import { NgModule, SkipSelf, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';
import { AuthGuard } from '../core/auth.guard';
import { AppRoutingModule } from '../app-routing.module';
import { MessageService } from 'primeng/api';

import { MatTabsModule } from '@angular/material/tabs';

import { StaffRoutingModule } from './staff.routing.module';
import { StaffMainComponent, StaffHomeComponent } from './';
import { BoxFrequencyComponent } from './home/box-frequency/box-frequency.component';
import { BoxDurationComponent } from './home/box-duration/box-duration.component';
import { BoxDttComponent } from './home/box-dtt/box-dtt.component';
import { BoxQuantityComponent } from './home/box-quantity/box-quantity.component';
import { BoxFluencyrateComponent } from './home/box-fluencyrate/box-fluencyrate.component';
import { BoxAnecdotalComponent } from './home/box-anecdotal/box-anecdotal.component';
import { BoxEchoicComponent } from './home/box-echoic/box-echoic.component';
import { PatientSessionService } from './home/patient-session.service';
import { BoxBehaviorFrequencyComponent } from './home/box-behavior-frequency/box-behavior-frequency.component';
import { EndSessionComponent } from './home/end-session/end-session.component';
import { TargetSelectorComponent } from './home/target-selector/target-selector.component';
import { ABCDataPanelComponent } from './home/abc-data-panel/abc-data-panel.component';
import { BoxBehaviorDurationComponent } from './home/box-behavior-duration/box-behavior-duration.component';
import { SessionsComponent } from './sessions/sessions.component';
import { SessionComponent } from './sessions/session/session.component';
import { ViewSessionComponent } from './sessions/view-session/view-session.component';
import { GraphsModule } from '../graphs/graphs.module';
import { ProgressNoteComponent } from './home/progress-note/progress-note.component';
import { TargetDetailsComponent } from './home/target-details/target-details.component';
import { BoxGroupedComponent } from './home/box-grouped-frequency/box-grouped.component';

function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    throw new Error(
      `${moduleName} has already been loaded. Import Core modules in the AppModule only.`
    );
  }
}

const pages = [StaffMainComponent, StaffHomeComponent];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    StaffRoutingModule,
    MatTabsModule,
    GraphsModule,
  ],
  declarations: [
    ...pages,
    BoxFrequencyComponent,
    BoxDurationComponent,
    BoxDttComponent,
    BoxQuantityComponent,
    BoxFluencyrateComponent,
    BoxAnecdotalComponent,
    BoxEchoicComponent,
    BoxBehaviorFrequencyComponent,
    EndSessionComponent,
    BoxGroupedComponent,
    TargetSelectorComponent,
    ABCDataPanelComponent,
    BoxBehaviorDurationComponent,
    SessionsComponent,
    SessionComponent,
    ViewSessionComponent,
    ProgressNoteComponent,
    TargetDetailsComponent,
  ],
  exports: [],
  bootstrap: [],
})
export class StaffModule {
  constructor(@Optional() @SkipSelf() parentModule: StaffModule) {
    if (parentModule) {
      throw new Error(
        'Module is already loaded. Import in your base AppModule only.'
      );
    }
  }
}
