/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum GraphFrequency {
  BySession = 0,
  Daily = 1,
  Weekly = 2,
  Monthly = 3,
  Yearly = 4
}
