import { AfterViewInit, ChangeDetectorRef, Component, effect, Input, OnInit } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { PatientSessionService } from '../patient-session.service';
import { PatientBehaviorDataModel, PatientBehaviorType, PatientTargetDataModel } from '../../../core/api/models';
import { BaseBoxComponent } from '../base-box/base-box.component';
import { ABCDataPanelComponent } from '../abc-data-panel/abc-data-panel.component';

@Component({
  selector: 'app-box-behavior-duration',
  templateUrl: './box-behavior-duration.component.html',
})
export class BoxBehaviorDurationComponent extends BaseBoxComponent {
  @Input() text: string | undefined = '-';
  @Input() amountOfTimes: number = 0;
  @Input() isTopBox: boolean = true;
  @Input() isBottomBox: boolean = false;
  @Input() behaviorID: number = 0;

  behaviorData: PatientBehaviorDataModel[] = [];
  abcData: PatientBehaviorDataModel[] = [];
  isStarted: boolean = false;
  timerValue: number = 0;
  private startTime: number = 0;
  times: { ID?: number, Text: string, Duration: number, RecordedDate: string, FormattedDate: string }[] = [
  ];
  behaviorType: PatientBehaviorType = PatientBehaviorType.Duration;
  
  @ViewChild('abcDataPanel') abcDataPanel!: ABCDataPanelComponent;

  constructor(
    protected override cdRef: ChangeDetectorRef,
    private patientSessionService: PatientSessionService) {
    super(cdRef);
    effect(() => {
      const behaviorData = this.patientSessionService.geBehaviorData(this.behaviorID);
      if (behaviorData && behaviorData.length > 0) {
        this.behaviorData = behaviorData;
        this.times = behaviorData.map(t => ({ ID: t.ID??undefined, Text: t.TextValue ?? '', Duration: t.IntValue ?? 0, RecordedDate: t.RecordedDate ?? '', FormattedDate: this.formatDate(new Date(t.RecordedDate ?? '')) }));
        this.amountOfTimes = this.times.length;
        
        this.abcData = behaviorData.filter(data => data.Notes != null && data.Notes != undefined);
      }
      else {
        this.times = [];
        this.amountOfTimes = 0;
      }
    });

    effect(() => {
      const targetsRunningData = this.patientSessionService.getTargetsRunningData(900000000+this.behaviorID);
      if (targetsRunningData) {
        this.isStarted = targetsRunningData.Data.isStarted;
        this.startTime = targetsRunningData.Data.startTime;
        this.timerValue = targetsRunningData.Data.timerValue;
        if (this.isStarted) {
          this.updateTimer();
        }
      } else {
        this.isStarted = false;
        this.startTime = 0;
        this.timerValue = 0;
      }
    });
  }

  override doLongPress(): void {
    this.showExtra();
    setTimeout(() => {
      //scroll tabs to end
      const tabs = document.querySelectorAll('.abc-tabs');
      tabs.forEach(tab => {
        tab.scrollLeft = tab.scrollWidth;
      });
    }, 100);
  }

  startTimer() {
    this.isStarted = true;
    this.startTime = Date.now();
    this.timerValue = 0;
    this.updateTimer();
    this.saveTargetRunningData();
  }

  private updateTimer(): void {
    if (this.isStarted) {
      const currentTime = Date.now();
      this.timerValue = Math.floor((currentTime - this.startTime) / 1000);

      setTimeout(() => {
        this.updateTimer()
        this.saveTargetRunningData();
      }, 1000);
    }
  }


  saveTargetRunningData() {
    this.patientSessionService.setTargetsRunningData(900000000+this.behaviorID, { isStarted: this.isStarted, startTime: this.startTime, timerValue: this.timerValue });
  }

  stopTimer() {
    if (this.timerValue > 0) {
      this.saveTime();
      this.amountOfTimes = this.times.length;
    }
    this.timerValue = 0;
    this.isStarted = false;
    this.startTime = 0;
    this.saveTargetRunningData();
  }

  saveTime() {
    const currentDate = new Date();
    const formattedDate = this.formatDate(currentDate);
    const formattedTime = this.timerString();
    let behaviorData: PatientBehaviorDataModel = {
      TextValue: formattedTime,
      IntValue: this.timerValue,
      PatientSessionID: 0,
      PatientBehaviorID: this.behaviorID,
      RecordedDate: new Date().toServerString()
    };
    this.patientSessionService.addBehaviorData(behaviorData);
    this.times.unshift({ Text: formattedTime, Duration: this.timerValue, RecordedDate: currentDate.toServerString(), FormattedDate: formattedDate });
  }

  timerString() {
    const minutes = Math.floor(this.timerValue / 60);
    const seconds = this.timerValue % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  
  setExtraBoxes(): void {
    const dropdowns = document.querySelectorAll('.p-overlay.p-component');
    //convert Element to ElementRef
    this.extraBoxes = Array.from(dropdowns).map(dropdown => new ElementRef(dropdown));
    this.extraBoxes.push(this.extraBox);
    this.extraBoxes.push(this.extraBox2);
  }
  
  addABCData(time: { ID?: number, Text: string, Duration: number, RecordedDate: string, FormattedDate: string }) {
    this.closeExtra2();
    this.abcDataPanel.addTimeABCData(time);
    this.abcDataPanel.setEditingABCData(time.RecordedDate);
    this.showExtra();
    setTimeout(() => {
      //scroll tabs to end
      const tabs = document.querySelectorAll('.abc-tabs');
      tabs.forEach(tab => {
        tab.scrollLeft = tab.scrollWidth;
      });
    }, 100);
  }

  hasABCData(time: { ID?: number, Text: string, Duration: number, RecordedDate: string, FormattedDate: string }): boolean {
    return !!this.abcData.find(data => data.RecordedDate === time.RecordedDate);
  }

  seeABCData(time: { ID?: number, Text: string, Duration: number, RecordedDate: string, FormattedDate: string }) {
    this.closeExtra2();
    this.abcDataPanel.setEditingABCData(time.RecordedDate);
    this.showExtra();
  }
}