import { HttpClient } from '@angular/common/http';
import { Component, OnInit, AfterViewInit, inject } from '@angular/core';
import { AuthService } from "../../../auth";
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuModule } from 'primeng/menu';
import { MenuItem } from 'primeng/api/menuitem';
import { PatientModel } from '../../../core/api/models/patient-model';
import { PatientSessionService } from '../../../staff/home/patient-session.service';
import { StaffApiService } from '../../../core/api/services/staff-api.service';
import { ShepherdService } from 'angular-shepherd';
import { defaultStepOptions, steps as defaultSteps } from "../../../shepherd-config";

@Component({
  selector: 'top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit, AfterViewInit {
  isSignedIn: boolean = false;

  selectedPatient: { ID: number | undefined, FullName: string } | null = null;
  openMenu: boolean = false;
  patientID: number | null = null;
  loading: boolean = true;
  currentScreen: string = 'staff';
  patientOptions: { ID: number | undefined, FullName: string }[] = [];
    constructor(
    private authService: AuthService,
    protected patientSessionService: PatientSessionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private staffApiService: StaffApiService,
    private shepherdService: ShepherdService
  ) {
  }

  ngOnInit() {

    this.currentScreen = this.router.url.split('/')[1];
    if (this.currentScreen === 'staff') {
      if (this.router.url.split('/')[2] === 'home') {
        this.currentScreen = 'staff-home';
      } else if (this.router.url.split('/')[2] === 'preview') {
        this.currentScreen = 'staff-preview';
      }
      else if (this.router.url.split('/')[3] === 'sessions') {
        this.currentScreen = 'staff-sessions';
      }
    }

    this.authService.onStateChanged().forEach((state: boolean) => {
      this.isSignedIn = state;
    });

    if (!this.patientOptions || this.patientOptions.length === 0) {
      this.staffApiService.GetMyPatients().subscribe((patients) => {
        this.patientOptions = patients.map(patient => ({
          ID: patient.ID,
          FullName: `${patient.FirstName} ${patient.LastName}`
        }));
        this.loading = false;
      });
    }

    const subscription = this.activatedRoute.params.subscribe((params) => {
      this.patientID = params['patientID'];
      if (isNaN(Number(this.patientID))) {
        this.patientID = this.patientSessionService.patientID();
      }

      if (!isNaN(Number(this.patientID))) {
        this.loading = true;
        this.staffApiService
          .GetPatientInfo({ id: Number(this.patientID) })
          .subscribe((patient) => {
            if (patient) {
              (this.selectedPatient as any) = {
                ID: patient.ID,
                FullName: `${patient.FirstName} ${patient.LastName}`
              };
            }
            this.loading = false;
          });

        if (this.patientID?.toString() !== this.patientSessionService.patientID()?.toString()) {
          this.patientSessionService.initializePatientSession(
            Number(this.patientID),
            this.currentScreen === 'staff-preview' ? -1 : undefined
          );
        }
      }
    });

  }
  signOut() {
    this.authService.signOut().subscribe(() => {
      this.router.navigate(['/signin']);
    });
  }

  
ngAfterViewInit(): void {
  this.shepherdService.defaultStepOptions = defaultStepOptions;
  this.shepherdService.modal = true;

  this.shepherdService.addSteps(defaultSteps);

}

  startTour() {
    this.shepherdService.start();
  }

  clickOutside() {
    if (!this.shepherdService.isActive) {
      this.openMenu = false;
    }
  }

  retrySession() {
    this.patientSessionService.sendCurrentSessionToBackend();
  }


  switchToStaff() {
    const patientSession = this.patientSessionService.patientSession();
    this.router.navigate([
      '/staff/home/' + this.selectedPatient?.ID + '/' + patientSession?.ID,
    ]);
  }
  switchToPreviewSession(){
    this.router.navigate([
      '/staff/preview/' + this.selectedPatient?.ID,
    ]);
  }

  switchToSessionList() {
    this.router.navigate(['/staff/' + this.selectedPatient?.ID + '/sessions']);
  }

  switchToGraphs() {
    this.router.navigate(['/graphs', this.selectedPatient?.ID]);
  }

  switchToSupervisor() {
    this.router.navigate(['/supervisor', this.selectedPatient?.ID]);
  }

  backToOpto() {
    window.location.href = this.authService.getOptoURL() ?? '/';
  }

  changedPatient(event: { originalEvent: Event, value: number }) {
    const newPatientIDSelected = event.value;
    this.selectedPatient = this.patientOptions?.find(patient => patient.ID === newPatientIDSelected) ?? null;
    if (this.currentScreen === 'staff-home') {
      this.router.navigate(['/staff/home/' + newPatientIDSelected + '/0']);
    } else if (this.currentScreen === 'supervisor') {
      this.router.navigate(['/supervisor/' + newPatientIDSelected + '/programs']);
    } else if (this.currentScreen === 'graphs') {
      this.router.navigate(['/graphs/' + newPatientIDSelected]);
    } else if (this.currentScreen === 'settings') {
      this.router.navigate(['/settings/' + newPatientIDSelected]);
    } else if (this.currentScreen === 'staff-sessions') {
      this.router.navigate(['/staff/' + newPatientIDSelected + '/sessions']);
    }

  }

  showTourButton(): boolean {
    // Check if the current route is a program route
    return this.router.url.includes('/supervisor/') && 
           this.router.url.includes('/programs');
  }
}
