import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  PatientBehaviorModel,
  PatientBehaviorType,
  PatientModel,
} from '../../../core/api/models';
import {
  StaffApiService,
  SupervisorApiService,
} from '../../../core/api/services';
import { SupervisorHelperService } from '../../supervisor.helper.service';
import { AlertService } from '../../../shared/services/alert.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Utils } from '../../../core/utils';

@Component({
  selector: 'app-add-behavior',
  templateUrl: './add-behavior.component.html',
  styleUrl: './add-behavior.component.css',
})
export class AddBehaviorComponent {
  @Input() selectedPatient: PatientModel | null = null;
  @Output() close = new EventEmitter<void>();

  patientBehaviorTypes: any[] = [
    { value: PatientBehaviorType.Frequency, text: 'Frequency' },
    { value: PatientBehaviorType.Duration, text: 'Duration' },
  ];

  constructor(
    private staffApiService: StaffApiService,
    private supervisorHelperService: SupervisorHelperService,
    private alertService: AlertService
  ) {}

  behaviorForm = new FormGroup({
    patientBehaviorName: new FormControl<string | null>(''),
    behaviorType: new FormControl<PatientBehaviorType>(
      PatientBehaviorType.Duration,
      [Validators.required]
    ),
  });

  behaviorToEdit: PatientBehaviorModel | null = null;

  titleName: string = 'Add Behavior';
  actionName: string = 'Add';

  ngOnInit(): void {
    this.behaviorToEdit = {
      PatientID: this.selectedPatient?.ID ?? 0,
      BehaviorType: PatientBehaviorType.Duration,
    };

    // EDIT LOGIC
    if (this.supervisorHelperService.behaviorToEdit() != null) {
      this.titleName = 'Edit Behavior';
      this.actionName = 'Save';
      this.behaviorToEdit = this.supervisorHelperService.behaviorToEdit();

      this.behaviorForm.patchValue({
        patientBehaviorName: this.behaviorToEdit?.PatientBehaviorName ?? '',
        behaviorType:
          this.behaviorToEdit?.BehaviorType ?? PatientBehaviorType.Duration,
      });
    }
  }

  onCancel() {
    this.close.emit();
  }

  onSubmit() {
    this.behaviorForm.markAllAsTouched();

    if (this.behaviorForm.invalid) {
      const errors = Utils.getFormErrors(this.behaviorForm);
      if (errors.length > 0) {
        this.alertService.error(errors.join(' \n'));
        return;
      }
    }

    this.saveBehavior();
  }

  saveBehavior() {
    var formValue = this.behaviorForm.getRawValue();
    this.behaviorToEdit = {
      ...this.behaviorToEdit,
      ...formValue,
    };

    this.staffApiService
      .SavePatientBehavior({
        body: this.behaviorToEdit,
      })
      .subscribe(
        (behavior) => {
          console.log(behavior);
          this.alertService.success('Behavior saved successfully');
          this.close.emit();
        },
        (error) => {
          console.error(error);
          this.alertService.error('Error saving Behavior - ' + error.Message);
          this.close.emit();
        }
      );
  }
}
