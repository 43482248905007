import { Component, effect, Input, input, OnInit } from '@angular/core';
import { PatientModel, SkillAreaModel } from '../../core/api/models';
import { SupervisorApiService } from '../../core/api/services';
import { AlertService } from '../../shared/services/alert.service';
import { SupervisorHelperService } from '../supervisor.helper.service';
import { Utils } from '../../core/utils';

@Component({
  selector: 'app-skill-areas',
  templateUrl: './skill-areas.component.html',
  styleUrl: './skill-areas.component.css',
})
export class SkillAreasComponent implements OnInit {
  patientID = input.required<string>();
  skillAreas: SkillAreaModel[] = [];

  loadingSkillAreas: boolean = false;

  constructor(
    private supervisorApiService: SupervisorApiService,
    private alertService: AlertService,
    private supervisorHelperService: SupervisorHelperService
  ) {
    effect(() => {
      console.log(
        'update signal triggered: ',
        this.supervisorHelperService.updateSignal()
      );
      this.loadData();
    });
  }

  ngOnInit(): void {
    console.log('SkillAreasComponent ngOnInit');
    this.loadData();
  }

  loadData() {
    this.loadingSkillAreas = true;
    this.supervisorApiService.GetSkillAreas({ patientID: Number(this.patientID()) }).subscribe(
      (skillAreas) => {
        this.loadingSkillAreas = false;
        this.skillAreas = skillAreas;
      },
      (err) => {
        this.loadingSkillAreas = false;
        this.alertService.error(Utils.getAjaxError(err));
      }
    );
  }

  addSkillAreaButtonClicked() {
    console.log('addSkillAreaButtonClicked');
    this.supervisorHelperService.addSkillAreaButtonClicked(null);
  }

  getFromBSPButtonClicked() {
    console.log('getFromBSPButtonClicked');
    this.supervisorApiService.ImportSkillAreasFromBSP({ patientID: Number(this.patientID()) }).subscribe(
      (response) => {
        console.log('ImportSkillAreasFromBSP response: ', response);
        this.loadData();
      },
      (err) => {
        this.alertService.error(Utils.getAjaxError(err));
      }
    );
  }
}
