@if (isCollapsed) {
<div class="container" (click)="toggleCollapsed()">
  <div class="row h-4rem align-items-center">
    <div class="col-3">
      <button type="button" class="btn btn-primary position-relative program-name">
        {{ promptSchedule?.PromptScheduleName }}
      </button>
    </div>
    <div class="col-1">
      <i class="pi pi-arrow-down" style="font-size: 1rem; color: #719eb6"></i>
    </div>
    @if (promptSchedule?.IsDeleted) {
      <div class="col-2">
        <p-tag id="deleted-tag" severity="danger" value="Deleted" />
      </div>
      }
  </div>
</div>
} @else {
<div class="container">
  <div class="row h-4rem align-items-center">
    <div class="col-3">
      <button type="button" class="btn btn-primary position-relative program-name" (click)="toggleCollapsed()">
        {{ promptSchedule?.PromptScheduleName }}
      </button>
    </div>

    <div class="col-1">
      <i class="pi pi-arrow-up" style="font-size: 1rem; color: #719eb6"></i>
    </div>

    @if (promptSchedule?.IsDeleted) {
      <div class="col-2">
        <p-tag id="deleted-tag" severity="danger" value="Deleted" />
      </div>
      } @else {
    <div class="col-8">
      <div class="side-menu-buttons-horizontal">
        <button (click)="editPromptSchedule()" class="side-menu-button2">
          Edit Prompt Schedule
        </button>
        <button (click)="deletePromptSchedule()" class="switch-mode-button">
          Delete Prompt Schedule
        </button>
      </div>
    </div>
    }
  </div>

  <div class="row" *ngIf="promptSchedule != null">
    
    <div class="col-4">Promotion:</div>
    <div class="col-8">
      {{ promptSchedule!.PromotionNeeded }} of {{ promptSchedule!.PromotionTotal }}
    </div>
    <div class="col-4">
      Demotion:
    </div>
    <div class="col-8">
      {{ promptSchedule!.DemotionNeeded }} of {{ promptSchedule!.DemotionTotal }}
    </div>
    
    <div class="col-4">
      Tie goes to:
    </div>
    <div class="col-8">
      {{ promptSchedule!.TieIsDemotion ? 'Demotion' : 'Promotion' }}
    </div>
    <div class="col-4">
      Is Inverse
    </div>
    <div class="col-8">
      {{ promptSchedule!.IsInverse ? 'Yes' : 'No' }}
    </div>
  </div>
</div>
}