@if (isCollapsed) {
<div class="container" (click)="toggleCollapsed()" [id]="'toggleCollapseButton-' + consequence?.PatientBehaviorConsequence">
  <div class="row h-4rem align-items-center">
    <div class="col-3">
      <button
        type="button"
        class="btn btn-primary position-relative program-name"
      >
        {{ consequence?.PatientBehaviorConsequence }}
      </button>
    </div>
    <div class="col-1">
      <i class="pi pi-arrow-down" style="font-size: 1rem; color: #719eb6"></i>
    </div>

    @if (consequence?.IsDeleted) {
      <div class="col-2">
        <p-tag id="deleted-tag" severity="danger" value="Deleted" />
      </div>
      }
  </div>
</div>
} @else {
<div class="container">
  <div class="row h-4rem align-items-center">
    <div class="col-3">
      <button
        type="button"
        class="btn btn-primary position-relative program-name"
        (click)="toggleCollapsed()"
        [id]="'toggleCollapseButton-' + consequence?.PatientBehaviorConsequence"
      >
        {{ consequence?.PatientBehaviorConsequence }}
      </button>
    </div>

    <div class="col-1">
      <i class="pi pi-arrow-up" style="font-size: 1rem; color: #719eb6"></i>
    </div>

    @if (consequence?.IsDeleted) {
      <div class="col-2">
        <p-tag id="deleted-tag" severity="danger" value="Deleted" />
      </div>
      } @else {
        <div class="col-8">
          <div class="side-menu-buttons-horizontal">
            <button (click)="editConsequence()" class="side-menu-button2" [id]="'editConsequenceButton-' + consequence?.PatientBehaviorConsequence">
              Edit Consequence
            </button>
            <button (click)="deleteConsequence()" class="switch-mode-button" [id]="'deleteConsequenceButton-' + consequence?.PatientBehaviorConsequence">
              Delete Consequence
            </button>
          </div>
        </div>
      }
    
  </div>

</div>
}
