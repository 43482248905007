<div class="header" >
  <div class="side-menu-buttons" >
    <div class="client-name" id="clientName">
      @if (currentScreen !== 'settings') {
        {{ this.selectedPatient?.FullName }} {{ this.patientSessionService.patientSession()?.ServiceID?(' - Service #'+this.patientSessionService.patientSession()?.ServiceID):'' }}
      }
      @if (this.patientSessionService.sessionOffline()) {
        <span class="text-danger" title="Click here to retry" (click)="retrySession()">Offline</span>
      }
      @if (currentScreen === 'staff-preview') {
        <span class="text-danger" >Preview</span>
      }
    </div>

  </div>
  <div (clickOutside)="clickOutside()" [additionalElementRefs]="[]">
  @if (showTourButton()) {
    <button class="updates-button me-3" (click)="startTour()" id="startTourButton">
      Start Tour
    </button>
  }
  <button class="side-menu-button2 me-3" (click)="openMenu = !openMenu" id="sideMenuButton" >
    Menu
  </button>
  <div class="side-menu-buttons menu-top" *ngIf="openMenu" id="sideMenuButtons">
    <div class="text-center w-100 ">
      <span *ngIf="patientOptions && patientOptions.length > 0">
        <p-dropdown
        styleClass="w-100"
        appendTo="body"
        [options]="patientOptions"
        [ngModel]="selectedPatient?.ID"
        name="selectedPatientDropdown"
        id="selectedPatientDropdown"
        [showClear]="true"
        placeholder="Select a patient"
        [filter]="true"
        filterBy="FullName"
        optionLabel="FullName"
        optionValue="ID"
        [virtualScroll]="true"
        [virtualScrollItemSize]="50"
        (onChange)="changedPatient($event)"
        >
        <ng-template pTemplate="selectedItem" let-selectedOption>
          <div class="flex align-items-center gap-2">
            <div>{{ selectedOption.FullName }}</div>
          </div>
        </ng-template>
      </p-dropdown>

      </span>
    </div>
    <button
      (click)="switchToStaff()"
      *ngIf="this.patientSessionService.patientSession()?.ID && this.patientSessionService.patientSession()?.ID !== -1"
      
      [class.side-menu-button2]="currentScreen !== 'staff-home'"
      [class.updates-button]="currentScreen === 'staff-home'"
    >
      Session
    </button>

    <button
      (click)="switchToPreviewSession()"
      
      [class.side-menu-button2]="currentScreen !== 'staff-preview'"
      [class.updates-button]="currentScreen === 'staff-preview'"
    >
      Preview Session
    </button>
    
    <button (click)="switchToSupervisor()" 
    [class.side-menu-button2]="currentScreen !== 'supervisor'"
    [class.updates-button]="currentScreen === 'supervisor'"
    *ngIf="selectedPatient"
    >
      Supervisor
    </button>

    
    <button
      *ngIf="selectedPatient"
      [routerLink]="['/graphs', selectedPatient.ID]"
      [class.side-menu-button2]="currentScreen !== 'graphs'"
      [class.updates-button]="currentScreen === 'graphs'"
      
    >
      Graphs
    </button>
    

    <button (click)="switchToSessionList()" 
    [class.side-menu-button2]="currentScreen !== 'staff-sessions'"
    [class.updates-button]="currentScreen === 'staff-sessions'"
    *ngIf="selectedPatient"
    >
      All Sessions
    </button>

    <hr/>
    <button
      [routerLink]="['/settings/prompt-codes']"
      [class.side-menu-button2]="currentScreen !== 'settings'"
      [class.updates-button]="currentScreen === 'settings'"
    >
      General Settings
    </button>
    <button (click)="backToOpto()" 
    class="side-menu-button2">
      Back to Opto
    </button>
    <button (click)="signOut()" class="side-menu-button2" id="signOutButton">
      Sign Out
    </button>
  </div>
</div>
</div>
