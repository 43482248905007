import { Component, effect, input } from '@angular/core';
import { PatientReminderModel, PromptCodeModel } from '../../core/api/models';
import { SupervisorApiService } from '../../core/api/services';
import { AlertService } from '../../shared/services/alert.service';
import { SettingsHelperService } from '../settings.helper.service';
import { Utils } from '../../core/utils';

@Component({
  selector: 'app-prompt-codes',
  templateUrl: './prompt-codes.component.html',
})
export class PromptCodesComponent {
  patientID = input.required<string>();

  promptCodes: PromptCodeModel[] = [];

  loadingPromptCodes: boolean = false;
  showAddPromptCode = this.settingsHelperService.showAddPromptCode;

  constructor(
    private supervisorApiService: SupervisorApiService,
    private alertService: AlertService,
    protected settingsHelperService: SettingsHelperService
  ) {
    effect(() => {
      console.log(
        'update signal triggered: ',
        this.settingsHelperService.updateSignal()
      );
      this.loadData();
    });
  }

  ngOnInit(): void {
    console.log('RemindersComponent ngOnInit');
    this.loadData();
  }

  loadData() {
    this.loadingPromptCodes = true;
    this.supervisorApiService
      .GetPromptCodes({ patientID: undefined })
      .subscribe(
        (promptCodes) => {
          this.loadingPromptCodes = false;
          this.promptCodes = promptCodes;
        },
        (err) => {
          this.loadingPromptCodes = false;
          this.alertService.error(Utils.getAjaxError(err));
        }
      );
  }
  
  onCloseAddPromptCode() {
    this.settingsHelperService.onCloseAddPromptCode();
  }

  
  moveUp(promptCode: PromptCodeModel) {
    if (promptCode.ID != null) {
      this.supervisorApiService.MovePromptCodeUp({ promptCodeID: promptCode.ID }).subscribe(
        () => {
          this.loadData();
        }
      );
    }
  }

  moveDown(promptCode: PromptCodeModel) {
    if (promptCode.ID != null) {
      this.supervisorApiService.MovePromptCodeDown({ promptCodeID: promptCode.ID }).subscribe(
        () => {
        this.loadData();
      }
      );
    }
  }

  
  addPromptCodeButtonClicked() {
    this.settingsHelperService.addPromptCodeButtonClicked(null);
  }
}
