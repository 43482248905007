import { Component, effect, input, signal, ViewChild } from '@angular/core';
import { PatientTargetModel, TargetType } from '../../core/api/models';
import { GraphApiService, SupervisorApiService } from '../../core/api/services';
import { MultiSelectChangeEvent } from 'primeng/multiselect';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-targets',
  templateUrl: './targets.component.html',
  styleUrl: './targets.component.css',
})
export class TargetsComponent {
  patientID = input.required<string>();

  isLoading: boolean = false;

  selectableTargets = signal<SelectItem[]>([]);
  selectedTarget: PatientTargetModel | null = null;

  constructor(private supervisorApiService: SupervisorApiService) {
    effect(() => {
      if (this.patientID()) {
        this.loadData();
      }
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.supervisorApiService
      .GetPatientTargets({ patientID: Number(this.patientID()) })
      .subscribe(
        (targets) => {
          console.log(targets);
          this.selectableTargets.set(
            targets.map((target) => ({
              text: target.TargetName ?? 'No Name For Target',
              value: target,
              disabled:
                target.TargetType !== TargetType.DiscreteTrialTeaching &&
                target.TargetType !== TargetType.Echoic &&
                target.TargetType !== TargetType.Frequency &&
                target.TargetType !== TargetType.Duration &&
                target.TargetType !== TargetType.Quantity &&
                target.TargetType !== TargetType.FluencyRate,
            }))
          );
          this.isLoading = false;
        },
        (error) => {
          console.error('Error fetching data', error);
          this.isLoading = false;
        }
      );
  }

  onTargetChange(event: MultiSelectChangeEvent) {
    if (!event.value) {
      return;
    }
  }
}
