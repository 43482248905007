@if (isCollapsed) {
<div
  class="container"
  (click)="toggleCollapsed()"
  [id]="'toggleCollapseButton-' + antecedent?.PatientBehaviorAntecedent"
>
  <div class="row h-4rem align-items-center">
    <div class="col-3">
      <button
        type="button"
        class="btn btn-primary position-relative program-name"
      >
        {{ antecedent?.PatientBehaviorAntecedent }}
      </button>
    </div>
    <div class="col-1">
      <i class="pi pi-arrow-down" style="font-size: 1rem; color: #719eb6"></i>
    </div>

    @if (antecedent?.IsDeleted) {
    <div class="col-2">
      <p-tag id="deleted-tag" severity="danger" value="Deleted" />
    </div>
    }
  </div>
</div>
} @else {
<div class="container">
  <div class="row h-4rem align-items-center">
    <div class="col-3">
      <button
        type="button"
        class="btn btn-primary position-relative program-name"
        (click)="toggleCollapsed()"
        [id]="'toggleCollapseButton-' + antecedent?.PatientBehaviorAntecedent"
      >
        {{ antecedent?.PatientBehaviorAntecedent }}
      </button>
    </div>

    <div class="col-1">
      <i class="pi pi-arrow-up" style="font-size: 1rem; color: #719eb6"></i>
    </div>

    @if (antecedent?.IsDeleted) {
    <div class="col-2">
      <p-tag id="deleted-tag" severity="danger" value="Deleted" />
    </div>
    } @else {
    <div class="col-8">
      <div class="side-menu-buttons-horizontal">
        <button
          (click)="editAntecedent()"
          [id]="'editAntecedentButton-' + antecedent?.PatientBehaviorAntecedent"
          class="side-menu-button2"
        >
          Edit Antecedent
        </button>
        <button
          (click)="deleteAntecedent()"
          [id]="
            'deleteAntecedentButton-' + antecedent?.PatientBehaviorAntecedent
          "
          class="switch-mode-button"
        >
          Delete Antecedent
        </button>
      </div>
    </div>
    }
  </div>
</div>
}
