<div class="w-100 d-flex justify-content-center" *ngIf="promptSchedules.length > 0">
  <button (click)="addPromptScheduleButtonClicked()" class="side-menu-button2  w-100">
    + Prompt Schedule
  </button>
</div>

<p-table
  id="prompt-schedules-patient"
  [value]="promptSchedules"
  #dt
  dataKey="ID"
  [tableStyle]="{ 'min-width': '70rem' }"
  [loading]="loadingPromptSchedules"
  [rows]="25"
  [rowsPerPageOptions]="[10, 25, 50]"
  [paginator]="true"
>
  <ng-template pTemplate="body" let-promptSchedule>
    <app-prompt-schedule [patientID]="patientID" [promptSchedule]="promptSchedule"></app-prompt-schedule>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <p
      style="
        text-align: center;
        margin-top: 20px;
        font-size: 1rem;
        color: #719eb6;
      "
    >
    There are no custom Prompt Schedules for this patient.<br>
    <button class="btn btn-primary" (click)="customizePromptCodes()">Customize Prompt Codes</button>
    </p>
  </ng-template>
</p-table>

@if (showAddPromptSchedule()) {
  <div class="supervisor-dialog-backdrop" (click)="onCloseAddPromptSchedule()"></div>
  <dialog class="supervisor-dialog" open>
    <app-add-prompt-schedule [patientID]="patientID" (close)="onCloseAddPromptSchedule()" />
  </dialog>
}