<div class="d-flex flex-row gap-3 ms-3 me-3">
  <button
    (click)="addSkillAreaButtonClicked()"
    class="side-menu-button2 w-100"
    id="addSkillAreaButton"
  >
    + Skill Area
  </button>
  <button (click)="getFromBSPButtonClicked()" class="side-menu-button2 w-100">
    Get From BSP
  </button>
</div>

<p-table
  [value]="skillAreas"
  #dt
  dataKey="ID"
  [tableStyle]="{ 'min-width': '70rem' }"
  [loading]="loadingSkillAreas"
  [rows]="25"
  [rowsPerPageOptions]="[10, 25, 50]"
  [paginator]="true"
>
  <ng-template pTemplate="body" let-skillArea>
    <app-skill-area [skillArea]="skillArea" [id]="skillArea?.SkillAreaName" />
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <p
      style="
        text-align: center;
        margin-top: 20px;
        font-size: 1rem;
        color: #719eb6;
      "
    >
      There are no Skill Areas yet.
    </p>
  </ng-template>
</p-table>
