import {
  Component,
  computed,
  DestroyRef,
  inject,
  input,
  OnInit,
  signal,
} from '@angular/core';
import {
  PatientBehaviorAntecedentModel,
  PatientBehaviorConsequenceModel,
  PatientBehaviorDataModel,
  PatientBehaviorLocationModel,
  PatientBehaviorModel,
  PatientBehaviorResponseModel,
  PatientBehaviorSeverityModel,
  PatientModel,
  PatientSessionModel,
  PatientTargetDataModel,
  PatientTargetModel,
  UserModel,
} from '../../core/api/models';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../auth';
import {
  HealthCheckApiService,
  SupervisorApiService,
  StaffApiService,
} from '../../core/api/services';
import { AlertService } from '../../shared/services/alert.service';
import { SupervisorHelperService } from '../../supervisor/supervisor.helper.service';
import { PatientSessionService } from '../home/patient-session.service';
import { catchError, forkJoin, Observable, of } from 'rxjs';
import { DatePipe } from '@angular/common';
import { TableRowCollapseEvent, TableRowExpandEvent } from 'primeng/table';
import { Utils } from '../../core/utils';
import { SessionsBaseComponent } from './sessions-base.component';
import { SelectItemGroup } from 'primeng/api';

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrl: './sessions.component.css',
})
export class SessionsComponent extends SessionsBaseComponent implements OnInit {
  patientID = input.required<string>();

  userInfo: UserModel | null = null;

  openMenu: boolean = false;
  showGraph = signal(false);

  clonedSessions: { [s: number]: PatientSessionModel } = {};
  clonedSessionData: {
    [s: number]: PatientTargetDataModel | PatientBehaviorDataModel;
  } = {};

  sessionBeingEdited: PatientSessionModel | null = null;
  sessionIndexBeingEdited: number | null = null;

  // filters logic
  possibleFilters = computed<SelectItemGroup[]>(() => {
    let targets = this.targets();
    let behaviors = this.behaviors();
    return [
      {
        label: 'Targets',
        items: targets.map((target) => {
          return {
            label: target.TargetName ?? 'No Name for Target',
            value: target,
          };
        }),
      },
      {
        label: 'Behaviors',
        items: behaviors.map((behavior) => {
          return {
            label: behavior.PatientBehaviorName ?? 'No Name for Behavior',
            value: behavior,
          };
        }),
      },
    ];
  });

  selectedFilters = signal<any[]>([]);

  filteredSessions = computed(() => {
    let filteredSessions = this.sessions();
    if (this.selectedFilters() == null) {
      return filteredSessions;
    }
    this.selectedFilters().forEach((selectedFilter) => {
      if (this.supervisorHelperService.isPatientTargetModel(selectedFilter)) {
        filteredSessions = filteredSessions.filter((session) =>
          session.TargetData?.some(
            (target) => target.PatientTargetID === selectedFilter.ID
          )
        );
      }
      if (this.supervisorHelperService.isPatientBehaviorModel(selectedFilter)) {
        filteredSessions = filteredSessions.filter((session) =>
          session.BehaviorData?.some(
            (behavior) => behavior.PatientBehaviorID === selectedFilter.ID
          )
        );
      }
    });
    return filteredSessions;
  });

  constructor(
    private authService: AuthService,
    private healthCheckApiService: HealthCheckApiService,
    private router: Router,
    protected override supervisorApiService: SupervisorApiService,
    private alertService: AlertService,
    protected override supervisorHelperService: SupervisorHelperService,
    private activatedRoute: ActivatedRoute,
    protected override patientSessionService: PatientSessionService,
    protected override staffApiService: StaffApiService,
    private datePipe: DatePipe
  ) {
    super(
      supervisorApiService,
      supervisorHelperService,
      patientSessionService,
      staffApiService
    );
    console.log(
      'SupervisorMain PatientSessionService instance:',
      this.patientSessionService
    );
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.activatedRoute.params.subscribe((params) => {
      this.loadBaseData(Number(params['patientID']));
    });
  }

  get canPlotGraph() {
    return true;
  }

  onRowExpand(event: TableRowExpandEvent) {
    console.log('Row expanded:', event);
  }

  onRowCollapse(event: TableRowCollapseEvent) {
    console.log('Row collapsed:', event);
  }

  switchToStaff() {
    const patientSession = this.patientSessionService.patientSession();
    this.router.navigate([
      '/staff/home/' + this.selectedPatient?.ID + '/' + patientSession?.ID,
    ]);
  }

  backToOpto() {
    window.location.href = this.authService.getOptoURL() ?? '/';
  }

  onSessionEditInit(session: PatientSessionModel) {
    console.log('Editing session:', session);
    this.clonedSessions[session.ID as number] = {
      ...session,
    } as PatientSessionModel;
  }

  onSessionEditSave(session: PatientSessionModel) {
    console.log('Updated Session:', session);
    this.saveSessionData(session);
  }

  onSessionEditCancel(session: PatientSessionModel, index: number) {
    console.log('Cancelled editing session:', session);
    this.sessions()[index] = this.clonedSessions[session.ID as number];
    delete this.clonedSessions[session.ID as number];
  }

  saveSessionData(newSessionData: PatientSessionModel) {
    this.isLoading = true;
    console.log('Saving session data:', newSessionData);
    this.staffApiService
      .UpdatePatientSession({
        body: newSessionData,
      })
      .subscribe(
        (response) => {
          console.log('Saved session data:', response);
          this.alertService.success('Session data saved successfully');
          this.isLoading = false;
        },
        (error) => {
          console.error('Error saving session data:', error);
          this.alertService.error(
            'Error saving session data - ' + error.Message
          );
          this.isLoading = false;
        }
      );
  }

  handOffTextForSession(session: PatientSessionModel) {
    return session.IsHandoffToStaff ? 'Handoff to Staff' : 'Handoff to Parent';
  }

  severityForHandoffTypeOfSession(session: PatientSessionModel) {
    return session.IsHandoffToStaff ? 'success' : 'warning';
  }

  onShowGraphClicked(session: PatientSessionModel) {
    console.log('Show graph for session:', session);
    this.showGraph.update((oldValue) => !oldValue);
  }
}
