import { Component, effect, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
  PatientModel,
  PatientProgramModel,
  PatientTargetModel,
  SkillAreaModel,
} from '../../../core/api/models';
import { SupervisorApiService } from '../../../core/api/services';
import { SupervisorHelperService } from '../../supervisor.helper.service';
import { AlertService } from '../../../shared/services/alert.service';
import { Utils } from '../../../core/utils';

@Component({
  selector: 'app-add-program',
  templateUrl: './add-program.component.html',
  styleUrl: './add-program.component.css',
})
export class AddProgramComponent implements OnInit {
  @Input() selectedPatientID: number | null = null;
  programToEdit: PatientProgramModel | null = null;

  @Output() close = new EventEmitter<void>();

  isLoading: boolean = false;

  targets: PatientTargetModel[] | undefined;
  selectedTargets: PatientTargetModel[] | undefined;

  skillAreas: SkillAreaModel[] | undefined;
  selectedSkillArea: SkillAreaModel | undefined;

  loading: boolean = false;

  titleName: string = 'Add Program';
  actionName: string = 'Add';

  programId: number = 0;
  enteredName = '';
  enteredDetails = '';

  constructor(
    private supervisorApiService: SupervisorApiService,
    private supervisorHelperService: SupervisorHelperService,
    private alertService: AlertService
  ) {
    effect(() => {
      console.log(
        'update signal triggered: ',
        this.supervisorHelperService.updateSignal()
      );

      this.loadData();
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.isLoading = true;
    this.supervisorApiService
      .GetPatientTargets({
        patientID: this.selectedPatientID ?? undefined,
      })
      .subscribe(
        (targets) => {
          this.targets = targets;
          this.supervisorApiService.GetSkillAreas({patientID: this.selectedPatientID ?? undefined}).subscribe(
            (skillAreas) => {
              this.skillAreas = skillAreas;
              this.isLoading = false;

              // EDIT LOGIC
              if (this.supervisorHelperService.programToEdit() != null) {
                this.titleName = 'Edit Program';
                this.actionName = 'Save';
                this.programToEdit =
                  this.supervisorHelperService.programToEdit();
                this.programId = this.programToEdit?.ID ?? 0;
                this.enteredName = this.programToEdit?.ProgramName ?? '';
                this.enteredDetails = this.programToEdit?.Details ?? '';
                this.selectedTargets = this.targets?.filter(
                  (target) =>
                    this.programToEdit?.Targets?.findIndex(
                      (t) => t.ID === target.ID
                    ) !== -1
                );
                this.selectedSkillArea = this.skillAreas?.find(
                  (skillArea) =>
                    skillArea.ID === this.programToEdit?.SkillAreaID
                );
              }
            },
            (error) => {
              console.error(error);
              this.isLoading = false;
            }
          );
        },
        (error) => {
          console.error(error);
          this.isLoading = false;
        }
      );
  }

  onCancel() {
    this.close.emit();
  }

  onSubmit() {
    this.saveProgram(false);
  }

  saveProgram(isArchive: boolean = false) {
    if (!this.loading) {
      this.loading = true;
      this.supervisorApiService
      .SavePatientProgram({
        body: {
          ID: this.programId,
          Details: this.enteredDetails,
          PatientID: this.selectedPatientID ?? undefined,
          ProgramName: this.enteredName,
          SkillAreaID: this.selectedSkillArea?.ID,
          SkillAreaName: this.selectedSkillArea?.SkillAreaName,
          Targets: this.selectedTargets,
          IsArchived: isArchive,
        },
      })
      .subscribe(
        (patientProgram) => {
          console.log(patientProgram);
          this.loading = false;
          this.alertService.success('Program saved successfully');
          this.close.emit();
        },
        (error) => {
          console.error(error);
          this.alertService.error(Utils.getAjaxError(error));
          this.loading = false;
          this.close.emit();
          }
        );
    }
  }

  addTarget(event: Event) {
    console.log('addTarget');
    event.preventDefault();
    this.supervisorHelperService.additTargetButtonClicked(null);
  }

  archiveButtonClicked() {
    if (this.programToEdit?.IsArchived) {
      this.programToEdit!.IsArchived = false;
      this.saveProgram();
    } else {
      this.saveProgram(true);
    }
  }
}
