<div class="card">
  <div
    class="card-body"
    style="padding-left: 2rem; padding-right: 2rem; padding-bottom: 2rem"
  >
    @if(isLoading) {
    <app-loading-indicator />
    } @else { @if(shouldDisplayGraphTypeOptions()) {
    <div class="control-row">
      <div class="control no-margin">
        <label for="selectedGraphType">Graph Type:</label>
        <p-dropdown
          styleClass="w-100"
          appendTo="body"
          [options]="graphTypes"
          [(ngModel)]="selectedGraphType"
          name="selectedGraphType"
          [checkmark]="true"
          optionLabel="text"
          optionValue="value"
          [showClear]="true"
          placeholder="Select a Graph Type"
          [filter]="true"
          filterBy="text"
          display="chip"
          [showClear]="true"
          [virtualScroll]="true"
          [virtualScrollItemSize]="50"
          [loading]="isLoading"
          optionDisabled="disabled"
          [disabled]="isLoading"
          [hidden]="!shouldDisplayGraphTypeOptions()"
          (onChange)="onGraphTypeChange($event)"
          ><ng-template pTemplate="selectedGraphType" let-selectedOption>
            <div class="flex align-items-center gap-2">
              <div>{{ selectedOption.text }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
    }

    <div class="control-row">
      <div class="control no-margin">
        <label for="selectedGraphType">Date Options:</label>
        <p-dropdown
          [options]="dateOptions"
          [(ngModel)]="dateOption"
          appendTo="body"
          styleClass="w-100"
          optionValue="value"
          optionLabel="text"
          (onChange)="onDateOptionChange($event)"
        />
      </div>
    </div>
    @if(shouldDisplayFrequencyOptions()) {
    <div class="control-row">
      <div class="control no-margin">
        <label for="selectedGraphType">Frequency Options:</label>
        <p-dropdown
          [options]="frequencyOptions"
          [(ngModel)]="frequencyOption"
          appendTo="body"
          styleClass="w-100"
          optionValue="value"
          optionLabel="text"
          (onChange)="onFrequencyOptionChange($event)"
        />
      </div>
    </div>
    } @if(shouldDisplayGraph()) {
    <div
      style="
        text-align: center;
        background-color: white;
        border: 2px solid cornflowerblue;
        border-radius: 20px;
      "
    >
      <apx-chart
        #chart
        [series]="chartOptions.series || []"
        [chart]="chartOptions.chart || { type: 'line' }"
        [xaxis]="chartOptions.xaxis || {}"
        [yaxis]="chartOptions.yaxis || {}"
        [title]="chartOptions.title || {}"
        [dataLabels]="chartOptions.dataLabels || {}"
        [grid]="chartOptions.grid || {}"
        [stroke]="chartOptions.stroke || {}"
        [labels]="chartOptions.labels || []"
        [annotations]="chartOptions.annotations || {}"
        [legend]="chartOptions.legend || {}"
        [markers]="chartOptions.markers || {}"
        [tooltip]="chartOptions.tooltip || {}"
        [responsive]="chartOptions.responsive || []"
        [plotOptions]="chartOptions.plotOptions || {}"
        [colors]="chartOptions.colors || []"
      ></apx-chart>
    </div>
    } @else if(isLoading) {
    <app-loading-indicator />
    } @else {
    <div class="no-data">
      <p
        style="
          text-align: center;
          margin-top: 20px;
          font-size: 1rem;
          color: #719eb6;
        "
      >
        No data available for the selected target/graph type.
      </p>
    </div>
    } }
  </div>
</div>
