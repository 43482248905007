import { Component, effect, input, signal, ViewChild } from '@angular/core';
import {
  PatientTargetModel,
  GraphDataModel,
  GraphFrequency,
  TargetGraphType,
  TargetType,
} from '../../../core/api/models';
import { DatePipe } from '@angular/common';
import { MultiSelectChangeEvent } from 'primeng/multiselect';
import {
  GraphApiService,
  SupervisorApiService,
} from '../../../core/api/services';
import { AlertService } from '../../../shared/services/alert.service';
import { Utils } from '../../../core/utils';
import { GraphsBaseComponent } from '../../graphs-base.component';
import { ChartComponent } from 'ng-apexcharts';

@Component({
  selector: 'app-target-graph',
  templateUrl: './target-graph.component.html',
  styleUrl: './target-graph.component.css',
})
export class TargetGraphComponent extends GraphsBaseComponent {
  @ViewChild('chart', { static: false }) chart: ChartComponent | undefined;

  patientID = input.required<string>();
  startDate = signal<Date>(Utils.dateInPastBy(90));
  endDate = signal<Date>(new Date());

  isLoading: boolean = false;

  targetId = input.required<number>();
  loadedTargets: PatientTargetModel[] = [];
  selectedTarget: PatientTargetModel | null = null;

  graphData = signal<GraphDataModel | null>(null);

  graphTypes: any[] = [];
  selectedGraphType: TargetGraphType | null = null;

  constructor(
    private graphApiService: GraphApiService,
    private supervisorApiService: SupervisorApiService,
    private datePipe: DatePipe,
    private alertService: AlertService
  ) {
    super();
    this.loadDefaultGraphOptions();
    effect(() => {
      console.log('targetId changed to:', this.targetId());
      this.selectedGraphType = null;
      this.loadData();
    });
  }

  ngOnInit(): void {}

  loadData() {
    this.isLoading = true;
    this.supervisorApiService
      .GetPatientTargets({ patientID: Number(this.patientID()) })
      .subscribe(
        (targets) => {
          console.log(targets);
          this.loadedTargets = targets;
          this.selectedTarget =
            targets.find((target) => target.ID == this.targetId()) ?? null;
          this.loadDataForGraph(this.targetId());
          this.isLoading = false;
        },
        (error) => {
          console.error('Error fetching data', error);
          this.isLoading = false;
        }
      );
  }

  onGraphTypeChange(event: any) {
    this.selectedGraphType = event.value;
    if (this.selectedTarget) {
      if (this.selectedTarget?.ID != null) {
        this.loadDataForGraph(this.selectedTarget.ID);
      }
    } else {
      this.graphData.set(null);
      this.updateGraph();
    }
  }

  onTargetChange(event: MultiSelectChangeEvent) {
    if (!event.value) {
      return;
    }
    this.loadDataForGraph(event.value.ID);
  }

  updateAvailableGraphTypes() {
    // set the available graph types based on the target type
    switch (this.selectedTarget?.TargetType) {
      case TargetType.DiscreteTrialTeaching:
      case TargetType.Echoic:
        this.graphTypes = [
          {
            text: '% Correct Responding',
            value: TargetGraphType.PercentageCorrectResponding,
          },
          {
            text: 'Prompt % Distribution',
            value: TargetGraphType.PromptPercentageDistribution,
          },
          { text: 'Prompt Counts', value: TargetGraphType.PromptCounts },
          {
            text: 'Trials by Prompts',
            value: TargetGraphType.TrialsByPrompts,
          },
          {
            text: 'Daily Prompt %',
            value: TargetGraphType.DailyPromptPercentage,
          },
          { text: 'Time of Day', value: TargetGraphType.TimeOfDay },
        ];
        if (this.selectedGraphType == null) {
          this.selectedGraphType = TargetGraphType.PercentageCorrectResponding;
        }
        break;
      case TargetType.Frequency:
        this.graphTypes = [
          {
            text: 'Frequency Simple',
            value: TargetGraphType.FrequencySimple,
          },
        ];
        this.selectedGraphType = TargetGraphType.FrequencySimple;
        break;
      case TargetType.Duration:
        this.graphTypes = [
          {
            text: 'Duration Sum',
            value: TargetGraphType.DurationSum,
          },
          {
            text: 'Duration Count',
            value: TargetGraphType.DurationCount,
          },
        ];
        if (this.selectedGraphType == null) {
          this.selectedGraphType = TargetGraphType.DurationSum;
        }
        break;
      case TargetType.Quantity:
        this.graphTypes = [
          {
            text: 'Quantity Total',
            value: TargetGraphType.QuantityTotal,
          },
          {
            text: 'Quantity Individual',
            value: TargetGraphType.QuantityIndividual,
          },
        ];
        if (this.selectedGraphType == null) {
          this.selectedGraphType = TargetGraphType.QuantityTotal;
        }
        break;
      case TargetType.FluencyRate:
        this.graphTypes = [
          {
            text: 'Fluency Average',
            value: TargetGraphType.FluencyAverage,
          },
          {
            text: 'Fluency Individual',
            value: TargetGraphType.FluencyIndividual,
          },
        ];
        if (this.selectedGraphType == null) {
          this.selectedGraphType = TargetGraphType.FluencyAverage;
        }
        break;
      default:
        this.graphTypes = [];
        break;
    }
  }

  loadDataForGraph(targetID: number) {
    this.updateAvailableGraphTypes();

    this.isLoading = true;
    this.graphApiService
      .GetTargetGraph({
        patientID: Number(this.patientID()),
        targetID: targetID,
        startTime: this.startDate().toServerString(),
        endTime: new Date().toServerString(),
        graphType: this.selectedGraphType!,
        graphFrequency: this.frequencyOption,
      })
      .subscribe(
        (data) => {
          console.log(data);
          this.graphData.set(data);
          this.updateGraph();
          this.isLoading = false;
        },
        (error) => {
          console.error('Error fetching data', error);
          this.alertService.error('Error fetching data: ' + error.Message);
          this.graphData.set(null);
          this.updateGraph();
          this.isLoading = false;
        }
      );
  }

  shouldDisplayGraphTypeOptions(): boolean {
    return (
      this.selectedTarget?.TargetType === TargetType.DiscreteTrialTeaching ||
      this.selectedTarget?.TargetType === TargetType.Echoic ||
      this.selectedTarget?.TargetType === TargetType.Duration ||
      this.selectedTarget?.TargetType === TargetType.FluencyRate ||
      this.selectedTarget?.TargetType === TargetType.Quantity
    );
  }

  shouldDisplayFrequencyOptions(): boolean {
    return (
      this.selectedGraphType === TargetGraphType.PercentageCorrectResponding ||
      this.selectedGraphType === TargetGraphType.TrialsByPrompts
    );
  }

  shouldDisplayGraph(): boolean {
    return (
      this.isLoading === false &&
      this.selectedTarget != null &&
      !this.isGraphEmpty()
    );
  }

  isGraphEmpty(): boolean {
    // check if the graph data is empty
    if (this.graphData() == null) {
      return true;
    }

    // check if series is empty
    if (this.graphData()?.Series?.length == 0) {
      return true;
    }

    // for some graph types, check the lenght of the first series as well.
    switch (this.selectedGraphType) {
      case TargetGraphType.PercentageCorrectResponding:
      case TargetGraphType.TimeOfDay:
      case TargetGraphType.FrequencySimple:
      case TargetGraphType.DurationCount:
      case TargetGraphType.DurationSum:
      case TargetGraphType.QuantityTotal:
      case TargetGraphType.QuantityIndividual:
      case TargetGraphType.FluencyAverage:
      case TargetGraphType.FluencyIndividual:
        return this.graphData()!.Series![0].DataPoints!.length == 0;

      default:
        return false;
    }
  }

  onDateOptionChange(event: any) {
    this.startDate.set(event.value);
    if (this.selectedTarget) {
      this.loadDataForGraph(this.selectedTarget!.ID!);
    }
  }

  onFrequencyOptionChange(event: any) {
    this.frequencyOption = event.value;
    if (this.selectedTarget) {
      this.loadDataForGraph(this.selectedTarget!.ID!);
    }
  }

  updateGraph() {
    if (!this.graphData()) {
      // hide graph
      this.chartOptions.series = [];
      return;
    }
    switch (this.selectedGraphType) {
      case TargetGraphType.PercentageCorrectResponding:
        this.loadPercentageCorrectRespondingGraphOptions();
        break;
      case TargetGraphType.PromptPercentageDistribution:
        this.loadPromptPercentageDistributionGraphOptions();
        break;
      case TargetGraphType.PromptCounts:
        this.loadPromptCountsGraphOptions();
        break;
      case TargetGraphType.TrialsByPrompts:
        this.loadTrialsByPromptsGraphOptions();
        break;
      case TargetGraphType.DailyPromptPercentage:
        this.loadDailyPromptPercentageGraphOptions();
        break;
      case TargetGraphType.TimeOfDay:
        this.loadTimeOfDayGraphOptions(this.graphData()!);
        break;
      case TargetGraphType.FrequencySimple:
        this.loadFrequencySimpleGraphOptions();
        break;
      case TargetGraphType.DurationSum:
        this.loadDurationSumGraphOptions();
        break;
      case TargetGraphType.DurationCount:
      case TargetGraphType.QuantityTotal:
      case TargetGraphType.QuantityIndividual:
      case TargetGraphType.FluencyAverage:
      case TargetGraphType.FluencyIndividual:
        this.loadDefaultLineGraphOptions(this.graphData()!);
        break;
      default:
        return;
    }

    // we need to call this so that the options are actually updated on the graph
    this.chart?.updateOptions(this.chartOptions);
  }

  loadPercentageCorrectRespondingGraphOptions() {
    this.chartOptions = {
      series: this.graphData()!.Series!.map((series) => ({
        name: series.Name ?? 'No Name',
        data: series.DataPoints!.map(
          (data) =>
            [
              data.Date ? new Date(data.Date).getTime() : new Date().getTime(),
              data.Value,
            ] as [number, number | null]
        ),
      })),
      legend: {
        position: 'top',
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: false,
          zoomedArea: {
            fill: {
              color: '#90CAF9',
              opacity: 0.4,
            },
            stroke: {
              color: '#0D47A1',
              opacity: 0.4,
              width: 1,
            },
          },
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp?: number) {
                return new Date(timestamp!).toDateString();
              },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
          autoSelected: 'zoom',
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (value: any, { seriesIndex, dataPointIndex, w }) => {
          return value + ' %';
        },
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        padding: {
          right: 30,
          left: 20,
        },
      },
      markers: {
        size: 3,
        strokeColors: '#fff',
        strokeWidth: 2,
        strokeOpacity: 0.9,
        fillOpacity: 1,
        shape: 'square',
      },
      tooltip: {
        x: {
          format: 'MMM dd yyyy',
        },
        y: {
          formatter: (value: any) => {
            return value + ' %';
          },
        },
      },
      title: {
        text: this.graphData()?.Title ?? 'Chart',
      },
      xaxis: {
        type: 'datetime',
        title: {
          text: this.graphData()?.XAxisTitle ?? 'X Axis',
        },
      },
      yaxis: {
        title: {
          text: this.graphData()?.YAxisTitle ?? 'Y Axis',
        },
      },
    };
  }

  loadPromptPercentageDistributionGraphOptions() {
    this.chartOptions = {
      title: {
        text: this.graphData()?.Title ?? 'Chart',
      },
      series:
        this.graphData()?.Series!.map((series) => series.Value ?? 0) ?? [],
      chart: {
        type: 'pie',
      },
      colors:
        this.graphData()?.Series!.map((series) => series.Color ?? '#008FFB') ??
        [],
      labels:
        this.graphData()?.Series!.map((series) => series.Name ?? '') ?? [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
  }

  loadPromptCountsGraphOptions() {
    this.chartOptions = {
      title: {
        text: this.graphData()?.Title ?? 'Chart',
      },
      series: [
        {
          name: 'count',
          data:
            this.graphData()?.Series!.map((series) => series.Value ?? 0) ?? [],
        },
      ],
      chart: {
        height: 350,
        type: 'bar',
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
      },
      colors:
        this.graphData()?.Series!.map((series) => series.Color ?? '#008FFB') ??
        [],
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      grid: {
        show: false,
      },
      xaxis: {
        categories:
          this.graphData()?.Series!.map((series) => series.Name ?? '') ?? [],
        labels: {
          style: {
            fontSize: '12px',
          },
        },
      },
    };
  }

  loadTrialsByPromptsGraphOptions() {
    this.chartOptions = {
      series: this.graphData()!.Series!.map((series) => ({
        name: series.Name ?? 'No Name',
        data: series.DataPoints!.map(
          (data) =>
            [
              data.Date ? new Date(data.Date).getTime() : new Date().getTime(),
              data.Value,
            ] as [number, number | null]
        ),
        color: series.Color ?? '#008FFB',
      })),
      legend: {
        position: 'top',
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: false,
          zoomedArea: {
            fill: {
              color: '#90CAF9',
              opacity: 0.4,
            },
            stroke: {
              color: '#0D47A1',
              opacity: 0.4,
              width: 1,
            },
          },
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp?: number) {
                return new Date(timestamp!).toDateString();
              },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
          autoSelected: 'zoom',
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (value: any, { seriesIndex, dataPointIndex, w }) => {
          return value;
        },
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        padding: {
          right: 30,
          left: 20,
        },
      },
      markers: {
        size: 3,
        strokeWidth: 2,
        strokeOpacity: 0.9,
        fillOpacity: 1,
        shape: 'circle',
      },
      tooltip: {
        x: {
          format: 'MMM dd yyyy',
        },
        y: {
          formatter: (value: any) => {
            return value;
          },
        },
      },
      title: {
        text: this.graphData()?.Title ?? 'Chart',
      },
      xaxis: {
        type: 'datetime',
        title: {
          text: this.graphData()?.XAxisTitle ?? 'X Axis',
        },
      },
      yaxis: {
        title: {
          text: this.graphData()?.YAxisTitle ?? 'Y Axis',
        },
      },
    };
  }

  loadDailyPromptPercentageGraphOptions() {
    this.chartOptions = {
      series: this.graphData()!.Series!.map((series) => ({
        name: series.Name ?? 'No Name',
        data: series.DataPoints!.map(
          (data) =>
            [
              data.Date ? new Date(data.Date).getTime() : new Date().getTime(),
              data.Value,
            ] as [number, number | null]
        ),
        color: series.Color ?? '#008FFB',
      })),
      legend: {
        position: 'top',
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: false,
          zoomedArea: {
            fill: {
              color: '#90CAF9',
              opacity: 0.4,
            },
            stroke: {
              color: '#0D47A1',
              opacity: 0.4,
              width: 1,
            },
          },
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp?: number) {
                return new Date(timestamp!).toDateString();
              },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
          autoSelected: 'zoom',
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (value: any, { seriesIndex, dataPointIndex, w }) => {
          return value + ' %';
        },
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        padding: {
          right: 30,
          left: 20,
        },
      },
      markers: {
        size: 3,
        strokeColors: '#fff',
        strokeWidth: 2,
        strokeOpacity: 0.9,
        fillOpacity: 1,
        shape: 'square',
      },
      tooltip: {
        x: {
          format: 'MMM dd yyyy',
        },
        y: {
          formatter: (value: any) => {
            return value + ' %';
          },
        },
      },
      title: {
        text: this.graphData()?.Title ?? 'Chart',
      },
      xaxis: {
        type: 'datetime',
        title: {
          text: this.graphData()?.XAxisTitle ?? 'X Axis',
        },
      },
      yaxis: {
        title: {
          text: this.graphData()?.YAxisTitle ?? 'Y Axis',
        },
      },
    };
  }

  loadFrequencySimpleGraphOptions() {
    this.chartOptions = {
      series: this.graphData()!.Series!.map((series) => ({
        name: series.Name ?? 'No Name',
        data: series.DataPoints!.map(
          (data) =>
            [
              data.Date ? new Date(data.Date).getTime() : new Date().getTime(),
              data.Value,
            ] as [number, number | null]
        ),
      })),
      legend: {
        position: 'top',
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: false,
          zoomedArea: {
            fill: {
              color: '#90CAF9',
              opacity: 0.4,
            },
            stroke: {
              color: '#0D47A1',
              opacity: 0.4,
              width: 1,
            },
          },
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp?: number) {
                return new Date(timestamp!).toDateString();
              },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
          autoSelected: 'zoom',
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (value: any, { seriesIndex, dataPointIndex, w }) => {
          return value;
        },
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        padding: {
          right: 30,
          left: 20,
        },
      },
      markers: {
        size: 3,
        strokeColors: '#fff',
        strokeWidth: 2,
        strokeOpacity: 0.9,
        fillOpacity: 1,
        shape: 'square',
      },
      tooltip: {
        x: {
          format: 'MMM dd yyyy',
        },
        y: {
          formatter: (value: any) => {
            return value;
          },
        },
      },
      title: {
        text: this.graphData()?.Title ?? 'Chart',
      },
      xaxis: {
        type: 'datetime',
        title: {
          text: this.graphData()?.XAxisTitle ?? 'X Axis',
        },
      },
      yaxis: {
        title: {
          text: this.graphData()?.YAxisTitle ?? 'Y Axis',
        },
      },
    };
  }

  loadDurationSumGraphOptions() {
    this.chartOptions = {
      series: this.graphData()!.Series!.map((series) => ({
        name: series.Name ?? 'No Name',
        data: series.DataPoints!.map(
          (data) =>
            [
              data.Date ? new Date(data.Date).getTime() : new Date().getTime(),
              data.Value,
            ] as [number, number | null]
        ),
      })),
      legend: {
        position: 'top',
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: false,
          zoomedArea: {
            fill: {
              color: '#90CAF9',
              opacity: 0.4,
            },
            stroke: {
              color: '#0D47A1',
              opacity: 0.4,
              width: 1,
            },
          },
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp?: number) {
                return new Date(timestamp!).toDateString();
              },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
          autoSelected: 'zoom',
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (value: any, { seriesIndex, dataPointIndex, w }) => {
          return value;
        },
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        padding: {
          right: 30,
          left: 20,
        },
      },
      markers: {
        size: 3,
        strokeColors: '#fff',
        strokeWidth: 2,
        strokeOpacity: 0.9,
        fillOpacity: 1,
        shape: 'square',
      },
      tooltip: {
        x: {
          format: 'MMM dd yyyy',
        },
        y: {
          formatter: (value: any) => {
            return value;
          },
        },
      },
      title: {
        text: this.graphData()?.Title ?? 'Chart',
      },
      xaxis: {
        type: 'datetime',
        title: {
          text: this.graphData()?.XAxisTitle ?? 'X Axis',
        },
      },
      yaxis: {
        title: {
          text: this.graphData()?.YAxisTitle ?? 'Y Axis',
        },
      },
    };
  }
}
