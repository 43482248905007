import { Component, effect, input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatientReminderModel, PromptCodeModel, PromptScheduleModel } from '../../core/api/models';
import { SupervisorApiService } from '../../core/api/services';
import { AlertService } from '../../shared/services/alert.service';
import { SettingsHelperService } from '../../settings/settings.helper.service';
import { Utils } from '../../core/utils';

@Component({
  selector: 'app-maintanance-criteria-patient',
  templateUrl: './maintanance-criteria-patient.component.html',
})
export class MaintananceCriteriaPatientComponent {
  patientID: string='';
  patientIDLoaded:boolean=false;

  constructor(
    protected settingsHelperService: SettingsHelperService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.patientID = params['patientID'];
      this.patientIDLoaded = true;
    });
  }
}