import { Component, Input } from '@angular/core';
import { PatientBehaviorModel } from '../../../core/api/models';
import { StaffApiService } from '../../../core/api/services';
import { SupervisorHelperService } from '../../supervisor.helper.service';
import { AlertService } from '../../../shared/services/alert.service';
import { Utils } from '../../../core/utils';

@Component({
  selector: 'app-behavior',
  templateUrl: './behavior.component.html',
  styleUrl: '../../supervisor-main.component.css',
})
export class BehaviorComponent {
  @Input() behavior: PatientBehaviorModel | undefined;

  constructor(
    private staffApiService: StaffApiService,
    private supervisorHelperService: SupervisorHelperService,
    private alertService: AlertService
  ) {}

  isCollapsed = true;

  toggleCollapsed() {
    this.isCollapsed = !this.isCollapsed;
  }

  patientBehaviorTypeLabel(behaviorType: number | undefined) {
    if (!behaviorType) return 'Unspecified';
    return Utils.patientBehaviorTypeLabel(behaviorType);
  }

  editBehavior() {
    console.log('editBehavior clicked!');
    this.supervisorHelperService.addBehaviorButtonClicked(
      this.behavior ?? null
    );
  }

  deleteBehavior() {
    console.log('deleteBehavior clicked!');

    this.alertService
      .confirm(
        'Delete Behavior',
        'Are you sure you want to delete this behavior? It will be marked as deleted.'
      )
      .subscribe((result) => {
        if (result) {
          this.deleteBehaviorConfirmed();
        }
      });
  }

  deleteBehaviorConfirmed() {
    if (this.behavior?.ID != null) {
      this.staffApiService
        .MarkBehaviorAsDeleted({ patientBehaviorID: this.behavior.ID })
        .subscribe(
          () => {
            this.alertService.success('Behavior is now marked as deleted');
            this.supervisorHelperService.onCloseAddBehavior();
          },
          (error) => {
            console.error(error);
            this.alertService.error(
              'Failed to mark behavior as deleted - ' + error.Message
            );
          }
        );
    }
  }
}
