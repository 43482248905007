import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PatientModel, PatientBehaviorAntecedentModel } from '../../../../core/api/models';
import { StaffApiService } from '../../../../core/api/services';
import { SupervisorHelperService } from '../../../supervisor.helper.service';
import { AlertService } from '../../../../shared/services/alert.service';
import { Utils } from '../../../../core/utils';

@Component({
  selector: 'app-add-antecedent',
  templateUrl: './add-antecedent.component.html',
})
export class AddAntecedentComponent {
  @Input({ required: true }) patientID!: number;
  antecedentToEdit: PatientBehaviorAntecedentModel | null = null;

  @Output() close = new EventEmitter<void>();

  loading: boolean = false;

  titleName: string = 'Add Antecedent';
  actionName: string = 'Add';

  antecedentId: number | null = null;
  name = '';
  message = '';
  interval = 0;

  constructor(
    private staffApiService: StaffApiService,
    private supervisorHelperService: SupervisorHelperService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    // EDIT LOGIC
    if (this.supervisorHelperService.antecedentToEdit() != null) {
      this.titleName = 'Edit Antecedent';
      this.actionName = 'Save';
      this.antecedentToEdit = this.supervisorHelperService.antecedentToEdit();
      this.antecedentId = this.antecedentToEdit?.ID ?? 0;
      this.name = this.antecedentToEdit?.PatientBehaviorAntecedent ?? '';
    }
  }

  onCancel() {
    this.close.emit();
  }

  onSubmit() {
    this.loading = true;
    this.staffApiService
      .SavePatientBehaviorAntecedent({
        body: {
          ID: this.antecedentId,
          PatientBehaviorAntecedent: this.name,
          PatientID: this.patientID,
        },
      })
      .subscribe(
        (antecedent) => {
          console.log(antecedent);
          this.loading = false;
          this.alertService.success('Antecedent saved successfully');
          this.close.emit();
        },
        (error) => {
          console.error(error);
          this.alertService.error(Utils.getAjaxError(error));
          this.loading = false;
          this.close.emit();
        }
      );
  }
}
