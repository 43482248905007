import { AfterViewInit, ChangeDetectorRef, Component, effect, Input, OnInit } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { PatientSessionService } from '../patient-session.service';
import { PatientTargetDataModel, PatientTargetModel, PatientTargetStatus, PromptCodeModel, PromptScheduleModel } from '../../../core/api/models';
import { getPatientTargetStatusColor, getPatientTargetStatusLabel } from '../../../shared/utils/utils';
import { BaseBoxComponent } from '../base-box/base-box.component';
@Component({
  selector: 'app-box-dtt',
  templateUrl: './box-dtt.component.html',
})
export class BoxDttComponent extends BaseBoxComponent implements OnInit {
  text: string | undefined = '-';
  @Input() isTopBox: boolean = true;
  @Input() isBottomBox: boolean = false;
  targetID: number = 0;
  requiredTrials: number = 3;
  targetStatus: PatientTargetStatus = PatientTargetStatus.Unspecified;
  @Input() enabled?: boolean = true;
  currentPromptCodeID?: number;
  @Input() promptSchedule?: PromptScheduleModel|null;

  targetData: PatientTargetDataModel[] = [];
  totalSuccess: number = 0;
  selectedOptions: PromptCodeModel[] = [];
  selectedOptionsDate: string[] = [];
  @Input() options: PromptCodeModel[] = [ ];
  selectedOption: PromptCodeModel | null = null;

  @Input() target: PatientTargetModel | undefined = undefined;


  constructor(
    protected override cdRef: ChangeDetectorRef,
    private patientSessionService: PatientSessionService) { 
      super(cdRef);
    effect(() => {
      const targetData = this.patientSessionService.getTargetData(this.targetID);
      if (targetData) {
        this.targetData = targetData;
        //Add the options inside targetData to the selectedOptions based on the options available in the options array
        this.selectedOptions = targetData.map(t => this.options.find(o => o.ID === t.IntValue)).filter(o => o !== undefined) as PromptCodeModel[];
        this.selectedOptionsDate = targetData.map(t => this.formatDate(new Date(t.RecordedDate ?? '')));
        this.totalSuccess = this.selectedOptions.filter(o => o.IsIndependent).length;
        if (this.selectedOptions.length > 0) {
          this.selectedOption = this.selectedOptions[this.selectedOptions.length-1];
        } else {
          this.selectedOption = null;
        }
      }
      else {
        this.targetData = [];
        this.selectedOptions = [];
        this.totalSuccess = 0;
        this.selectedOption = null;
      }
    });
  }

  ngOnInit(): void {
    this.text = this.target?.TargetName ?? '-';
    this.targetID = this.target?.ID ?? 0;
    this.targetStatus = this.target?.TargetStatus ?? PatientTargetStatus.Unspecified;
    this.requiredTrials = this.target?.DesiredTrials ?? 3;
    this.currentPromptCodeID = this.target?.CurrentPromptCodeID ?? 0;
    if (!this.currentPromptCodeID) {
      this.currentPromptCodeID = this.options[0].ID ?? 0;
    }
  }

  selectOption(option: PromptCodeModel) {
    // Store the selected option
    this.selectedOption = option;

    // Initialize the selectedOptions array if it doesn't exist
    if (!this.selectedOptions) {
      this.selectedOptions = [];
    }

    // Add the selected option to the array
    this.selectedOptions.push(option);
    this.selectedOptionsDate.push(this.formatDate(new Date()));

    this.totalSuccess = this.selectedOptions.filter(o => o.IsIndependent).length;

    this.saveOption(option);

    // Close the extra container after selection
    this.closeExtra();
  }

  saveOption(option: PromptCodeModel) {
    this.patientSessionService.addTargetData({
      IntValue: option.ID,
      TextValue: option.Text,
      PatientTargetID: this.targetID,
      RecordedDate: new Date().toServerString(),
    });
  }

  getIcon(option: PromptCodeModel): string {
    //get first letter of the text
    return option.Abbreviation ?? '';
  }
  
  getStatusText() {
    if (!this.enabled) {
      return 'Waiting';
    }
    return getPatientTargetStatusLabel(this.targetStatus);
  }

  getStatusColor() {
    if (!this.enabled) {
      return '#CCCCCC';
    }
    return getPatientTargetStatusColor(this.targetStatus);
  }

  override doLongPress(): void {
    this.showExtra2();
  }
}