import { Component, computed, effect, input, signal } from '@angular/core';

import { PatientTargetModel, TargetTemplateModel } from '../../core/api/models';
import { SupervisorApiService } from '../../core/api/services';
import { AlertService } from '../../shared/services/alert.service';
import { Utils } from '../../core/utils';
import { SupervisorHelperService } from '../supervisor.helper.service';
import { SupervisorBaseComponent } from '../supervisor-base.component';
import { SelectItemGroup } from 'primeng/api';

@Component({
  selector: 'app-targets',
  templateUrl: './targets.component.html',
  styleUrl: '../supervisor-main.component.css',
})
export class TargetsComponent extends SupervisorBaseComponent {
  patientID = input.required<string>();

  selectedFilters = signal<any[]>([]);
  possibleFilters = computed<SelectItemGroup[]>(() => {
    let skillAreas = this.supervisorHelperService.skillAreas();
    let playlists = this.supervisorHelperService.playlists();
    return [
      {
        label: 'Skill Areas',
        items: skillAreas.map((skillArea) => {
          return {
            label: skillArea.SkillAreaName ?? 'No Name for Skill Area',
            value: skillArea,
          };
        }),
      },
      {
        label: 'Playlists',
        items: playlists.map((playlist) => {
          return {
            label: playlist.PlaylistName ?? 'No Name for Playlist',
            value: playlist,
          };
        }),
      },
    ];
  });
  searchKeyword = signal<string | null>(null);

  computedFilteredTargets = computed(() => {
    console.log('filteredTargets computed');
    let filteredTargets = this.supervisorHelperService.targets();
    // use keyword from search bar for the first filter
    if (this.searchKeyword() != null && this.searchKeyword()!.trim().length > 3) { // only filter if keyword is more than 3 characters
      filteredTargets = filteredTargets.filter((target) =>
        target.TargetName?.toLowerCase().includes(this.searchKeyword()!.toLowerCase())
      );
    }
    if (this.selectedFilters() == null) {
      return filteredTargets;
    } else {
      this.selectedFilters().forEach((selectedFilter) => {
        if (this.supervisorHelperService.isSkillAreaModel(selectedFilter)) {
          filteredTargets = filteredTargets.filter(
            (target) => target.SkillAreaID === selectedFilter.ID
          );
        }
        if (this.supervisorHelperService.isPatientPlaylistModel(selectedFilter)) {
          filteredTargets = filteredTargets.filter((target) =>
            //check if target is in the playlist
            this.supervisorHelperService.playlists().find((playlist) => {
              return playlist.Items?.find((playlistItem) => {
                return (
                  playlist.ID === selectedFilter.ID &&
                  playlistItem.PatientTargetID === target.ID
                );
              });
            })
          );
        }
      });
    }
    console.log('Filtered Targets:', filteredTargets);
    return filteredTargets;
  });

  constructor(
    protected override supervisorApiService: SupervisorApiService,
    protected override alertService: AlertService,
    protected override supervisorHelperService: SupervisorHelperService
  ) {
    super(supervisorApiService, alertService, supervisorHelperService);
    effect(() => {
      console.log(
        'update signal triggered: ',
        this.supervisorHelperService.updateSignal()
      );
      if (this.patientID()) {
        this.loadBaseData(Number(this.patientID()));
      }
    });
  }

  ngOnInit(): void {
  }

  addTargetButtonClicked() {
    console.log('addTargetButtonClicked');
    this.supervisorHelperService.additTargetButtonClicked(null);
  }
}
