<div class="d-flex flex-row gap-3 ms-3 me-3">
  <button
    (click)="addAntecedentButtonClicked()"
    id="addAntecedentButton"
    class="side-menu-button2 w-100"
  >
    + Antecedent
  </button>
  <button
    (click)="getFromBIPButtonClicked()"
    class="side-menu-button2 w-100"
    *ngIf="false"
  >
    Get From BIP
  </button>
</div>
<p-table
  id="antecedents"
  [value]="antecedents"
  #dt
  dataKey="ID"
  [tableStyle]="{ 'min-width': '70rem' }"
  [loading]="loadingAntecedents"
  [rows]="25"
  [rowsPerPageOptions]="[10, 25, 50]"
  [paginator]="true"
>
  <ng-template pTemplate="body" let-antecedent>
    <app-antecedent [antecedent]="antecedent" [id]="antecedent.PatientBehaviorAntecedent"></app-antecedent>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <p
      style="
        text-align: center;
        margin-top: 20px;
        font-size: 1rem;
        color: #719eb6;
      "
    >
    There are no Antecedents yet.
    </p>
  </ng-template>
</p-table>

@if (showAddAntecedent()) {
<div class="supervisor-dialog-backdrop" (click)="onCloseAddAntecedent()"></div>
<dialog class="supervisor-dialog" open>
  <app-add-antecedent
    [patientID]="patientID()"
    (close)="onCloseAddAntecedent()"
  />
</dialog>
}
