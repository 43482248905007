<div #mainBox class="duration-box"  [class.top-box]="isTopBox" [class.bottom-box]="isBottomBox" 
     (clickOutside)="onClickOutside()"
     [additionalElementRefs]="extraBoxes">
    <div class="flag flex-column  no-select" (mousedown)="startLongPress()" (mouseup)="endLongPress()" (mouseleave)="endLongPress()" (touchstart)="startLongPress()" (touchend)="endLongPress()" (touchcancel)="endLongPress()">

        <span class="badge-time" *ngIf="isStarted">{{timerString()}}</span>
        <div class="duration-container">
            <div class="duration-controls">
                <button *ngIf="!isStarted" (click)="startTimer()" class="duration-button start-button">Start</button>
                <button *ngIf="isStarted" (click)="stopTimer()" class="duration-button stop-button">Stop</button>
            </div>
            <div class="flag-text">{{ text }}</div>
        </div>
        <span class="badge badge-left pointer" (click)="showExtra2()">{{ amountOfTimes }}</span>
        <!-- <button class="badge-bottom-left">?</button> -->
    </div>
</div>


<div class="extra-container extra-big abc-container" #extraBox [class.hide]="!extraOpened" >
    <app-abc-data-panel #abcDataPanel [behaviorID]="behaviorID" [sessionValue]="timerValue" (setExtraBoxesEvent)="setExtraBoxes()" [behaviorType]="behaviorType" ></app-abc-data-panel>
</div>


<div class="extra-container" #extraBox2 [class.hide]="!extra2Opened">
    <div class="extra-close" (click)="closeExtra2()">X</div>
    <div class="extra-container-textbox">
        <div *ngFor="let time of times" class="extra-text">
            {{ time.Text + ' @ ' + time.FormattedDate }}
            <button class="abc-add-button-duration" (click)="addABCData(time)" *ngIf="!hasABCData(time)">+ Add ABC Data</button>
            <button class="abc-add-button-duration" (click)="seeABCData(time)" *ngIf="hasABCData(time)">See ABC Data</button>
        </div>
    </div>
</div>