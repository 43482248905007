import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PatientModel, PatientReminderModel, PromptCodeModel, TargetType } from '../../../core/api/models';
import { SupervisorApiService } from '../../../core/api/services';
import { SettingsHelperService } from '../../../settings/settings.helper.service';
import { AlertService } from '../../../shared/services/alert.service';
import { Utils } from '../../../core/utils';
import { SelectItem } from 'primeng/api';
@Component({
  selector: 'app-add-prompt-code',
  templateUrl: './add-prompt-code.component.html',
  styleUrl: './add-prompt-code.component.css',
})
export class AddPromptCodeComponent {
  @Input() patientID: number | null = null;
  promptCodeToEdit: PromptCodeModel | null = null;

  @Output() close = new EventEmitter<void>();

  loading: boolean = false;

  titleName: string = 'Add Prompt Code';
  actionName: string = 'Add';

  promptCodeId: number | null = null;

  targetTypes: SelectItem[] = [];
  targetTypesSelected: SelectItem[] = [];
  constructor(
    private supervisorApiService: SupervisorApiService,
    private settingsHelperService: SettingsHelperService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    
    //get target types array from Enum with value as the number and label as the name
    this.targetTypes = Object.entries(TargetType).filter(([key, value]) => !isNaN(Number(key))).map(([key, value]) => ({
      label: value.toString(),
      value: Number(key),
    }));

    // EDIT LOGIC
    if (this.settingsHelperService.promptCodeToEdit() != null) {
      this.titleName = 'Edit Prompt Code';
      this.actionName = 'Save';
      this.promptCodeToEdit = this.settingsHelperService.promptCodeToEdit();
      this.promptCodeId = this.promptCodeToEdit?.ID ?? 0;
      if (this.promptCodeToEdit!.TargetTypeIDs) {
        this.targetTypesSelected = this.targetTypes.filter((t) => this.promptCodeToEdit!.TargetTypeIDs!.includes(t.value));
      }
      else {
        this.targetTypesSelected = [];
      }
    } else {
      this.promptCodeToEdit = {};
    }

  }

  onCancel() {
    this.close.emit();
  }

  onSubmit() {
    this.loading = true;
    if (this.patientID) {
      this.promptCodeToEdit!.PatientID = this.patientID;
    }
    this.promptCodeToEdit!.TargetTypeIDs = this.targetTypesSelected.map((t) => t.value);
    this.supervisorApiService
      .SavePromptCode({
        body: this.promptCodeToEdit!,
      })
      .subscribe(
        (promptCode) => {
          console.log(promptCode);
          this.loading = false;
          this.alertService.success('Prompt code saved successfully');
          this.close.emit();
        },
        (error) => {
          console.error(error);
          this.alertService.error(Utils.getAjaxError(error));
          this.loading = false;
          this.close.emit();
        }
      );
  }
}
