
<button (click)="addReminderButtonClicked()" class="side-menu-button2 w-100 ">
  + Reminder
</button>

<p-table
  [value]="reminders"
  #dt
  dataKey="ID"
  [tableStyle]="{ 'min-width': '70rem' }"
  [loading]="loadingReminders"
  [rows]="25"
  [rowsPerPageOptions]="[10, 25, 50]"
  [paginator]="true"
>
  <ng-template pTemplate="body" let-reminder>
    <app-reminder [reminder]="reminder"></app-reminder>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <p
      style="
        text-align: center;
        margin-top: 20px;
        font-size: 1rem;
        color: #719eb6;
      "
    >
      There are no Reminders yet.
    </p>
  </ng-template>
</p-table>
