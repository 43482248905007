<div #mainBox [class.top-box]="isTopBox" [class.bottom-box]="isBottomBox" class="dtt-box pointer" 
     (clickOutside)="onClickOutside()"
     [additionalElementRefs]="extraBoxes" [class.disabled]="!enabled" >
    <div class="flag flex-column  no-select" (click)="showExtra();endLongPress();" [class.flag-all-trials]="selectedOptions.length >= requiredTrials"
        (mousedown)="startLongPress()"
     (mouseup)="endLongPress()" (mouseleave)="endLongPress()" (touchstart)="startLongPress()"
     (touchend)="endLongPress()" (touchcancel)="endLongPress()">
        {{text}}
        <button class="button-program" *ngIf="isBottomBox" [style.background-color]="getStatusColor()" [style.border-color]="getStatusColor()">{{getStatusText()}}</button>
        <!-- <button class="badge-bottom-left">?</button> -->
        <span *ngIf="selectedOption" class="badge-count" [style.color]="selectedOption.Color" [style.border-color]="selectedOption.Color">{{totalSuccess}}/{{selectedOptions.length}}</span>
        <span *ngIf="selectedOption" class="badge badge-left dtt-option-icon" [style.color]="selectedOption.Color" [style.border-color]="selectedOption.Color">
            {{ getIcon(selectedOption) }}
        </span>
        
        <button class="badge-bottom-left" (click)="openDetails($event)" *ngIf="isBottomBox">?</button>  
    </div>
</div>

@if (detailsOpened) {
    <app-target-details [target]="target" (onCloseDetails)="onCloseDetails()"></app-target-details>
    }
<div class="extra-container" #extraBox [class.hide]="!extraOpened">
    <div class="extra-close" (click)="closeExtra()">X</div>
    <div class="extra-container-textbox">
        <div *ngFor="let option of options" class="pointer" (click)="selectOption(option)">
            <div class="dtt-option" [style.background-color]="option.Color" [class.current]="promptSchedule && option.ID == currentPromptCodeID">
                <span class="dtt-option-icon" [style.color]="option.Color">{{ getIcon(option) }}</span>
                <span class="dtt-option-name">{{ option.Text }}</span>
            </div>
        </div>
    </div>
</div>


<div class="extra-container" #extraBox2 [class.hide]="!extra2Opened">
    <div class="extra-close" (click)="closeExtra2()">X</div>
    <div class="extra-container-textbox">
        <div *ngFor="let option of selectedOptions; let i = index" >
            {{'@ '+selectedOptionsDate[i]}} 
            <div class="dtt-option" [style.background-color]="option.Color" >
                <span class="dtt-option-icon" [style.color]="option.Color">{{ getIcon(option) }}</span>
                <span class="dtt-option-name">{{ option.Text }}</span>
            </div>
        </div>
    </div>
</div>