
<div class="container">
  <div class="row h-4rem align-items-center">
    <!--Icon up and down to reorder-->

   

    <div class="col-3">
      <button type="button" class="btn btn-primary position-relative prompt-code-button program-name" (click)="toggleCollapsed()"
        [style.backgroundColor]="promptCode?.Color" [class.inactive]="promptCode?.Inactive">
        {{ promptCode?.Text }}
      </button>
    </div>

    <div class="col-1">
      <i class="pi" [class.pi-arrow-up]="isCollapsed" [class.pi-arrow-down]="!isCollapsed" style="font-size: 0.8rem; color: #719eb6"></i>
    </div>
    @if (!isCollapsed) {
    <div class="col-8">
      <div class="side-menu-buttons-horizontal">
        <button (click)="editPromptCode()" class="side-menu-button2">
          Edit Prompt Code
        </button>
        <button (click)="deactivatePromptCode()" class="switch-mode-button" *ngIf="!promptCode?.Inactive">
          Deactivate Prompt Code
        </button>
        <button (click)="activatePromptCode()" class="switch-mode-button" *ngIf="promptCode?.Inactive">
          Activate Prompt Code
        </button>
      </div>
    </div>
    }
  </div>

  @if (!isCollapsed) {
  <div class="row" *ngIf="promptCode != null">

    <div class="col-4">Color</div>
    <div class="col-8">
      <div [style.backgroundColor]="promptCode!.Color" style="width: 2rem; height: 2rem;border-radius: 50%"></div>
    </div>

    <div class="col-4">
      Abbreviation
    </div>
    <div class="col-8">
      {{ promptCode!.Abbreviation }}
    </div>

    <div class="col-4">
      Target Types
    </div>
    <div class="col-8">
      {{ targetTypes }}
    </div>

    <div class="col-4">
      Is Independent
    </div>
    <div class="col-8">
      {{ promptCode!.IsIndependent ? 'Yes' : 'No' }}
    </div>
  </div>
  }
</div>