@if (showGraph()) {
<div
  class="supervisor-dialog-backdrop"
  (click)="onShowGraphClicked(null)"
></div>
<dialog class="supervisor-dialog" open>
  <app-target-graph
    [patientID]="session().PatientID?.toString() || ''"
    [targetId]="selectedTargetID || 0"
  ></app-target-graph>
</dialog>
}
<mat-tab-group>
  <mat-tab>
    <ng-template mat-tab-label>
      <p>Targets</p>
    </ng-template>
    <p-table [value]="targetsData" dataKey="ID" editMode="row">
      <ng-template pTemplate="header">
        <tr>
          <th>Recorded Date</th>
          <th>Value</th>
          <th>Target Name</th>
          <th>Target Type</th>
          <th style="width: 4rem"></th>
          <th style="width: 4rem"></th>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-sessionData
        let-editingSessionData="editing"
        let-sessionDataIndex="rowIndex"
      >
        <tr [pEditableRow]="sessionData">
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input
                  pInputText
                  type="datetime-local"
                  [(ngModel)]="sessionData.RecordedDate"
                />
              </ng-template>
              <ng-template pTemplate="output">
                {{ sessionData.RecordedDate | date : "MM/dd/yyyy HH:mm" }}
              </ng-template>
            </p-cellEditor>
          </td>

          @if (targetIsPromptBased(sessionData)) { @if
          (targetIsEchoic(sessionData)) {
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-dropdown
                  class="pe-1"
                  [options]="promptOptionsForTarget(sessionData)"
                  [(ngModel)]="sessionData.IntValue"
                  [checkmark]="true"
                  optionLabel="text"
                  optionValue="value"
                />
                <p-dropdown
                  [options]="extraOptionsForEchoicTarget(sessionData)"
                  [(ngModel)]="sessionData.TextValue"
                  [checkmark]="true"
                  optionLabel="text"
                  optionValue="value"
                />
              </ng-template>
              <ng-template pTemplate="output">
                <p-tag
                  class="pe-1"
                  [style]="{
                    background: firstColorForEchoic(sessionData)
                  }"
                  [value]="firstTextForEchoic(sessionData)"
                />
                <p-tag
                  [style]="{ background: secondColorForEchoic(sessionData) }"
                  [value]="secondTextForEchoic(sessionData)"
                />
              </ng-template>
            </p-cellEditor>
          </td>
          } @else {
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-dropdown
                  [options]="promptOptionsForTarget(sessionData)"
                  [(ngModel)]="sessionData.IntValue"
                  [checkmark]="true"
                  optionLabel="text"
                  optionValue="value"
                />
              </ng-template>
              <ng-template pTemplate="output">
                <p-tag
                  [style]="{ background: valueColorForTarget(sessionData) }"
                  [value]="valueTextForTarget(sessionData)"
                />
              </ng-template>
            </p-cellEditor>
          </td>
          } } @else {
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input
                  pInputText
                  type="text"
                  [(ngModel)]="sessionData.TextValue"
                />
              </ng-template>
              <ng-template pTemplate="output">
                <p-tag
                  [style]="{ background: valueColorForTarget(sessionData) }"
                  [value]="valueTextForTarget(sessionData)"
                />
              </ng-template>
            </p-cellEditor>
          </td>
          }

          <td>
            {{ getTargetName(sessionData.PatientTargetID) }}
          </td>
          <td>
            {{ getTargetType(sessionData.PatientTargetID) }}
          </td>
          <td>
            <div class="flex align-items-center justify-content-center gap-2">
              <button
                *ngIf="!editingSessionData"
                pButton
                pRipple
                type="button"
                pInitEditableRow
                icon="pi pi-pencil"
                (click)="onSessionDataEditInit(sessionData)"
                class="p-button-rounded p-button-text"
              ></button>
              <button
                *ngIf="editingSessionData"
                pButton
                pRipple
                type="button"
                pSaveEditableRow
                icon="pi pi-check"
                (click)="onSessionDataEditSave(sessionData)"
                class="p-button-rounded p-button-text p-button-success mr-2"
              ></button>
              <button
                *ngIf="editingSessionData"
                pButton
                pRipple
                type="button"
                pCancelEditableRow
                icon="pi pi-times"
                (click)="onSessionDataEditCancel(sessionData)"
                class="p-button-rounded p-button-text p-button-danger"
              ></button>
            </div>
          </td>
          <td>
            <button
              *ngIf="!editingSessionData"
              pButton
              pRipple
              type="button"
              icon="pi pi-chart-bar"
              (click)="onShowGraphClicked(sessionData.PatientTargetID)"
              (disabled)="canPlotGraph(sessionData.PatientTargetID)"
              class="p-button-rounded p-button-text"
            ></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="6">There are no Target entries for this session.</td>
        </tr>
      </ng-template>
    </p-table>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <p>Behaviors</p>
    </ng-template>
    <p-table
      [value]="behaviorsData"
      dataKey="ID"
      editMode="row"
      (onRowExpand)="onBehaviorsRowExpand($event)"
      (onRowCollapse)="onBehaviorsRowCollapse($event)"
      [rowHover]="true"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 5rem"></th>
          <th>Recorded Date</th>
          <th>Behavior</th>
          <th>Text Value</th>
          <th>Notes</th>
          <th style="width: 4rem"></th>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-sessionData
        let-editingSessionData="editing"
        let-expanded="expanded"
        let-sessionDataIndex="rowIndex"
      >
        <tr [pEditableRow]="sessionData">
          <td>
            <p-button
              type="button"
              pRipple
              [pRowToggler]="sessionData"
              [text]="true"
              [rounded]="true"
              [plain]="true"
              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
            />
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input
                  pInputText
                  type="datetime-local"
                  [(ngModel)]="sessionData.RecordedDate"
                />
              </ng-template>
              <ng-template pTemplate="output">
                {{ sessionData.RecordedDate | date : "MM/dd/yyyy HH:mm" }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            {{ getBehaviorName(sessionData.PatientBehaviorID) }}
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input
                  pInputText
                  type="text"
                  [(ngModel)]="sessionData.TextValue"
                />
              </ng-template>
              <ng-template pTemplate="output">
                {{ sessionData.TextValue }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="sessionData.Notes" />
              </ng-template>
              <ng-template pTemplate="output">
                {{ sessionData.Notes }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <div class="flex align-items-center justify-content-center gap-2">
              <button
                *ngIf="!editingSessionData"
                pButton
                pRipple
                type="button"
                pInitEditableRow
                icon="pi pi-pencil"
                (click)="onSessionDataEditInit(sessionData)"
                class="p-button-rounded p-button-text"
              ></button>
              <button
                *ngIf="editingSessionData"
                pButton
                pRipple
                type="button"
                pSaveEditableRow
                icon="pi pi-check"
                (click)="onSessionDataEditSave(sessionData)"
                class="p-button-rounded p-button-text p-button-success mr-2"
              ></button>
              <button
                *ngIf="editingSessionData"
                pButton
                pRipple
                type="button"
                pCancelEditableRow
                icon="pi pi-times"
                (click)="onSessionDataEditCancel(sessionData)"
                class="p-button-rounded p-button-text p-button-danger"
              ></button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-sessionData>
        <tr>
          <td colspan="7">
            <div class="p-3">
              <p-table
                [value]="[sessionData]"
                dataKey="ID"
                editMode="row"
                [rowHover]="true"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th>Antecedents</th>
                    <th>Consequences</th>
                    <th>Location</th>
                    <th>Response</th>
                    <th>Severity</th>
                    <th style="width: 4rem"></th>
                  </tr>
                </ng-template>
                <ng-template
                  pTemplate="body"
                  let-sessionData
                  let-editingSessionData="editing"
                  let-sessionDataIndex="rowIndex"
                >
                  <tr [pEditableRow]="sessionData">
                    <td>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <p-multiSelect
                            appendTo="body"
                            [showClear]="true"
                            [options]="behaviorAntecedentsOptions()"
                            [(ngModel)]="
                              sessionData.PatientBehaviorAntecedentIDs
                            "
                            optionLabel="text"
                            optionValue="value"
                          />
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{
                            getAntecedentsLabelsName(
                              sessionData.PatientBehaviorAntecedentIDs,
                              sessionData.PatientBehaviorAntecedentOther
                            )
                          }}
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <p-multiSelect
                            appendTo="body"
                            [showClear]="true"
                            [options]="behaviorConsequencesOptions()"
                            [(ngModel)]="
                              sessionData.PatientBehaviorConsequenceIDs
                            "
                            optionLabel="text"
                            optionValue="value"
                          />
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{
                            getConsequencesLabelsName(
                              sessionData.PatientBehaviorConsequenceIDs,
                              sessionData.PatientBehaviorConsequenceOther
                            )
                          }}
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <p-dropdown
                            appendTo="body"
                            [options]="behaviorLocationsOptions()"
                            [(ngModel)]="sessionData.PatientBehaviorLocationID"
                            [checkmark]="true"
                            optionLabel="text"
                            optionValue="value"
                            [showClear]="true"
                          />
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{
                            getLocationName(
                              sessionData.PatientBehaviorLocationID
                            )
                          }}
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <p-dropdown
                            appendTo="body"
                            [options]="behaviorResponsesOptions()"
                            [(ngModel)]="sessionData.PatientBehaviorResponseID"
                            [checkmark]="true"
                            optionLabel="text"
                            optionValue="value"
                            [showClear]="true"
                          />
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{
                            getResponseName(
                              sessionData.PatientBehaviorResponseID
                            )
                          }}
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <p-dropdown
                            appendTo="body"
                            [options]="behaviorSeveritiesOptions()"
                            [(ngModel)]="sessionData.PatientBehaviorSeverityID"
                            [checkmark]="true"
                            optionLabel="text"
                            optionValue="value"
                            [showClear]="true"
                          />
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{
                            getSeverityName(
                              sessionData.PatientBehaviorSeverityID
                            )
                          }}
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td>
                      <div
                        class="flex align-items-center justify-content-center gap-2"
                      >
                        <button
                          *ngIf="!editingSessionData"
                          pButton
                          pRipple
                          type="button"
                          pInitEditableRow
                          icon="pi pi-pencil"
                          (click)="onSessionDataEditInit(sessionData)"
                          class="p-button-rounded p-button-text"
                        ></button>
                        <button
                          *ngIf="editingSessionData"
                          pButton
                          pRipple
                          type="button"
                          pSaveEditableRow
                          icon="pi pi-check"
                          (click)="onSessionDataEditSave(sessionData)"
                          class="p-button-rounded p-button-text p-button-success mr-2"
                        ></button>
                        <button
                          *ngIf="editingSessionData"
                          pButton
                          pRipple
                          type="button"
                          pCancelEditableRow
                          icon="pi pi-times"
                          (click)="onSessionDataEditCancel(sessionData)"
                          class="p-button-rounded p-button-text p-button-danger"
                        ></button>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="6">There are no Behavior entries for this session.</td>
        </tr>
      </ng-template>
    </p-table>
  </mat-tab>
</mat-tab-group>
