import { Component, Input, signal } from '@angular/core';
import { SkillAreaModel } from '../../../core/api/models';
import { SupervisorApiService } from '../../../core/api/services';
import { SupervisorHelperService } from '../../supervisor.helper.service';
import { AlertService } from '../../../shared/services/alert.service';
import { Utils } from '../../../core/utils';

@Component({
  selector: 'app-skill-area',
  templateUrl: './skill-area.component.html',
  styleUrl: '../../supervisor-main.component.css',
})
export class SkillAreaComponent {
  @Input() skillArea: SkillAreaModel | undefined;
  isCollapsed = signal(true);

  constructor(
    private supervisorApiService: SupervisorApiService,
    private supervisorHelperService: SupervisorHelperService,
    private alertService: AlertService
  ) {}

  editSkillArea() {
    console.log('editSkillArea clicked!');
    this.supervisorHelperService.addSkillAreaButtonClicked(
      this.skillArea ?? null
    );
  }

  deleteSkillArea() {
    console.log('deleteSkillArea clicked!');
    if (this.skillArea?.ID != null) {
      this.alertService
        .confirm(
          'Delete Skill Area',
          'Are you sure you want to delete this Skill Area?'
        )
        .subscribe((result) => {
          if (result) {
            this.deleteSkillAreaConfirmed();
          }
        });
    }
  }

  deleteSkillAreaConfirmed() {
    if (this.skillArea?.ID != null) {
      this.supervisorApiService
        .MarkSkillAreaAsDeleted({ skillAreaID: this.skillArea.ID })
        .subscribe(
          () => {
            this.alertService.success('Skill Area marked as Deleted');
            this.supervisorHelperService.triggerUpdateSignal();
          },
          (error) => {
            console.log(error);
            this.alertService.error('Mark Skill area as deleted failed with error: ' + Utils.getServerErrorMessage(error));
          }
        );
    }
  }

  toggleCollapse() {
    this.isCollapsed.update((value) => !value);
  }
}
