import { Component, Input } from '@angular/core';
import { PromptCodeModel, TargetType } from '../../../core/api/models';
import { SupervisorApiService } from '../../../core/api/services';
import { SettingsHelperService } from '../../settings.helper.service';
import { AlertService } from '../../../shared/services/alert.service';

@Component({
  selector: 'app-prompt-code',
  templateUrl: './prompt-code.component.html',
})
export class PromptCodeComponent {
  @Input() promptCode: PromptCodeModel | undefined;
  targetTypes: string = "";

  constructor(
    private supervisorApiService: SupervisorApiService,
    private settingsHelperService: SettingsHelperService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    if (this.promptCode != null) {
      this.targetTypes = this.promptCode?.TargetTypeIDs?.map((id) => TargetType[id]).join(', ') ?? "";
    }
  }
  isCollapsed = true;

  toggleCollapsed() {
    this.isCollapsed = !this.isCollapsed;
  }

  editPromptCode() {
    console.log('editPromptCode clicked!');
    this.settingsHelperService.addPromptCodeButtonClicked(
      this.promptCode ?? null
    );
  }

  deactivatePromptCode() {
    console.log('deactivatePromptCode clicked!');

    this.alertService
      .confirm(
        'Deactivate Prompt Code',
        'Are you sure you want to deactivate this prompt code? It will no longer be available for use.'
      )
      .subscribe((result) => {
        if (result) {
          this.deactivatePromptCodeConfirmed();
        }
      });
  }

  activatePromptCode() {
    console.log('activatePromptCode clicked!');
    if (this.promptCode?.ID != null) {
      this.promptCode.Inactive = false;
      this.supervisorApiService.SavePromptCode({ body: this.promptCode }).subscribe(
        () => {
          this.alertService.success('Prompt code activated successfully');
        },
        (error) => {
          this.alertService.error(error);
        }
      );
    }
  }

  deactivatePromptCodeConfirmed() {
    if (this.promptCode?.ID != null) {
      this.promptCode.Inactive = true;
      this.supervisorApiService
        .SavePromptCode({ body: this.promptCode })
        .subscribe(
          () => {
            this.alertService.success('Prompt code deactivated successfully');
            this.settingsHelperService.onCloseAddPromptCode();
          },
          (error) => {
            this.alertService.error(error);
          }
        );
    }
  }


}
