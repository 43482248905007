import { Component, effect, input, OnInit, signal, ViewChild } from '@angular/core';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexTitleSubtitle,
} from 'ng-apexcharts';
import { GraphApiService, SupervisorApiService } from '../../core/api/services';
import {
  PatientProgramModel,
  PatientSessionEntryModel,
} from '../../core/api/models';
import { MultiSelect, MultiSelectChangeEvent } from 'primeng/multiselect';

type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrl: './programs.component.css',
})
export class ProgramsComponent implements OnInit {
  @ViewChild('chart', { static: false }) chart: ChartComponent | undefined;
  public chartOptions: Partial<ChartOptions>;

  patientID = input.required<string>();
  startDate = signal<Date>(this.dateInPastBy(180));
  endDate = signal<Date>(new Date());

  isLoading: boolean = false;

  programs = signal<PatientProgramModel[]>([]);
  selectedPrograms: PatientProgramModel | null = null;

  dateOptions: any[] = [
    { value: this.dateInPastBy(1), text: '1 day' },
    { value: this.dateInPastBy(5), text: '5 days' },
    { value: this.dateInPastBy(14), text: '14 days' },
    {
      value: this.dateInPastBy(this.daysToBeginningOfMonth()),
      text: 'Month to Date',
    },
    { value: this.dateInPastBy(30), text: '1 month' },
    { value: this.dateInPastBy(90), text: '3 months' },
    { value: this.dateInPastBy(180), text: '6 months' },
    {
      value: this.dateInPastBy(this.daysToBeginningOfYear()),
      text: 'Year to Date',
    },
  ];
  dateOption: string[] = this.dateOptions[4].value;

  constructor(
    private graphApiService: GraphApiService,
    private supervisorApiService: SupervisorApiService
  ) {
    this.chartOptions = {
      series: [
        {
          name: 'chart3',
          data: this.generateDayWiseTimeSeries(
            new Date('11 Jul 2024').getTime(),
            40,
            {
              min: 10,
              max: 60,
            }
          ),
        },
      ],
      chart: {
        height: 350,
        type: 'line',
      },
      title: {
        text: 'Chart',
      },
      xaxis: {
        type: 'datetime',
      },
    };
    effect(() => {
      if (this.patientID()) {
        this.loadData();
      }
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  onDateOptionChange(event: any) {
    this.startDate.set(event.value);
    this.loadGraphData();
  }

  dateInPastBy(numberOfDays: number): Date {
    var date = new Date();
    date.setDate(date.getDate() - numberOfDays);
    return date;
  }

  daysToBeginningOfMonth() {
    let currentDate = new Date();

    return Math.floor(
      (Date.UTC(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      ) -
        Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), 1)) /
        (1000 * 60 * 60 * 24)
    );
  }

  daysToBeginningOfYear() {
    let currentDate = new Date();

    return Math.floor(
      (Date.UTC(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      ) -
        Date.UTC(currentDate.getFullYear(), 1, 1)) /
        (1000 * 60 * 60 * 24)
    );
  }

  generateDayWiseTimeSeries(
    baseval: number,
    count: number,
    yrange: { min: any; max: any }
  ): any[] {
    let i = 0;
    let series = [];
    while (i < count) {
      var x = baseval;
      var y =
        Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

      series.push([x, y]);
      baseval += 86400000;
      i++;
    }
    return series;
  }

  onProgramsChange(event: MultiSelectChangeEvent) {
    // this.graphApiService
    //   .GetSessionEntriesForTarget({
    //     patientID: Number(this.patientID()),
    //     targetID: 2,
    //   })
    //   .subscribe(
    //     (data) => {
    //       console.log(data);
    //       if (data[0].Entries) {
    //         this.loadChart(data[0].Entries);
    //       } else {
    //         console.error('Entries are undefined');
    //       }
    //       this.isLoading = false;
    //     },
    //     (error) => {
    //       console.error('Error fetching data', error);
    //       this.isLoading = false;
    //     }
    //   );
  }

  loadGraphData() {}

  loadData() {
    this.isLoading = true;

    this.supervisorApiService
      .GetPatientPrograms({ patientID: Number(this.patientID()) })
      .subscribe(
        (programs) => {
          console.log(programs);
          this.programs.set(programs);
          this.isLoading = false;
        },
        (error) => {
          console.error('Error fetching data', error);
          this.isLoading = false;
        }
      );
  }
}
