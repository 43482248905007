import { DatePipe } from '@angular/common';
import { Component, input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../auth';
import {
  HealthCheckApiService,
  SupervisorApiService,
  StaffApiService,
} from '../../../core/api/services';
import { AlertService } from '../../../shared/services/alert.service';
import { SupervisorHelperService } from '../../../supervisor/supervisor.helper.service';
import { PatientSessionService } from '../../home/patient-session.service';
import { SessionsBaseComponent } from '../sessions-base.component';
import {
  PatientSessionDataModel,
  PatientSessionModel,
} from '../../../core/api/models';

@Component({
  selector: 'app-view-session',
  templateUrl: './view-session.component.html',
  styleUrl: './view-session.component.css',
})
export class ViewSessionComponent
  extends SessionsBaseComponent
  implements OnInit
{
  patientID = input.required<string>();
  sessionId = input.required<string>();

  openMenu: boolean = false;

  session: PatientSessionModel | null = null;

  constructor(
    private authService: AuthService,
    private healthCheckApiService: HealthCheckApiService,
    private router: Router,
    protected override supervisorApiService: SupervisorApiService,
    private alertService: AlertService,
    protected override supervisorHelperService: SupervisorHelperService,
    private activatedRoute: ActivatedRoute,
    protected override patientSessionService: PatientSessionService,
    protected override staffApiService: StaffApiService,
    private datePipe: DatePipe
  ) {
    super(
      supervisorApiService,
      supervisorHelperService,
      patientSessionService,
      staffApiService
    );
    console.log(
      'SupervisorMain PatientSessionService instance:',
      this.patientSessionService
    );
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.activatedRoute.params.subscribe((params) => {
      this.loadBaseData(Number(params['patientID']), () => {
        this.isLoading = true;
        this.staffApiService
          .GetPatienSession({
            sessionID: Number(params['sessionId']),
          })
          .subscribe(
            (session) => {
              this.session = session;
              console.log('Session:', this.session);
              this.isLoading = false;
            },
            (error) => {
              this.alertService.error('Failed to load session');
              this.isLoading = false;
            }
          );
      });
    });
  }

  switchToStaff() {
    const patientSession = this.patientSessionService.patientSession();
    this.router.navigate([
      '/staff/home/' + this.selectedPatient?.ID + '/' + patientSession?.ID,
    ]);
  }

  backToOpto() {
    window.location.href = this.authService.getOptoURL() ?? '/';
  }
}
