/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum PatientTargetStatus {
  Unspecified = 0,
  Ready = 1,
  Probe = 2,
  Failed_Probe = 3,
  Aquisition = 4,
  Mastered = 5,
  Maintenance = 6,
  Failed_Maintenance = 7,
  Closed = 8
}
