import { Component, effect, input } from '@angular/core';
import { MaintenanceCriteriaFullModel, MasteryCriteriaModel, ProbeCriteriaModel } from '../../core/api/models';
import { SupervisorApiService } from '../../core/api/services';
import { AlertService } from '../../shared/services/alert.service';
import { SettingsHelperService } from '../settings.helper.service';
import { Utils } from '../../core/utils';
import { forkJoin } from 'rxjs';
import { getPatientTargetStatuses } from '../../shared/utils/utils';


@Component({
  selector: 'app-probe-criteria',
  templateUrl: './probe-criteria.component.html',
})
export class ProbeCriteriaComponent {
  patientID = input<string | undefined>();

  probeCriteria: ProbeCriteriaModel = {};
  targetStatuses: any[] = [];
  loadingProbeCriteria: boolean = false;
  hasCustomProbeCriteria: boolean = false;

  constructor(
    private supervisorApiService: SupervisorApiService,
    private alertService: AlertService,
    protected settingsHelperService: SettingsHelperService
  ) {
    effect(() => {
      console.log(
        'update signal triggered: ',
        this.settingsHelperService.updateSignal()
      );
      this.loadData();
    });
  }

  ngOnInit(): void {
    console.log('ProbeCriteriaComponent ngOnInit');
    this.loadData();
  }

  loadData() {
    this.loadingProbeCriteria = true;
    const patinetIdToUse = this.patientID() && !isNaN(Number(this.patientID())) ? Number(this.patientID()) : undefined;
    if (!patinetIdToUse) {
      this.hasCustomProbeCriteria = true;
    }
    const GetProbeCriteriaObservable = this.supervisorApiService
      .GetProbeCriteria({ patientID: patinetIdToUse });

    this.targetStatuses = getPatientTargetStatuses();

    GetProbeCriteriaObservable
      .subscribe((probeCriteria) => {
        if (probeCriteria) {
          this.hasCustomProbeCriteria = true;
          this.probeCriteria = probeCriteria;
        }
        else {
          this.probeCriteria = { PatientID: patinetIdToUse };
        }
        this.loadingProbeCriteria = false;
      }, (err) => {
        this.loadingProbeCriteria = false;
        this.alertService.error(Utils.getAjaxError(err));
      });

  }


  save() {
    this.loadingProbeCriteria = true;
    this.supervisorApiService.SaveProbeCriteria({ body: this.probeCriteria }).subscribe(() => {
      this.loadingProbeCriteria = false;
      this.hasCustomProbeCriteria = true;
      this.alertService.success('Probe criteria saved successfully');
    }, (err) => {
      this.loadingProbeCriteria = false;
      this.alertService.error(Utils.getAjaxError(err));
    });
  }

  enableCustomProbeCriteria() {
    this.hasCustomProbeCriteria = true;
  }
}
