<form [formGroup]="targetForm" (ngSubmit)="onSubmit()" [class.disabled]="isLoading">
  <h2 class="form-title">{{ titleName }}</h2>

  @if (isLoading) {
    <div class="loading">
      <app-loading-indicator />
    </div>
  }

  <div class="control-row">
    <div class="control no-margin">
      <label for="targetName">Target Name</label>
      <input
        class="form-control"
        type="text"
        id="targetName"
        name="targetName"
        formControlName="targetName"
      />
    </div>
    @if (targetForm.controls.targetName.invalid &&
    targetForm.controls.targetName.touched) {
    <span class="control-error">Target Name is required.</span>
    }
  </div>

  <div class="control-row">
    <div class="control no-margin">
      <label for="goalName">BSP Goal</label>
      <p-dropdown
        autoWidth="false"
        [style]="{ width: '100%' }"
        appendTo="body"
        [options]="treatmentGoals"
        formControlName="BSP_TreatmentGoalID"
        optionLabel="Objective"
        optionValue="ID"
        [filter]="true"
        filterBy="Objective"
        [showClear]="true"
        [virtualScroll]="true"
        [virtualScrollItemSize]="50"
        [loading]="isLoading"
        placeholder="Select a Goal"
      >
        <ng-template pTemplate="selectedGoal" let-selectedOption>
          <div class="flex align-items-center gap-2">
            <div>{{ selectedOption.Objective }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
  </div>
  <!-- <div class="control-row">
    <div class="control no-margin">
      <label for="goalName">Goal Name</label>
      <input type="text" id="goalName" formControlName="goalName" />
      @if (targetForm.controls.goalName.invalid &&
      targetForm.controls.goalName.touched) {
      <span class="control-error">Goal Name is required.</span>
      }
    </div>
  </div> -->

  <div class="control-row">
    <div class="control no-margin">
      <label for="selectedSkillArea">Skill Area:</label>
      <p-dropdown
        autoWidth="false"
        [style]="{ width: '100%' }"
        appendTo="body"
        [options]="skillAreas"
        formControlName="SkillAreaID"
        optionLabel="SkillAreaName"
        optionValue="ID"
        [filter]="true"
        filterBy="SkillAreaName"
        [showClear]="true"
        [virtualScroll]="true"
        [virtualScrollItemSize]="50"
        [loading]="isLoading"
        placeholder="Select a Skill Area"
      >
        <ng-template pTemplate="selectedSkillArea" let-selectedOption>
          <div class="flex align-items-center gap-2">
            <div>{{ selectedOption.SkillAreaName }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
  </div>

  <div class="control-row">
    <div class="control no-margin">
      <label for="selectedPrograms">Programs:</label>
      <p-multiSelect
        appendTo="body"
        autoWidth="false"
        [style]="{ width: '100%' }"
        [options]="supervisorHelperService.programs()"
        formControlName="PatientProgramIDs"
        placeholder="Select Programs"
        optionLabel="ProgramName"
        optionValue="ID"
        [filter]="true"
        filterBy="ProgramName"
        display="chip"
        [showClear]="true"
        [virtualScroll]="true"
        [virtualScrollItemSize]="50"
        [loading]="isLoading"
      >
        <ng-template pTemplate="selectedProgram" let-selectedOption>
          <div class="flex align-items-center gap-2">
            <div>{{ selectedOption.ProgramName }}</div>
          </div>
        </ng-template>
      </p-multiSelect>
    </div>
  </div>

  <div class="control-row">
    <div class="control no-margin">
      <label for="selectedPlaylists">Playlists:</label>
      <p-multiSelect
        appendTo="body"
        autoWidth="false"
        [style]="{ width: '100%' }"
        [options]="supervisorHelperService.playlists()"
        formControlName="PatientPlaylistIDs"
        placeholder="Select Playlists"
        optionLabel="PlaylistName"
        optionValue="ID"
        [filter]="true"
        filterBy="PlaylistName"
        display="chip"
        [showClear]="true"
        [virtualScroll]="true"
        [virtualScrollItemSize]="50"
        [loading]="isLoading"
      >
        <ng-template pTemplate="selectedPlaylist" let-selectedOption>
          <div class="flex align-items-center gap-2">
            <div>{{ selectedOption.PlaylistName }}</div>
          </div>
        </ng-template>
      </p-multiSelect>
    </div>
  </div>

  <div class="control-row">
    <div class="control no-margin">
      <label for="discriminativeStimulus">SD (Discriminative Stimulus)</label>
      <input
        class="form-control"
        type="text"
        id="discriminativeStimulus"
        formControlName="discriminativeStimulus"
      />
    </div>
  </div>
  <div class="control-row">
    <div class="control no-margin">
      <label for="targetType">Target Type:</label>
      <p-dropdown
        autoWidth="false"
        [style]="{ width: '100%' }"
        appendTo="body"
        [options]="targetTypes"
        formControlName="targetType"
        optionValue="value"
        optionLabel="text"
        optionDisabled="disabled"
        [loading]="isLoading"
        [disabled]="currentTarget?.HasAnyData ?? false"
      >
      </p-dropdown>
      @if(targetForm.controls.targetType.invalid &&
      targetForm.controls.targetType.touched) {
      <span class="control-error">Target Type is required.</span>
      }
    </div>
  </div>

  @if (usesPrompts()) {
  <div class="control-row">
    <div class="control no-margin">
      <label for="selectedPromptSchedule">Prompt Schedule:</label>
      <p-dropdown
        autoWidth="false"
        [style]="{ width: '100%' }"
        appendTo="body"
        [options]="promptSchedules"
        formControlName="PromptScheduleID"
        optionLabel="PromptScheduleName"
        optionValue="ID"
        [filter]="true"
        filterBy="PromptScheduleName"
        [showClear]="true"
        [virtualScroll]="true"
        [virtualScrollItemSize]="50"
        [loading]="isLoading"
        placeholder="Select a Prompt Schedule"
      >
        <ng-template pTemplate="selectedPromptSchedule" let-selectedOption>
          <div class="flex align-items-center gap-2">
            <div>{{ selectedOption.PromptScheduleName }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      @if (targetForm.controls.PromptScheduleID.invalid &&
      targetForm.controls.PromptScheduleID.touched) {
      <span class="control-error">Prompt Schedule is required.</span>
      }
    </div>
  </div>

  <div class="control-row">
    <div class="control no-margin">
      <label for="promptCodes">Custom Prompt Codes:</label>
      <p-multiSelect
        appendTo="body"
        autoWidth="false"
        [style]="{ width: '100%' }"
        [options]="promptCodes"
        formControlName="CustomPromptCodeIDs"
        placeholder="Select Custom Prompt Codes"
        optionLabel="Text"
        optionValue="ID"
        display="chip"
        [filter]="true"
        filterBy="Text"
        [showClear]="true"
        [virtualScroll]="true"
        [virtualScrollItemSize]="50"
        [loading]="isLoading"
      >
        <ng-template pTemplate="selectedPromptCode" let-selectedOption>
          <div class="flex align-items-center gap-2">
            <div>{{ selectedOption.Text }}</div>
          </div>
        </ng-template>
      </p-multiSelect>
    </div>
  </div>

  } @if(requiresSubTargets()) {
  <div class="control-row">
    <div class="control no-margin">
      <label for="subTargets">Sub Targets:</label>
      <p-multiSelect
        appendTo="body"
        autoWidth="false"
        [style]="{ width: '100%' }"
        [options]="supervisorHelperService.targets()"
        formControlName="SubTargetIDs"
        placeholder="Select Sub Targets"
        optionLabel="TargetName"
        optionValue="ID"
        display="chip"
        [showClear]="true"
      >
      </p-multiSelect>
      @if (targetForm.controls.SubTargetIDs.invalid &&
      targetForm.controls.SubTargetIDs.touched) {
      <span class="control-error">Sub Targets are required.</span>
      }
    </div>
  </div>
  } @if (isTaskAnalysis()) {
  <div class="control-row">
    <div class="control no-margin">
      <label for="IsBackward">Is Backwards</label>
      <p-checkbox
        formControlName="IsBackward"
        [binary]="true"
        inputId="IsBackward"
      />
    </div>
  </div>
  }

  <div class="control-row">
    <div class="control no-margin">
      <label for="desiredTrials">Desired Trials</label>
      <p-inputNumber
        class="w-100"
        [showButtons]="true"
        inputId="desiredTrials"
        formControlName="desiredTrials"
      />
      @if(targetForm.controls.desiredTrials.invalid &&
      targetForm.controls.desiredTrials.touched) {
      <span class="control-error"
        >Desired Trials must be between 0 and 100.</span
      >
      }
    </div>
  </div>

  @if (requiresDuration()) {
  <div class="control-row">
    <div class="control no-margin">
      <label for="duration">Duration</label>
      <p-inputNumber
        autoWidth="false"
        [style]="{ width: '100%' }"
        appendTo="body"
        [showButtons]="true"
        inputId="duration"
        formControlName="duration"
      />
      @if (targetForm.controls.duration.invalid &&
      targetForm.controls.duration.touched) {
      <span class="control-error">Duration is required.</span>
      }
    </div>
  </div>
  } @if (isQuantity()) {
  <div class="control-row">
    <div class="control no-margin">
      <label for="QuantityUnit">Quantity Unit</label>
      <input
        class="form-control"
        type="text"
        id="QuantityUnit"
        formControlName="QuantityUnit"
      />
    </div>
  </div>
  }

  <div class="control-row">
    <div class="control no-margin">
      <label for="targetStatus">Target Status:</label>
      <p-dropdown
        autoWidth="false"
        [style]="{ width: '100%' }"
        appendTo="body"
        [options]="targetStatuses"
        formControlName="targetStatus"
        optionValue="value"
        optionLabel="text"
      >
      </p-dropdown>
    </div>
  </div>

  @if (isInMaintenance()) {
  <div class="control-row">
    <div class="control no-margin">
      <label for="MaintenanceCriteriaID">Maintenance Criteria</label>
      <p-dropdown
        autoWidth="false"
        [style]="{ width: '100%' }"
        appendTo="body"
        [options]="maintenanceCriterias"
        formControlName="MaintenanceCriteriaID"
        optionLabel="text"
        optionValue="value"
      />
    </div>
  </div>
  } @if (!hasAutomaticMasteryOption()) {
  <div class="control-row">
    <div class="control no-margin">
      <label for="ShouldMasterAutomatic">Mastery Calculation:</label>
      <div class="p-fluid">
      <p-selectButton
        [options]="[
          {label: 'Manual', value: false},
          {label: 'Automatic', value: true}
        ]"
        [formControl]="targetForm.controls.ShouldMasterAutomatic"
        autoWidth="false"
        [style]="{ width: '100%', display: 'flex' }"
        size="large"
        styleClass="w-full"
      >
      </p-selectButton>
      </div>
    </div>
  </div>

  @if (selectedAutomaticMasteryOption()) {
  <div class="control-row">
    <div class="control no-margin">
      <label for="CustomMasteryCriteria">Custom Mastery Criteria</label>
      <div class="p-fluid">
      <p-selectButton
        [options]="[
          {label: 'Default', value: false},
          {label: 'Custom', value: true}
        ]"
        [formControl]="targetForm.controls.CustomMasteryCriteria"
        autoWidth="false"
        [style]="{ width: '100%', display: 'flex' }"
        size="large"
        styleClass="w-full"
      >
      </p-selectButton>
      </div>
    </div>
  </div>
  @if (isCustomMasteryCriteria()) {
  <div class="control-row">
    <div class="control no-margin">
      <label for="responsePercentage">Response Percentage</label>
      <p-inputNumber
        [showButtons]="true"
        inputId="responsePercentage"
        formControlName="responsePercentage"
      />
      %
    </div>
  </div>
  <div class="control-row">
    <div class="control no-margin">
      <label for="consecutiveDays">Consecutive Days</label>
      <p-inputNumber
        [showButtons]="true"
        inputId="consecutiveDays"
        formControlName="consecutiveDays"
      />
    </div>
  </div>
  <div class="control-row">
    <div class="control no-margin">
      <label for="FirstTrialMustBeIndependent"
        >First Trial Must Be Independent</label
      >
      <p-checkbox
        formControlName="FirstTrialMustBeIndependent"
        [binary]="true"
        inputId="FirstTrialMustBeIndependent"
      />
    </div>
  </div>
  } } }

  <div class="control-row">
    <div class="control no-margin">
      <label for="instructions">Instructions</label>
      <textarea
        class="form-control"
        id="instructions"
        rows="3"
        formControlName="instructions"
      ></textarea>
    </div>
  </div>

  <div class="control-row">
    <div class="control no-margin">
      <label for="isFavorite">Favorite</label>
      <p-checkbox
        formControlName="isFavorite"
        [binary]="true"
        inputId="isFavorite"
      />
    </div>
  </div>

  <div class="control-row">
    <div class="control no-margin">
      <label for="isSpecialFavorite">Special Favorite</label>
      <p-checkbox
        formControlName="isSpecialFavorite"
        [binary]="true"
        inputId="isSpecialFavorite"
      />
    </div>
  </div>

  <div class="control-row">
    <div class="control no-margin">
      <label for="DateOpened">Date Opened</label>
      <input
        type="date"
        [style]="{ width: '100%' }"
        appendTo="body"
        class="form-control"
        name="DateOpened"
        formControlName="DateOpened"
        autocomplete="off"
      />
    </div>

    <div class="control no-margin">
      <label for="DateBaselined">Date Probed</label>
      <input
        type="date"
        [style]="{ width: '100%' }"
        appendTo="body"
        class="form-control"
        name="DateBaselined"
        formControlName="DateBaselined"
        autocomplete="off"
      />
    </div>
  </div>

  <div class="control-row">
    <div class="control no-margin">
      <label for="DateMastered">Date Mastered</label>
      <input
        type="date"
        [style]="{ width: '100%' }"
        appendTo="body"
        class="form-control"
        name="DateMastered"
        formControlName="DateMastered"
        autocomplete="off"
      />
    </div>

    <div class="control no-margin">
      <label for="DateClosed">Date Closed</label>
      <input
        type="date"
        [style]="{ width: '100%' }"
        appendTo="body"
        class="form-control form-control"
        name="DateClosed"
        formControlName="DateClosed"
        autocomplete="off"
      />
    </div>
  </div>

  <p class="actions" style="position: sticky; bottom: 0;">
    <button type="button" class="switch-mode-button" style="margin-right: auto;" (click)="onCancel()">
      Cancel
    </button>
    @if (currentTarget?.ID) {
    <button type="button" id="archiveButton" [disabled]="isLoading" (click)="archiveButtonClicked()"> {{ currentTarget?.IsArchived ? 'Unarchive' : 'Archive' }} </button>
    }
    <button
      type="submit"
      id="saveTargetButton"
      class="side-menu-button2"
      [disabled]="isLoading"
    >
      {{ actionName }}
    </button>
  </p>
</form>
