<div class="container">
<div class="text-center w-100 " style="margin-top: 100px;">
    <app-loading-indicator *ngIf="loading"></app-loading-indicator>
    <span *ngIf="!loading">
        <h1>Select a patient</h1>
      <p-dropdown
      styleClass="w-100"
      appendTo="body"
      [options]="patientOptions"
      [ngModel]="selectedPatient?.ID"
      name="selectedPatientDropdown"
      id="selectedPatientDropdown"
      [showClear]="true"
      placeholder="Select a patient"
      [filter]="true"
      filterBy="FullName"
      optionLabel="FullName"
      optionValue="ID"
      [virtualScroll]="true"
      [virtualScrollItemSize]="50"
      (onChange)="changedPatient($event)"
      >
      <ng-template pTemplate="selectedItem" let-selectedOption>
        <div class="flex align-items-center gap-2">
          <div>{{ selectedOption.FullName }}</div>
        </div>
      </ng-template>
    </p-dropdown>

    </span>
  </div>
</div>