
<div class="progress-note-inner" >
    <div class="progress-note-section" *ngIf="progressNoteStep == 1">
      <h4 class="w-100 text-center">Select all present at session</h4>
      <div class="progress-note-section-content">
        <div class="progress-note-section-content-item">
          <p-checkbox
            type="checkbox"
            id="select-all-present-at-session"
            class="progress-note-section-content-item-checkbox"
            name="presentAtSession"
            value="Parent/Guardian"
            [(ngModel)]="presentAtSession"
          ></p-checkbox>
          <label
            for="select-all-present-at-session"
            class="ms-2 progress-note-section-content-item-label"
            >Parent/Guardian</label
          >
        </div>
        <div class="progress-note-section-content-item">
          <p-checkbox
            type="checkbox"
            id="select-all-present-at-session"
            class="progress-note-section-content-item-checkbox"
            name="presentAtSession"
            value="BCBA"
            [(ngModel)]="presentAtSession"
          ></p-checkbox>
          <label
            for="select-all-present-at-session"
            class="ms-2 progress-note-section-content-item-label"
            >BCBA</label
          >
        </div>
        <div class="progress-note-section-content-item">
          <p-checkbox
            type="checkbox"
            id="select-all-present-at-session"
            class="progress-note-section-content-item-checkbox"
            name="presentAtSession"
            value="Siblings"
            [(ngModel)]="presentAtSession"
          ></p-checkbox>
          <label
            for="select-all-present-at-session"
            class="ms-2 progress-note-section-content-item-label"
            >Siblings</label
          >
        </div>
        <div class="progress-note-section-content-item">
          <p-checkbox
            type="checkbox"
            id="select-all-present-at-session"
            class="progress-note-section-content-item-checkbox"
            name="presentAtSession"
            value="Peer"
            [(ngModel)]="presentAtSession"
          ></p-checkbox>
          <label
            for="select-all-present-at-session"
            class="ms-2 progress-note-section-content-item-label"
            >Peer</label
          >
        </div>
        <div class="progress-note-section-content-item">
          
          <label
            for="others-present-at-session"
            class="me-2 progress-note-section-content-item-label"
            >Others</label
          >
          <input type="text" id="others-present-at-session" class="form-control" [(ngModel)]="othersPresentAtSession" />
        </div>
      </div>
    </div>
    <div class="progress-note-section" *ngIf="progressNoteStep == 2">
      <h4 class="w-100 text-center">Session Programs</h4>
      <div class="progress-note-section-content">
        <div class="w-100 progress-note-data-summary" [innerHTML]="progressNoteData.Programs" ></div>
      </div>
      <h4 class="w-100 text-center mt-3">Session Data Summary</h4>
      <div class="progress-note-section-content">
        <div class="w-100 progress-note-data-summary" [innerHTML]="progressNoteData.DataSummary" ></div>
      </div>
    </div>
    <div class="progress-note-section" *ngIf="progressNoteStep == 3">
      <h4 class="w-100 text-center">Goals</h4>
      
      <div class="progress-note-section-content" *ngIf="linkedGoals?.length == 0">
        <p>No data on targets linked to goals</p>
      </div>
      <div class="progress-note-section-content" *ngFor="let goal of linkedGoals">
        <h6>{{goal.Objective}}</h6>
        <div>Progress toward this goal:</div>
        <textarea class="form-control" [(ngModel)]="goalProgresses[goal?.ID??0]"  rows="15"></textarea>
      </div>
    </div>
    <div class="progress-note-section" *ngIf="progressNoteStep == 4">
      <h4 class="w-100 text-center">Barriers to progress</h4>
      <div class="progress-note-section-content">
        <textarea class="form-control" [(ngModel)]="progressNoteData.Progress" rows="15"></textarea>
      </div>
    </div>
    <div class="flex justify-content-center text-center gap-2 p-3 w-100">
      <p-button
        label="Back"
        class="me-3"
        severity="secondary"
        [class.hide]="progressNoteStep == 1"
        (onClick)="backProgressNote()"
      />
      <p-button
        label="Next"
        [class.hide]="progressNoteStep == 4"
        (onClick)="nextProgressNote()"
      />
      <p-button
        label="End Session"
        (onClick)="endSession()"
        [class.hide]="progressNoteStep != 4"
      />
    </div>
  </div>