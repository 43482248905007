import { AfterViewInit, ChangeDetectorRef, Component, Directive, effect, Input, OnInit } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';

@Directive()
export class BaseBoxComponent implements AfterViewInit {

  @ViewChild('mainBox', { static: true }) mainBox!: ElementRef;
  @ViewChild('extraBox', { static: true }) extraBox!: ElementRef;
  @ViewChild('extraBox2', { static: true }) extraBox2!: ElementRef;

  scrollEventListnersAdded: { element: any, listener: any }[] = [];
  resizeEventListnersAdded: { element: any, listener: any }[] = [];

  extraBoxes: ElementRef[] = [];
  extraOpened: boolean = false;
  extra2Opened: boolean = false;
  detailsOpened: boolean = false;

  longPressTimeout: any;

  constructor(protected cdRef: ChangeDetectorRef) {

  }

  ngAfterViewInit() {
    // Initialize the position of the dtt-extra-container
    const mainContainer = this.mainBox.nativeElement;
    const extraContainer = this.extraBox?.nativeElement;
    const extraContainer2 = this.extraBox2?.nativeElement;
    const body = document.querySelector('body') as HTMLElement;

    if (mainContainer && extraContainer) {
      // Append the extra container to the anecdotal container
      if (extraContainer) {
        body.appendChild(extraContainer);
        this.updateExtraContainerPosition(mainContainer, extraContainer);
      }
      if (extraContainer2) {
        body.appendChild(extraContainer2);
        this.updateExtraContainerPosition(mainContainer, extraContainer2);
      }

    }

    const updateListener = () => {
      if (extraContainer) {
        this.updateExtraContainerPosition(mainContainer, extraContainer);
      }
      if (extraContainer2) {
        this.updateExtraContainerPosition(mainContainer, extraContainer2);
      }
    };
    // Update position on window resize
    window.addEventListener('resize', updateListener);
    this.resizeEventListnersAdded.push({ element: window, listener: updateListener });

    // Update position on scroll
    this.scrollEventListnersAdded.push({ element: window, listener: updateListener });
    window.addEventListener('scroll', updateListener);

    // Function to check if an element is scrollable
    function isScrollable(element: any) {
      const overflowY = window.getComputedStyle(element).overflowY;
      const isOverflowY = overflowY === 'scroll' || overflowY === 'auto';
      
      const overflowX = window.getComputedStyle(element).overflowX;
      const isOverflowX = overflowX === 'scroll' || overflowX === 'auto';
      
      return (isOverflowY) || (isOverflowX);
    }

    // Select all div elements and filter the scrollable ones
    const scrollableElements = Array.from(document.querySelectorAll('div')).filter(isScrollable);
    scrollableElements.forEach(element => {
      this.scrollEventListnersAdded.push({ element: element, listener: updateListener });
      element.addEventListener('scroll', updateListener);
    });


    if (!this.extraBoxes) {
      this.extraBoxes = [];
    }
    if (this.extraBox) {
      this.extraBoxes.push(this.extraBox);
    }
    if (this.extraBox2) {
      this.extraBoxes.push(this.extraBox2);
    }
    this.cdRef.detectChanges();
  }

  onClickOutside() {
    if (this.extraOpened || this.extra2Opened) {
      this.extraOpened = false;
      this.extra2Opened = false;
    }
  }

  private updateExtraContainerPosition(dttContainer: HTMLElement, extraContainer: HTMLElement): void {
    const top = this.getTopOffsetToBody(dttContainer);
    const rect = dttContainer.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const containerRight = rect.right;
    const spaceOnRight = windowWidth - containerRight;
    const spaceOnLeft = rect.left;
    const extraContainerWidth = extraContainer.offsetWidth; // Width of the extra container

    if (spaceOnRight < extraContainerWidth + 50 && spaceOnLeft >= extraContainerWidth + 50) {
      // Position to the left if there's not enough space on the right
      extraContainer.style.left = `${rect.left - extraContainerWidth - 10}px`;
    } else {
      // Default positioning to the right
      extraContainer.style.left = `${rect.left + rect.width + 10}px`;
    }

    extraContainer.style.top = `${top}px`;
  }

  getTopOffsetToBody(element: HTMLElement): number {
    const rect = element.getBoundingClientRect();
    return rect.top + window.scrollY;
  }


  showExtra(): void {
    this.extraOpened = true;
    if (this.extraOpened) {
      // Hide all other extra containers
      const allExtraContainers = document.querySelectorAll('.extra-container');
      allExtraContainers.forEach((container: Element) => {
        if (container instanceof HTMLElement && container !== this.extraBox.nativeElement) {
          container.classList.add('hide');
        }
      });

      // Ensure the current extra container is visible
      this.extraBox.nativeElement.classList.remove('hide');

      // Update the position of the extra container
      setTimeout(() => {
        const mainContainer = this.mainBox.nativeElement;
        const extraContainer = this.extraBox.nativeElement;
        this.updateExtraContainerPosition(mainContainer, extraContainer);
      });
    }
  }

  showExtra2(): void {
    this.extra2Opened = true;
    if (this.extra2Opened) {
      // Hide all other extra list containers
      const allExtraContainers = document.querySelectorAll('.extra-container');
      allExtraContainers.forEach((container: Element) => {
        if (container instanceof HTMLElement && container !== this.extraBox2.nativeElement) {
          container.classList.add('hide');
        }
      });

      // Ensure the current extra list container is visible
      this.extraBox2.nativeElement.classList.remove('hide');

      // Update the position of the extra list container
      setTimeout(() => {
        const mainContainer = this.mainBox.nativeElement;
        const extra2Container = this.extraBox2.nativeElement;
        this.updateExtraContainerPosition(mainContainer, extra2Container);
      });
    }
  }

  closeExtra() {
    this.extraOpened = false;
  }

  
  closeExtra2() {
    this.extra2Opened = false;
  }

  formatDate(date: Date): string {
    const today = new Date();
    if (date.toDateString() === today.toDateString()) {
      return 'at ' + date.toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      }).toLowerCase();
    } else {
      return 'on ' + date.toLocaleString('en-US', {
        day: 'numeric',
        month: 'short',
        year: '2-digit'
      });
    }
  }

  ngOnDestroy() {
    this.extraBoxes.forEach(box => {
      box.nativeElement.remove();
    });
    this.scrollEventListnersAdded.forEach(listener => {
      listener.element.removeEventListener('scroll', listener.listener);
    });
    this.resizeEventListnersAdded.forEach(listener => {
      listener.element.removeEventListener('resize', listener.listener);
    });
  }

  
  startLongPress(): void {
    this.longPressTimeout = setTimeout(() => {
      this.doLongPress();
    }, 400);
  }

  //Override this to implement long press functionality
  doLongPress(): void {
  }

  endLongPress(): void {
    clearTimeout(this.longPressTimeout);
  }

  openDetails(event: Event) {
    event.stopPropagation();
    this.detailsOpened = true;
  }

  onCloseDetails() {
    this.detailsOpened = false;
  }
}