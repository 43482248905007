@if(isLoading) {
<app-loading-indicator />
} @else { @if (isCollapsed) {
<div
  class="container"
  (click)="toggleCollapsed()"
  [id]="'toggleCollapseButton-' + playlist?.PlaylistName"
>
  <div class="row h-4rem align-items-center">
    <div class="col-3">
      <button
        type="button"
        class="btn btn-primary position-relative program-name"
      >
        {{ playlistNameDisplayName }}
      </button>
    </div>
    <div class="col-1">
      <i class="pi pi-arrow-down" style="font-size: 1rem; color: #719eb6"></i>
    </div>
    <div class="col-4">
      <span class="fs-8 fw-bolder" style="color: #719eb6">
        Included programs/targets:
      </span>
      <span class="fs-8" style="color: #719eb6">
        {{ playlistItemsNames }}
      </span>
    </div>
    @if (playlist?.IsDeleted) {
      <div class="col-2">
        <p-tag id="deleted-tag" severity="danger" value="Deleted" />
      </div>
      }
  </div>
</div>
} @else {
<div class="container">
  <div class="row h-4rem align-items-center">
    <div class="col-3">
      <button
        type="button"
        class="btn btn-primary position-relative program-name"
        (click)="toggleCollapsed()"
        [id]="'toggleCollapseButton-' + playlist?.PlaylistName"
      >
        {{ playlistNameDisplayName }}
      </button>
    </div>
    <div class="col-1">
      <i class="pi pi-arrow-down" style="font-size: 1rem; color: #719eb6"></i>
    </div>
    <div class="col-6">
      <span class="fs-8 fw-bolder" style="color: #719eb6">
        Included programs/targets:
      </span>
      <span class="fs-8" style="color: #719eb6">
        {{ playlistItemsNames }}
      </span>
    </div>
    @if (playlist?.IsDeleted) {
      <div class="col-2">
        <p-tag id="deleted-tag" severity="danger" value="Deleted" />
      </div>
      } @else {
    <div class="col-1">
      <button
        (click)="editPlaylist()"
        [id]="'editPlaylistButton-' + playlist?.PlaylistName"
        class="side-menu-button2"
      >
        Edit
      </button>
    </div>
    <div class="col-1">
      <button
        (click)="deletePlaylist()"
        [id]="'deletePlaylistButton-' + playlist?.PlaylistName"
        class="switch-mode-button"
      >
        Delete
      </button>
    </div>
    }
  </div>
  <div class="row">
    @for (item of playlist?.Items; track item.OrderNr) { @if
    (item.PatientProgramID) {
    <app-program
      [patientID]="patientID()"
      [program]="programForID(item.PatientProgramID)"
    />
    } @else {
    <app-target
      [patientID]="patientID()"
      [target]="targetForID(item.PatientTargetID!)"
    />
    } } @empty {
    <p>There are no programs/targets for this playlist yet.</p>
    }
  </div>
</div>
} }
