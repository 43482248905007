<div class="dashboard">
  <top-bar></top-bar>
  <div *ngIf="!isPreview && (!sessionID || !patientID)" class="text-center w-100 mt-5 text-danger">
    @if (justFinishedSession) {
    Session finished. Redirecting to opto...
    } @else {
      Invalid session or patient
    }
  </div>
  <div class="flags-container" *ngIf="isPreview || (sessionID && patientID)">
    <div class="flags">
      <div class="top-box">
        <div class="flag flex-column">
          <div *ngIf="!isStarted()">
            Session
            <button class="button-start" (click)="startSession()">Start</button>
          </div>
          <div *ngIf="isStarted()">
            {{ timerString() }}
            <button class="button-end" (click)="endSession()">End</button>
          </div>
        </div>
      </div>

      <div class="flags-horizontal" *ngIf="isStarted()">
        @for (target of favoriteTargets; track target.ID) {
        <app-target-selector
          [target]="target"
          [isBottomBox]="false"
          [promptCodes]="target.PromptCodes??[]"
          [subTargetsDictionary]="subTargetsDictionary"
          [promptSchedules]="promptSchedules" 
        ></app-target-selector>
        }
      </div>
    </div>
  </div>

  <div class="flags" *ngIf="isStarted()">
    @for (target of specialFavoriteTargets; track target.ID) {
    <app-target-selector
      [target]="target"
      [isBottomBox]="false"
      [promptCodes]="target.PromptCodes??[]"
      [subTargetsDictionary]="subTargetsDictionary"
      [promptSchedules]="promptSchedules" ></app-target-selector>
    }
    <div class="flags-horizontal">
      @for (behavior of behaviors; track behavior.ID) { 
        @if(isFrequencyBehavior(behavior)) {
      <app-box-behavior-frequency
        [text]="behavior.PatientBehaviorName ?? ''"
        [showDayValue]="false"
        [behaviorID]="behavior.ID ?? 0"
      ></app-box-behavior-frequency>
      } 
      @if(isDurationBehavior(behavior)) {
      <app-box-behavior-duration
        [text]="behavior.PatientBehaviorName ?? ''"
        [behaviorID]="behavior.ID ?? 0"
      ></app-box-behavior-duration>
      } 
    
    }
    </div>
  </div>
  @if (isStarted()) { @for (reminder of showingReminders; track reminder.ID) {
  <div class="alert" (click)="closeReminder(reminder)">
    {{ reminder.Message }}
  </div>
  } }
  <div class="programs" *ngIf="isStarted()">
    @for (itemArea of groupedShowingItems; track itemArea.Title){

    <div class="program-title">{{ itemArea.Title }}</div>
    @for (item of itemArea.items; track $index) {
    <div class="program-name">{{ item.Name }}</div>
    <div class="program-items">
      @for (target of item.Targets; track target.ID) {
      <app-target-selector
        [target]="target"
        [isBottomBox]="true"
        [promptCodes]="target.PromptCodes??[]"
        [subTargetsDictionary]="subTargetsDictionary"
        [promptSchedules]="promptSchedules" ></app-target-selector>
      }
    </div>
    @if (item.ProgramID){
    <div
      class="program-notes"
      (click)="showProgramNotes(programNotesDiv, programNotesTextarea)"
      (clickOutside)="hideProgramNotes(programNotesDiv, programNotesTextarea)"
      #programNotesDiv
    >
      <span
        >Program Notes:
        <i
          class="pi pi-arrow-down"
          *ngIf="!programNotesTextarea.classList.contains('focused')"
        ></i
      ></span>
      <textarea
        class="program-notes-textarea"
        #programNotesTextarea
        [(ngModel)]="item.ProgramNotes"
        (ngModelChange)="updateProgramNotes(item)"
        (blur)="saveProgramNotes(item.ProgramID, item.ProgramNotes!)"
      ></textarea>
    </div>
    } } }
  </div>

  <div class="programs-list-container" *ngIf="isStarted()">
    <div class="programs-list">
      @for (program of filteredPrograms; track program.ID; let i = $index) {
      <div class="program-list-item" (click)="selectProgram(program)" [class.selected]="i == selectedProgramIndex">
        <span
          class="badge-count"
          [class.badge-count-green]="
            countMasteredTargets(program) >= (program.Targets?.length ?? 0)
          "
          [class.badge-count-red]="
            countMasteredTargets(program) < (program.Targets?.length ?? 0)
          "
          >{{ countMasteredTargets(program) }}/{{
            program.Targets?.length ?? 0
          }}</span
        >
        <div class="program-list-item-title">Program</div>
        <div class="program-list-item-name">{{ program.ProgramName }}</div>
        <div class="program-list-item-circles">
          @for (counter of program.Counters; track counter.targetStatus) {
          <div
            class="program-list-item-circle"
            [style.background-color]="counter.color"
          >
            {{ counter.count }}
          </div>
          }
        </div>
      </div>
      } @for (playlist of filteredPlaylists; track playlist.ID; let i = $index) {
      <div class="program-list-item" (click)="selectPlaylist(playlist)"  [class.selected]="i == selectedPlaylistIndex">
        <div class="program-list-item-title">Playlist</div>
        <div class="program-list-item-name">{{ playlist.PlaylistName }}</div>
        <div class="program-list-item-circles"></div>
      </div>
      } @for (target of filteredTargets; track target.ID; let i = $index) {
      <div class="program-list-item" (click)="selectTarget(target)" [class.selected]="i == selectedTargetIndex">
        <div class="program-list-item-title">Target</div>
        <div class="program-list-item-name">{{ target.TargetName }}</div>
        <div class="program-list-item-circles"></div>
      </div>
      }
    </div>
    <div class="programs-list-buttons">
      <input type="text" class="programs-list-search form-control" [(ngModel)]="filterText" (ngModelChange)="filterPrograms()" placeholder="Search"  *ngIf="showFilterDialog"/>
      
      <div class="programs-list-clearsearch" (click)="clearSearchInput()" *ngIf="showFilterDialog">
        <i class="pi pi-times-circle" style="pointer-events: all;"></i>
      </div>
      <button class="programs-list-button" [class.active]="filterText.length > 0" (click)="showFilter()">Search</button>
      <button class="programs-list-button" (click)="nextOnList()">Next</button>
    </div>
  </div>
</div>

<div class="progress-note" (clickOutside)="hideProgressNote()" *ngIf="isStarted()">
  <div
    class="progress-note-text"
    (click)="showProgressNote()"
    [class.hide]="showingProgressNote"
  >
    <span>Progress Note </span>
    <span
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i class="pi pi-arrow-right"></i
    ></span>
  </div>

  <div
    class="progress-note-text"
    (click)="hideProgressNote()"
    [class.hide]="!showingProgressNote"
  >
    <span>Progress Note </span>
    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="pi pi-arrow-left"></i></span>
  </div>
  <app-progress-note class="progress-note-inner" [isPreview]="isPreview" [PatientSessionID]="patientSession?.ID ?? 0" #progressNoteComponent (endSessionEvent)="endSession()"/>

@if (showEndSession) {
<div class="staff-dialog-backdrop" (click)="onCloseEndSession()"></div>
<p-dialog
  header="End Session - Handoff"
  class="staff-dialog"
  [closable]="false"
  [visible]="true"
>
  <app-end-session (close)="onCloseEndSession()" (finished)="onEndSessionFinished()" />
</p-dialog>
}

@if (loading) {
<app-loading-indicator />
}

