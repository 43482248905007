import { Component, effect, input, OnInit, signal } from '@angular/core';
import {
  PatientBehaviorAntecedentModel,
  PatientBehaviorConsequenceModel,
  PatientBehaviorDataModel,
  PatientBehaviorLocationModel,
  PatientBehaviorModel,
  PatientBehaviorResponseModel,
  PatientBehaviorSeverityModel,
  PatientSessionModel,
  PatientTargetDataModel,
  PatientTargetModel,
  TargetType,
} from '../../../core/api/models';
import { Utils } from '../../../core/utils';
import { StaffApiService } from '../../../core/api/services';
import { AlertService } from '../../../shared/services/alert.service';
import { TableRowCollapseEvent, TableRowExpandEvent } from 'primeng/table';

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrl: './session.component.css',
})
export class SessionComponent implements OnInit {
  isLoading: boolean = true;

  showGraph = signal(false);

  session = input.required<PatientSessionModel>();

  targetsData: PatientTargetDataModel[] = [];
  behaviorsData: PatientBehaviorDataModel[] = [];

  targets = input.required<PatientTargetModel[]>();
  behaviors = input.required<PatientBehaviorModel[]>();
  antecedents = input.required<PatientBehaviorAntecedentModel[]>();
  consequences = input.required<PatientBehaviorConsequenceModel[]>();
  locations = input.required<PatientBehaviorLocationModel[]>();
  responses = input.required<PatientBehaviorResponseModel[]>();
  severities = input.required<PatientBehaviorSeverityModel[]>();

  selectedTargetID: number | null = null;

  clonedSessionData: {
    [s: number]: PatientTargetDataModel | PatientBehaviorDataModel;
  } = {};

  sessionBeingEdited: PatientSessionModel | null = null;

  constructor(
    private staffApiService: StaffApiService,
    private alertService: AlertService
  ) {
    effect(() => {
      console.log('Session Targets:', this.session().TargetData);
      console.log('Session Behaviors:', this.session().BehaviorData);
      this.targetsData = this.session().TargetData ?? [];
      this.behaviorsData = this.session().BehaviorData ?? [];
    });
  }

  ngOnInit(): void {
    if (this.targets().length === 0) {
      console.log('Targets not provided, fetching from API');
      this.isLoading = true;
    } else {
      console.log('Targets provided:', this.targets());
    }
  }

  getTargetName(targetId: number) {
    const target = this.targets().find((t) => t.ID === targetId);
    return target?.TargetName ?? 'Unknown';
  }

  getTargetType(targetId: number) {
    const target = this.targets().find((t) => t.ID === targetId);
    const targetName = Utils.patientTargetTypeLabel(target?.TargetType!);
    return targetName;
  }

  targetIsPromptBased(targetData: PatientTargetDataModel | undefined) {
    if (!targetData) return false;
    const target = this.targets().find(
      (t) => t.ID === targetData.PatientTargetID
    );
    return (
      target?.TargetType === TargetType.DiscreteTrialTeaching ||
      target?.TargetType === TargetType.Echoic
    );
  }

  targetIsEchoic(targetData: PatientTargetDataModel | undefined) {
    if (!targetData) return false;
    const target = this.targets().find(
      (t) => t.ID === targetData.PatientTargetID
    );
    return target?.TargetType === TargetType.Echoic;
  }

  canPlotGraph(targetId: number) {
    const target = this.targets().find((t) => t.ID === targetId);
    return target?.TargetType ? Utils.canPlotGraph(target.TargetType) : false;
  }

  onShowGraphClicked(targetId: number | null) {
    this.selectedTargetID = targetId;
    this.showGraph.update((oldValue) => !oldValue);
  }

  firstColorForEchoic(targetData: PatientTargetDataModel) {
    return this.valueColorForTarget(targetData);
  }

  firstTextForEchoic(targetData: PatientTargetDataModel) {
    return this.valueTextForTarget(targetData);
  }

  secondColorForEchoic(targetData: PatientTargetDataModel) {
    return '#5DADE2';
  }

  secondTextForEchoic(targetData: PatientTargetDataModel) {
    return targetData.TextValue?.split('|')[1] ?? 'Unknown';
  }

  valueTextForTarget(targetData: PatientTargetDataModel) {
    // if type uses prompt codes, use it for the text, otherwise use the int value.
    const target = this.targets().find(
      (t) => t.ID === targetData.PatientTargetID
    );
    if (this.targetIsPromptBased(targetData)) {
      const promptCodeForTargetValue = target?.PromptCodes?.find(
        (p) => p.ID === targetData.IntValue
      );

      return promptCodeForTargetValue?.Text ?? 'Unknown';
    } else {
      return targetData.TextValue ?? 'Unknown';
    }
  }

  valueColorForTarget(targetData: PatientTargetDataModel) {
    // if type uses prompt codes, use it for the text, otherwise use the int value.
    const target = this.targets().find(
      (t) => t.ID === targetData.PatientTargetID
    );
    if (this.targetIsPromptBased(targetData)) {
      const promptCodeForTargetValue = target?.PromptCodes?.find(
        (p) => p.ID === targetData.IntValue
      );

      return promptCodeForTargetValue?.Color ?? 'black';
    } else {
      return 'black';
    }
  }

  promptOptionsForTarget(targetData: PatientTargetDataModel) {
    const target = this.targets().find(
      (t) => t.ID === targetData.PatientTargetID
    );
    const promptOptionsForTarget = target?.PromptCodes?.map((p) => {
      return {
        value: p.ID,
        text: p.Text,
      };
    });
    return promptOptionsForTarget;
  }

  extraOptionsForEchoicTarget(targetData: PatientTargetDataModel) {
    const firstPartOfValue = targetData.TextValue?.split('|')[0];
    const extraOptionsForEchoicTarget = [
      {
        value: firstPartOfValue + '|Articulation',
        text: 'Articulation',
      },
      {
        value: firstPartOfValue + '|Volume',
        text: 'Volume',
      },
      {
        value: firstPartOfValue + '|Speed',
        text: 'Speed',
      },
      {
        value: firstPartOfValue + '|Tone',
        text: 'Tone',
      },
      {
        value: firstPartOfValue + '|Rhythm',
        text: 'Rhythm',
      },
    ];
    return extraOptionsForEchoicTarget;
  }

  getBehaviorName(behaviorId: number) {
    const behavior = this.behaviors().find((b) => b.ID === behaviorId);
    return behavior?.PatientBehaviorName ?? 'Unknown';
  }

  getAntecedentsLabelsName(
    antecedentIDs: number[],
    antecedentOther: string | null
  ) {
    if (antecedentIDs.length === 0 && !antecedentOther) {
      return 'None';
    }
    var antecedentsLabel = antecedentIDs
      .map((antecedentId) => {
        const antecedent = this.antecedents().find(
          (a) => a.ID === antecedentId
        );
        return antecedent?.PatientBehaviorAntecedent ?? 'Unknown';
      })
      .join(', ');
    if (antecedentOther) {
      if (antecedentsLabel === '') {
        antecedentsLabel += antecedentOther;
      } else {
        antecedentsLabel += ', ' + antecedentOther;
      }
    }
    return antecedentsLabel;
  }

  getConsequencesLabelsName(
    consequenceIDs: number[],
    consequenceOther: string | null
  ) {
    if (consequenceIDs.length === 0 && !consequenceOther) {
      return 'None';
    }
    var consequenceLabel = consequenceIDs
      .map((consequenceId) => {
        const consequence = this.consequences().find(
          (c) => c.ID === consequenceId
        );
        return consequence?.PatientBehaviorConsequence ?? 'Unknown';
      })
      .join(', ');
    if (consequenceOther) {
      if (consequenceLabel === '') {
        consequenceLabel += consequenceOther;
      } else {
        consequenceLabel += ', ' + consequenceOther;
      }
    }
    return consequenceLabel;
  }

  getLocationName(locationId: number | null) {
    if (!locationId) {
      return 'None';
    }
    const location = this.locations().find((l) => l.ID === locationId);
    return location?.PatientBehaviorLocation ?? 'Unknown';
  }

  getResponseName(responseId: number | null) {
    if (!responseId) {
      return 'None';
    }
    const response = this.responses().find((r) => r.ID === responseId);
    return response?.PatientBehaviorResponse ?? 'Unknown';
  }

  getSeverityName(severityId: number | null) {
    if (!severityId) {
      return 'None';
    }
    const severity = this.severities().find((s) => s.ID === severityId);
    return severity?.PatientBehaviorSeverity ?? 'Unknown';
  }

  onSessionDataEditInit(
    sessionData: PatientTargetDataModel | PatientBehaviorDataModel
  ) {
    console.log('Editing session data:', sessionData);

    this.sessionBeingEdited = this.session();

    this.clonedSessionData[sessionData.ID as number] = {
      ...sessionData,
    } as PatientTargetDataModel | PatientBehaviorDataModel;
  }

  isPatientTargetDataModel(obj: any): obj is PatientTargetDataModel {
    return 'PatientSessionID' in obj && 'PatientTargetID' in obj;
  }

  onSessionDataEditSave(
    sessionData: PatientTargetDataModel | PatientBehaviorDataModel
  ) {
    console.log('Updated Session data:', sessionData);
    this.saveSessionData(this.sessionBeingEdited!);
  }

  onSessionDataEditCancel(
    sessionData: PatientTargetDataModel | PatientBehaviorDataModel
  ) {
    console.log('Cancelled editing session:', sessionData);

    if (this.isPatientTargetDataModel(sessionData)) {
      const index = this.sessionBeingEdited?.TargetData?.findIndex(
        (t) => t.ID === sessionData.ID
      );
      if (index === undefined) {
        console.error('Could not find index for session data:', sessionData);
        return;
      }

      this.sessionBeingEdited!.TargetData![index] = this.clonedSessionData[
        sessionData.ID as number
      ] as PatientTargetDataModel;
      delete this.clonedSessionData[sessionData.ID as number];
    } else {
      const index = this.sessionBeingEdited?.BehaviorData?.findIndex(
        (t) => t.ID === sessionData.ID
      );
      if (index === undefined) {
        console.error('Could not find index for session data:', sessionData);
        return;
      }

      this.sessionBeingEdited!.BehaviorData![index] = this.clonedSessionData[
        sessionData.ID as number
      ] as PatientBehaviorDataModel;
      delete this.clonedSessionData[sessionData.ID as number];
    }
  }

  saveSessionData(newSessionData: PatientSessionModel) {
    this.isLoading = true;
    console.log('Saving session data:', newSessionData);
    this.staffApiService
      .UpdatePatientSession({
        body: newSessionData,
      })
      .subscribe(
        (response) => {
          console.log('Saved session data:', response);
          this.alertService.success('Session data saved successfully');
          this.isLoading = false;
        },
        (error) => {
          console.error('Error saving session data:', error);
          this.alertService.error(
            'Error saving session data - ' + error.Message
          );
          this.isLoading = false;
        }
      );
  }

  onBehaviorsRowExpand(event: TableRowExpandEvent) {
    console.log('Row expanded:', event);
  }

  onBehaviorsRowCollapse(event: TableRowCollapseEvent) {
    console.log('Row collapsed:', event);
  }

  behaviorAntecedentsOptions() {
    const antecedentsOptions = this.antecedents().map((a) => {
      return {
        value: a.ID,
        text: a.PatientBehaviorAntecedent,
      };
    });
    return antecedentsOptions;
  }

  behaviorConsequencesOptions() {
    const consequencesOptions = this.consequences().map((c) => {
      return {
        value: c.ID,
        text: c.PatientBehaviorConsequence,
      };
    });
    return consequencesOptions;
  }

  behaviorLocationsOptions() {
    const locationsOptions = this.locations().map((l) => {
      return {
        value: l.ID,
        text: l.PatientBehaviorLocation,
      };
    });
    return locationsOptions;
  }

  behaviorResponsesOptions() {
    const responsesOptions = this.responses().map((r) => {
      return {
        value: r.ID,
        text: r.PatientBehaviorResponse,
      };
    });
    return responsesOptions;
  }

  behaviorSeveritiesOptions() {
    const severitiesOptions = this.severities().map((s) => {
      return {
        value: s.ID,
        text: s.PatientBehaviorSeverity,
      };
    });
    return severitiesOptions;
  }
}
