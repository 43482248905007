<div>
  <div class="control-row">
    <div class="control no-margin">
      <p-dropdown
        styleClass="w-100"
        appendTo="body"
        [options]="selectableTargets()"
        [(ngModel)]="selectedTarget"
        name="selectedTarget"
        [checkmark]="true"
        optionLabel="text"
        optionValue="value"
        [showClear]="true"
        placeholder="Select a Target"
        [filter]="true"
        filterBy="text"
        display="chip"
        [showClear]="true"
        [virtualScroll]="true"
        [virtualScrollItemSize]="50"
        [loading]="isLoading"
        [disabled]="isLoading"
        (onChange)="onTargetChange($event)"
        ><ng-template pTemplate="selectedTarget" let-selectedOption>
          <div class="flex align-items-center gap-2">
            <div>{{ selectedOption.text }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
  </div>
  <app-target-graph
    *ngIf="selectedTarget"
    [patientID]="patientID()"
    [targetId]="selectedTarget.ID!"
  ></app-target-graph>
</div>
