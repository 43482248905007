import { NgModule } from '@angular/core';
import { RouterModule, Routes, mapToCanActivate } from '@angular/router';
import { AuthGuard } from '../core/auth.guard';
import { StaffMainComponent, StaffHomeComponent } from './';
import { SessionsComponent } from './sessions/sessions.component';
import { ViewSessionComponent } from './sessions/view-session/view-session.component';

const routes: Routes = [
  { path: '', redirectTo: 'staff/home', pathMatch: 'full' },
  { path: 'staff', redirectTo: 'staff/home', pathMatch: 'full' },
  {
    path: 'staff',
    component: StaffMainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'home/:patientID/:sessionID',
        component: StaffHomeComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'preview/:patientID',
        component: StaffHomeComponent,
        canActivate: [AuthGuard],
      },
      {
        path: ':patientID/sessions',
        component: SessionsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: ':patientID/sessions/:sessionId',
        component: ViewSessionComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class StaffRoutingModule {}
