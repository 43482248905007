/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Info } from '../fn/health-check/info';
import { Info$Params } from '../fn/health-check/info';
import { Ping } from '../fn/health-check/ping';
import { Ping$Params } from '../fn/health-check/ping';

@Injectable({ providedIn: 'root' })
export class HealthCheckApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiHealthCheckPingGet()` */
  static readonly ApiHealthCheckPingGetPath = '/api/HealthCheck/Ping';

  /**
   * Reuturns a 200 OK  "pong" response to indicate the service is running.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Ping()` instead.
   *
   * This method doesn't expect any request body.
   */
  Ping$Response(params?: Ping$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return Ping(this.http, this.rootUrl, params, context);
  }

  /**
   * Reuturns a 200 OK  "pong" response to indicate the service is running.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `Ping$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  Ping(params?: Ping$Params, context?: HttpContext): Observable<void> {
    return this.Ping$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiHealthCheckInfoGet()` */
  static readonly ApiHealthCheckInfoGetPath = '/api/HealthCheck/Info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Info()` instead.
   *
   * This method doesn't expect any request body.
   */
  Info$Response(params?: Info$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return Info(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `Info$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  Info(params?: Info$Params, context?: HttpContext): Observable<void> {
    return this.Info$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
