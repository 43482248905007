import {
  ApexAxisChartSeries,
  ApexNonAxisChartSeries,
  ApexAnnotations,
  ApexChart,
  ApexXAxis,
  ApexYAxis,
  ApexDataLabels,
  ApexGrid,
  ApexStroke,
  ApexTitleSubtitle,
  ApexLegend,
  ApexMarkers,
  ApexTooltip,
  ApexResponsive,
  ApexPlotOptions,
  ChartComponent,
} from 'ng-apexcharts';
import { Utils } from '../core/utils';
import { GraphDataModel, GraphFrequency } from '../core/api/models';

export type ChartOptions = {
  series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  annotations: ApexAnnotations;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  labels: string[];
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
  markers: ApexMarkers;
  tooltip: ApexTooltip;
  responsive: ApexResponsive[];
  plotOptions: ApexPlotOptions;
  colors: string[];
};

export class GraphsBaseComponent {
  public chartOptions: Partial<ChartOptions> = {};

  dateOptions: any[] = [
    { value: Utils.dateInPastBy(1), text: '1 day' },
    { value: Utils.dateInPastBy(5), text: '5 days' },
    { value: Utils.dateInPastBy(14), text: '14 days' },
    {
      value: Utils.dateInPastBy(Utils.daysToBeginningOfMonth()),
      text: 'Month to Date',
    },
    { value: Utils.dateInPastBy(30), text: '1 month' },
    { value: Utils.dateInPastBy(90), text: '3 months' },
    { value: Utils.dateInPastBy(180), text: '6 months' },
    {
      value: Utils.dateInPastBy(Utils.daysToBeginningOfYear()),
      text: 'Year to Date',
    },
  ];
  dateOption: string[] = this.dateOptions[5].value;

  frequencyOptions: any[] = [
    { value: GraphFrequency.BySession, text: 'By Session' },
    { value: GraphFrequency.Daily, text: 'Daily' },
    { value: GraphFrequency.Weekly, text: 'Weekly' },
    { value: GraphFrequency.Monthly, text: 'Monthly' },
    { value: GraphFrequency.Yearly, text: 'Yearly' },
  ];
  frequencyOption: GraphFrequency = this.frequencyOptions[0].value;

  loadDefaultGraphOptions() {
    this.chartOptions = {
      legend: {
        position: 'top',
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      chart: {
        height: 300,
        type: 'line',
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: false,
          zoomedArea: {
            fill: {
              color: '#90CAF9',
              opacity: 0.4,
            },
            stroke: {
              color: '#0D47A1',
              opacity: 0.4,
              width: 1,
            },
          },
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp?: number) {
                return new Date(timestamp!).toDateString();
              },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
          autoSelected: 'zoom',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        padding: {
          right: 30,
          left: 20,
        },
      },
      markers: {
        size: 3,
        strokeColors: '#fff',
        strokeWidth: 2,
        strokeOpacity: 0.9,
        fillOpacity: 1,
        shape: 'square',
      },
      tooltip: {
        x: {
          format: 'MMM dd yyyy',
        },
        y: {
          formatter: (value: any) => {
            return value + ' %';
          },
        },
      },
      title: {
        text: 'Chart',
      },
      xaxis: {
        type: 'datetime',
        title: {
          text: 'Dates',
        },
      },
      yaxis: {
        title: {
          text: 'Values',
        },
      },
    };
  }

  loadDefaultLineGraphOptions(graphData: GraphDataModel) {
    this.chartOptions = {
      series: graphData.Series!.map((series) => ({
        name: series.Name ?? 'No Name',
        data: series.DataPoints!.map(
          (data) =>
            [
              data.Date ? new Date(data.Date).getTime() : new Date().getTime(),
              data.Value,
            ] as [number, number | null]
        ),
      })),
      legend: {
        position: 'top',
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: false,
          zoomedArea: {
            fill: {
              color: '#90CAF9',
              opacity: 0.4,
            },
            stroke: {
              color: '#0D47A1',
              opacity: 0.4,
              width: 1,
            },
          },
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp?: number) {
                return new Date(timestamp!).toDateString();
              },
            },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            },
          },
          autoSelected: 'zoom',
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (value: any, { seriesIndex, dataPointIndex, w }) => {
          return value;
        },
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        padding: {
          right: 30,
          left: 20,
        },
      },
      markers: {
        size: 3,
        strokeColors: '#fff',
        strokeWidth: 2,
        strokeOpacity: 0.9,
        fillOpacity: 1,
        shape: 'square',
      },
      tooltip: {
        x: {
          format: 'MMM dd yyyy',
        },
        y: {
          formatter: (value: any) => {
            return value;
          },
        },
      },
      title: {
        text: graphData.Title ?? 'Chart',
      },
      xaxis: {
        type: 'datetime',
        title: {
          text: graphData.XAxisTitle ?? 'X Axis',
        },
      },
      yaxis: {
        title: {
          text: graphData.YAxisTitle ?? 'Y Axis',
        },
      },
    };
  }

  loadTimeOfDayGraphOptions(graphData: GraphDataModel) {
    this.chartOptions = {
      series: graphData.Series!.map((series) => ({
        name: series.Name ?? 'No Name',
        data: series.DataPoints!.map(
          (data) =>
            [
              data.Date ? new Date(data.Date).getTime() : new Date().getTime(),
              data.Value,
            ] as [number, number | null]
        ),
        color: series.Color ?? '',
      })),
      chart: {
        height: 350,
        type: 'scatter',
        zoom: {
          type: 'xy',
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        labels: {
          formatter: (value) => {
            // format time from seconds to HH:MM:SS
            return Utils.formatTime(value);
          },
        },
      },
    };
  }
}
