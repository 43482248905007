<h2 class="form-title">{{ titleName }}</h2>

@if (loadingItems) {
  <div class="loading">
    <app-loading-indicator />
  </div>
}

<form (ngSubmit)="onSubmit()" [class.disabled]="loadingItems">
  <div class="control">
    <label for="name">Name</label>
    <input
      class="form-control"
      type="text"
      id="name"
      name="name"
      [(ngModel)]="enteredName"
    />
  </div>

  <div class="control">
    <label for="selectedItems">Items</label>
    <p-multiSelect
      name="selectedItems"
      autoWidth="false"
      [style]="{ width: '100%' }"
      appendTo="body"
      [options]="availableItems"
      [(ngModel)]="selectedItems"
      display="chip"
      placeholder="Select Items"
    >
      <ng-template let-value pTemplate="selectedItems">
        <div
          class="inline-flex align-items-center gap-2 px-1"
          *ngFor="let option of value"
        >
          <div>{{ option.ProgramName ?? option.TargetName }},</div>
        </div>
        <div *ngIf="!value || value.length === 0">Select Items</div>
      </ng-template>
      <ng-template let-item pTemplate="item">
        <div class="flex align-items-center gap-2">
          <div>{{ item.ProgramName ?? item.TargetName }}</div>
        </div>
      </ng-template>
      <ng-template let-country pTemplate="footer">
        <div class="py-2 px-3">
          <b>
            {{ selectedItems ? selectedItems.length : 0 }}
          </b>
          item{{ (selectedItems ? selectedItems.length : 0) > 1 ? "s" : "" }}
          selected.
        </div>
      </ng-template>
    </p-multiSelect>
  </div>

  <div class="control">
    <label for="orderedItems">Selected Items</label>
    <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
      @for (item of selectedItems; track item) {
      <div class="example-box" cdkDrag>
        {{ item.ProgramName ?? item.TargetName }}
      </div>
      }
    </div>
  </div>

  <div class="actions">
    <button type="button" (click)="onCancel()">Cancel</button>
    <button type="submit" id="savePlaylistButton">{{ actionName }}</button>
  </div>
</form>
