import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { GraphsMainComponent } from './graphs-main.component';
import { ProgramsComponent } from './programs/programs.component';
import { TargetsComponent } from './targets/targets.component';
import { GraphsRoutingModule } from './graphs.routing.module';

import { NgApexchartsModule } from 'ng-apexcharts';
import { TargetGraphComponent } from './targets/target-graph/target-graph.component';
import { BehaviorGraphComponent } from './behaviors/behavior-graph/behavior-graph.component';
import { BehaviorsComponent } from './behaviors/behaviors.component';

const pages = [GraphsMainComponent, ProgramsComponent, TargetsComponent];

@NgModule({
  exports: [TargetGraphComponent],
  declarations: [
    ...pages,
    TargetGraphComponent,
    BehaviorGraphComponent,
    BehaviorsComponent,
  ],
  providers: [DatePipe],
  imports: [
    CommonModule,
    SharedModule,
    GraphsRoutingModule,
    NgApexchartsModule,
  ],
})
export class GraphsModule {
  constructor(@Optional() @SkipSelf() parentModule: GraphsModule) {
    if (parentModule) {
      throw new Error(
        'Module is already loaded. Import in your base AppModule only.'
      );
    }
  }
}
