import { Component, effect, input } from '@angular/core';
import { PatientReminderModel, PromptCodeModel } from '../../core/api/models';
import { SupervisorApiService } from '../../core/api/services';
import { AlertService } from '../../shared/services/alert.service';

import { Utils } from '../../core/utils';
import { SettingsHelperService } from '../../settings/settings.helper.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-prompt-codes-patient',
  templateUrl: './prompt-codes-patient.component.html',
})
export class PromptCodesPatientComponent {
  patientID: number = 0;

  promptCodes: PromptCodeModel[] = [];

  loadingPromptCodes: boolean = false;
  showAddPromptCode = this.settingsHelperService.showAddPromptCode;


  constructor(
    private supervisorApiService: SupervisorApiService,
    private alertService: AlertService,
    protected settingsHelperService: SettingsHelperService,
    private activatedRoute: ActivatedRoute
  ) {
    effect(() => {
      console.log(
        'update signal triggered: ',
        this.settingsHelperService.updateSignal()
      );
      this.loadData();
    });
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.patientID = params['patientID'];
      this.loadData();
    });

  }

  loadData() {
    this.loadingPromptCodes = true;
    this.supervisorApiService
      .GetPromptCodes({ patientID: this.patientID })
      .subscribe(
        (promptCodes) => {
          this.loadingPromptCodes = false;
          this.promptCodes = promptCodes;
        },
        (err) => {
          this.loadingPromptCodes = false;
          this.alertService.error(Utils.getAjaxError(err));
        }
      );
  }

  onCloseAddPromptCode() {
    this.settingsHelperService.onCloseAddPromptCode();
  }

  customizePromptCodes() {
    //call backend to create new prompt code for this patient
    this.loadingPromptCodes = true;
    this.supervisorApiService.CreateCustomPromptCodes({ patientID: this.patientID }).subscribe((result) => {
      this.loadingPromptCodes = false;
      this.loadData();
    });
  }


  addPromptCodeButtonClicked() {
    console.log('addPromptCodeButtonClicked');
    this.settingsHelperService.addPromptCodeButtonClicked(null);
  }

  moveUp(promptCode: PromptCodeModel) {
    if (promptCode.ID != null) {
      this.supervisorApiService.MovePromptCodeUp({ promptCodeID: promptCode.ID }).subscribe(
        () => {
          this.loadData();
        }
      );
    }
  }

  moveDown(promptCode: PromptCodeModel) {
    if (promptCode.ID != null) {
      this.supervisorApiService.MovePromptCodeDown({ promptCodeID: promptCode.ID }).subscribe(
        () => {
        this.loadData();
      }
      );
    }
  }
}
