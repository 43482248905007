import { effect, Injectable, signal } from '@angular/core';
import {
  PatientBehaviorModel,
  PatientPlaylistItemModel,
  PatientPlaylistModel,
  PatientProgramModel,
  PatientReminderModel,
  PatientTargetModel,
  PromptScheduleModel,
  SkillAreaModel,
  TreatmentGoalModel,
  PatientBehaviorAntecedentModel,
  PatientBehaviorConsequenceModel,
} from '../core/api/models';

@Injectable({
  providedIn: 'root',
})
export class SupervisorHelperService {
  updateSignal = signal(0);

  playlists = signal<PatientPlaylistModel[]>([]);
  skillAreas = signal<SkillAreaModel[]>([]);
  programs = signal<PatientProgramModel[]>([]);
  targets = signal<PatientTargetModel[]>([]);
  promptSchedules = signal<PromptScheduleModel[]>([]);
  treatmentGoals = signal<TreatmentGoalModel[]>([]);
  showAddProgram = signal(false);
  showAddStaffAlert = signal(false);
  showAddPlaylist = signal(false);
  showAddTarget = signal(false);
  showAddSkillArea = signal(false);
  showAddReminder = signal(false);
  showAddBehavior = signal(false);
  showAddAntecedent = signal(false);
  showAddConsequence = signal(false);
  programToEdit = signal<PatientProgramModel | null>(null);
  playlistToEdit = signal<PatientPlaylistModel | null>(null);
  targetToEdit = signal<PatientTargetModel | null>(null);
  skillAreaToEdit = signal<SkillAreaModel | null>(null);
  reminderToEdit = signal<PatientReminderModel | null>(null);
  behaviorToEdit = signal<PatientBehaviorModel | null>(null);
  antecedentToEdit = signal<PatientBehaviorAntecedentModel | null>(null);
  consequenceToEdit = signal<PatientBehaviorConsequenceModel | null>(null);
  constructor() {
    console.log('SupervisorHelperService constructor');
    effect(() => {
      console.log('update signal triggered: ', this.updateSignal());
    });
  }

  onCloseAddPlaylist() {
    this.showAddPlaylist.set(false);
    this.updateSignal.update((oldValue) => oldValue + 1);
  }

  onCloseAddTarget() {
    this.showAddTarget.set(false);
    this.updateSignal.update((oldValue) => oldValue + 1);
  }

  onCloseAddProgram() {
    this.showAddProgram.set(false);
    this.updateSignal.update((oldValue) => oldValue + 1);
  }

  onCloseAddStaffAlert() {
    this.showAddStaffAlert.set(false);
    this.updateSignal.update((oldValue) => oldValue + 1);
  }

  onCloseAddAntecedent() {
    this.showAddAntecedent.set(false);
    this.updateSignal.update((oldValue) => oldValue + 1);
  }

  onCloseAddConsequence() {
    this.showAddConsequence.set(false);
    this.updateSignal.update((oldValue) => oldValue + 1);
  }

  additProgramButtonClicked(programToEdit: PatientProgramModel | null) {
    this.programToEdit.set(programToEdit);
    this.showAddProgram.set(true);
  }

  additPlaylistButtonClicked(playlistToEdit: PatientPlaylistModel | null) {
    this.playlistToEdit.set(playlistToEdit);
    this.showAddPlaylist.set(true);
  }

  additTargetButtonClicked(targetToEdit: PatientTargetModel | null) {
    this.targetToEdit.set(targetToEdit);
    this.showAddTarget.set(true);
  }

  addAntecedentButtonClicked(
    antecedentToEdit: PatientBehaviorAntecedentModel | null
  ) {
    this.antecedentToEdit.set(antecedentToEdit);
    this.showAddAntecedent.set(true);
  }

  staffAlertButtonClicked() {
    this.showAddStaffAlert.set(true);
  }

  addSkillAreaButtonClicked(skillAreaToEdit: SkillAreaModel | null) {
    this.skillAreaToEdit.set(skillAreaToEdit);
    this.showAddSkillArea.set(true);
  }

  onCloseAddSkillArea() {
    this.showAddSkillArea.set(false);
    this.updateSignal.update((oldValue) => oldValue + 1);
  }

  addReminderButtonClicked(reminderToEdit: PatientReminderModel | null) {
    this.reminderToEdit.set(reminderToEdit);
    this.showAddReminder.set(true);
  }

  onCloseAddReminder() {
    this.showAddReminder.set(false);
    this.updateSignal.update((oldValue) => oldValue + 1);
  }

  addBehaviorButtonClicked(behaviorToEdit: PatientBehaviorModel | null) {
    this.behaviorToEdit.set(behaviorToEdit);
    this.showAddBehavior.set(true);
  }

  addConsequenceButtonClicked(
    consequenceToEdit: PatientBehaviorConsequenceModel | null
  ) {
    this.consequenceToEdit.set(consequenceToEdit);
    this.showAddConsequence.set(true);
  }

  onCloseAddBehavior() {
    this.showAddBehavior.set(false);
    this.updateSignal.update((oldValue) => oldValue + 1);
  }

  isPatientTargetModel(filter: any): filter is PatientTargetModel {
    return (filter as PatientTargetModel).TargetName !== undefined;
  }

  isSkillAreaModel(filter: any): filter is SkillAreaModel {
    return (filter as SkillAreaModel).SkillAreaName !== undefined;
  }

  isPatientBehaviorModel(filter: any): filter is PatientBehaviorModel {
    return (filter as PatientBehaviorModel).PatientBehaviorName !== undefined;
  }

  isPatientProgramModel(filter: any): filter is PatientProgramModel {
    return (filter as PatientProgramModel).ProgramName !== undefined;
  }

  isPatientPlaylistModel(filter: any): filter is PatientPlaylistModel {
    return (filter as PatientPlaylistModel).PlaylistName !== undefined;
  }

  triggerUpdateSignal() {
    this.updateSignal.update((oldValue) => oldValue + 1);
  }
}
