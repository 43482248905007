<form [formGroup]="behaviorForm" (ngSubmit)="onSubmit()">
  <h2 class="form-title">{{ titleName }}</h2>

  <div class="control-row">
    <div class="control no-margin">
      <label for="patientBehaviorName">Behavior Name</label>
      <input
        class="form-control"
        type="text"
        id="patientBehaviorName"
        name="patientBehaviorName"
        formControlName="patientBehaviorName"
      />
    </div>
  </div>

  <div class="control-row">
    <div class="control no-margin">
      <label for="targetType">Behavior Type:</label>
      <p-dropdown
        autoWidth="false"
        [style]="{ width: '100%' }"
        appendTo="body"
        [options]="patientBehaviorTypes"
        formControlName="behaviorType"
        optionValue="value"
        optionLabel="text"
      >
      </p-dropdown>
      @if(behaviorForm.controls.behaviorType.invalid &&
      behaviorForm.controls.behaviorType.touched) {
      <span class="control-error">Behavior Type is required.</span>
      }
    </div>
  </div>

  <p class="actions">
    <button type="button" (click)="onCancel()">Cancel</button>
    <button type="submit" id="saveBehaviorButton">{{ actionName }}</button>
  </p>
</form>
