<h2 class="form-title">{{ titleName }}</h2>

@if (isLoading) {
  <div class="loading">
    <app-loading-indicator />
  </div>
}

<form (ngSubmit)="onSubmit()" [class.disabled]="isLoading">
  <div class="control">
    <label for="name">Name</label>
    <input
      class="form-control"
      type="text"
      id="name"
      name="name"
      [(ngModel)]="enteredName"
    />
  </div>

  <div class="control">
    <label for="selectedProgram">Targets:</label>
    <p-multiSelect
      appendTo="body"
      autoWidth="false"
      [style]="{ width: '100%' }"
      [options]="targets"
      name="selectedTargets"
      [(ngModel)]="selectedTargets"
      placeholder="Select Target"
      optionLabel="TargetName"
      display="chip"
      [showClear]="true"
    />
  </div>

  <div style="padding-top: 10px; width: 100%;">
    <button id="addTargetButton" style="width: 100%;" class="side-menu-button2" (click)="addTarget($event)" [disabled]="loading">
      Add New Target
    </button>
  </div>

  <div class="control">
    <label for="selectedSkillArea">Skill Area:</label>
    <p-dropdown
      autoWidth="false"
      [style]="{ width: '100%' }"
      appendTo="body"
      [options]="skillAreas"
      [(ngModel)]="selectedSkillArea"
      name="selectedSkillArea"
      [checkmark]="true"
      optionLabel="SkillAreaName"
      [showClear]="true"
      placeholder="Select a Skill Area"
    />
  </div>

  <div class="control">
    <label for="details">Program Objective</label>
    <textarea
      class="form-control"
      id="details"
      rows="5"
      name="details"
      [(ngModel)]="enteredDetails"
    ></textarea>
  </div>

  <p class="actions" >
    <button type="button" (click)="onCancel()" style="margin-right: auto;">Cancel</button>
    @if (programToEdit) {
      <button type="button" id="archiveButton" [disabled]="loading" (click)="archiveButtonClicked()">{{ programToEdit?.IsArchived ? 'Unarchive' : 'Archive' }}</button>
    }
    <button type="submit" id="saveProgramButton" [disabled]="loading">
      {{ actionName }}
    </button>
  </p>
</form>
