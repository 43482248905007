import { Component, effect, EventEmitter, Input, Output } from '@angular/core';
import { SessionProgressNoteModel } from '../../../core/api/models/session-progress-note-model';
import { StaffApiService, SupervisorApiService } from '../../../core/api/services';
import { AlertService } from '../../../shared/services/alert.service';
import { PatientSessionModel, TreatmentGoalModel } from '../../../core/api/models';
import { SupervisorHelperService } from '../../../supervisor/supervisor.helper.service';
import { PatientSessionService } from '../patient-session.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-progress-note',
  templateUrl: './progress-note.component.html'
})
export class ProgressNoteComponent {
  @Input() PatientSessionID!: number;
  @Input() isPreview: boolean = false;
  @Output() endSessionEvent = new EventEmitter<void>();
  possiblePresentAtSession: string[] = ['Parent/Guardian', 'BCBA', 'Siblings', 'Peer'];
  presentAtSession: string[] = [];
  othersPresentAtSession: string = '';
  progressNoteStep: number = 1;
  progressNoteData!: SessionProgressNoteModel;
  treatmentGoals: TreatmentGoalModel[] = [];
  patientSession: PatientSessionModel | null = null;
  linkedGoals: TreatmentGoalModel[] = [];
  goalProgresses: { [key: number]: string } = {};
  constructor(
    private staffApiService: StaffApiService,
    private supervisorApiService: SupervisorApiService,
    private patientSessionService: PatientSessionService
  ) {
    effect(() => {
      if (!this.isPreview) {
        this.patientSession = this.patientSessionService.patientSession();
        this.getData();
      } else {
        this.progressNoteData = {
          PatientSessionID: -1,
          PresentAtSession: '',
          Progress: '',
          DataSummary: '',
          ProgressNoteGoalProgress: []
        };
      }
    });
  }

  getData() {
    const getTreatmentGoalsObservable = this.supervisorApiService.GetPatientTreatmentGoals({
      patientID: this.patientSession?.PatientID ?? 0,
    });
    const getProgressNoteObservable = this.staffApiService.GetSessionProgressNotes({ patientSessionID: this.PatientSessionID });
    forkJoin([getTreatmentGoalsObservable, getProgressNoteObservable]).subscribe(([treatmentGoals, progressNote]) => {
      this.treatmentGoals = treatmentGoals;
      this.progressNoteData = progressNote;
      this.getProgressNoteData();
    });


  }

  getProgressNoteData(progressNote?: SessionProgressNoteModel) {
    this.progressNoteData = progressNote ?? this.progressNoteData;
    if (!this.progressNoteData) {
      this.progressNoteData = {
        PatientSessionID: this.PatientSessionID,
        PresentAtSession: '',
        Progress: '',
        DataSummary: '',
        ProgressNoteGoalProgress: []
      };
    }

    const allPresentAtSession = this.progressNoteData.PresentAtSession?.split(', ') || [];
    this.presentAtSession = allPresentAtSession.filter((item) => this.possiblePresentAtSession.includes(item));
    this.othersPresentAtSession = allPresentAtSession.filter((item) => !this.possiblePresentAtSession.includes(item)).join(', ') || '';

    //get goals that are linked to the session
    this.getLinkedGoals();
  }

  getLinkedGoals() {
    //get distinct goals linked to the session on this.patientSession?.TargetData
    const targetIDsOnSession = this.patientSession?.TargetData?.map(item => item.PatientTargetID).filter(item => !!item) as number[];
    const targetsOnSession = this.patientSessionService.patientTargets().filter(target => !!target && targetIDsOnSession.includes(target.ID ?? 0));
    const goalsLinkedToSession = targetsOnSession.map(target => this.treatmentGoals.find(goal => goal.ID === target.BSP_TreatmentGoalID)).filter(goal => !!goal) as TreatmentGoalModel[];
    //only distinct goals
    this.linkedGoals = goalsLinkedToSession.filter((goal, index, self) => self.findIndex(t => t?.ID === goal?.ID) === index) ?? [];

    //add to goalProgresses the values from this.progressNoteData.ProgressNoteGoalProgress
    this.goalProgresses = this.linkedGoals.reduce((acc, goal) => {
      acc[goal.ID ?? 0] = this.progressNoteData?.ProgressNoteGoalProgress?.find(progress => progress.BSP_TreatmentGoalID === goal.ID)?.Progress ?? '';
      return acc;
    }, {} as { [key: number]: string });

  }

  backProgressNote() {
    if (this.progressNoteStep > 1) {
      this.progressNoteStep--;
      this.saveProgressNote();
    }
  }

  nextProgressNote() {
    this.progressNoteStep++;
    this.saveProgressNote();
  }

  saveProgressNote() {
    if (this.isPreview) {
      return;
    }
    const allPresentAtSession = [...this.presentAtSession, this.othersPresentAtSession];
    this.progressNoteData.PresentAtSession = allPresentAtSession.filter(item => !!item).join(', ');
    this.progressNoteData.ProgressNoteGoalProgress = Object.entries(this.goalProgresses).map(([BSP_TreatmentGoalID, Progress]) => ({
      BSP_TreatmentGoalID: Number(BSP_TreatmentGoalID),
      Progress: Progress,
      PatientSessionID: this.PatientSessionID
    }));
    this.staffApiService.SaveSessionProgressNotes({ body: this.progressNoteData }).subscribe(() => {
      console.log('Progress note saved successfully!');
      //this.alertService.success('Progress note saved successfully!');
    });
  }

  endSession() {
    if (this.isPreview) {
      return;
    }
    this.saveProgressNote();
    this.endSessionEvent.emit();
  }
} 