import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, ChangeDetectorRef, effect } from '@angular/core';
import { PatientSessionService } from '../patient-session.service';
import { PatientBehaviorDataModel, PatientTargetModel, PatientTargetStatus, PromptCodeModel, PromptScheduleModel, TargetType } from '../../../core/api/models';
import { BaseBoxComponent } from '../base-box/base-box.component';

@Component({
  selector: 'app-target-selector',
  templateUrl: './target-selector.component.html',
})
export class TargetSelectorComponent {
  @Input() target?: PatientTargetModel;
  private _promptCodes: PromptCodeModel[] = [];
  @Input() 
  set promptCodes(value: PromptCodeModel[]) {
    this._promptCodes = value.filter(o => !o.Inactive);
  }
  get promptCodes(): PromptCodeModel[] {
    return this._promptCodes;
  }
  @Input() subTargetsDictionary: { [key: number]: PatientTargetModel[] } = {};
  @Input() isBottomBox: boolean = false;
  @Input() enabled?: boolean = true;
  @Input() promptSchedules?: PromptScheduleModel[];
  promptSchedule: PromptScheduleModel | null = null;

  targetStatus: PatientTargetStatus = PatientTargetStatus.Unspecified;

  constructor() {

  }

  ngOnInit(): void {
    this.targetStatus = this.getTargetStatus(this.target!);
    this.promptSchedule = this.promptSchedules?.find(ps => ps.ID === this.target?.PromptScheduleID) ?? null;
    
  }


  isDurationTarget(target: PatientTargetModel) {
    return target.TargetType === TargetType.Duration;
  }

  isFrequencyTarget(target: PatientTargetModel) {
    return target.TargetType === TargetType.Frequency;
  }

  isDttTarget(target: PatientTargetModel) {
    return target.TargetType === TargetType.DiscreteTrialTeaching;
  }

  isConditionalDiscriminationTarget(target: PatientTargetModel) {
    return target.TargetType === TargetType.ConditionalDiscrimination;
  }

  isEchoicTarget(target: PatientTargetModel) {
    return target.TargetType === TargetType.Echoic;
  }

  isFluencyRateTarget(target: PatientTargetModel) {
    return target.TargetType === TargetType.FluencyRate;
  }

  isGroupedFrequencyTarget(target: PatientTargetModel) {
    return target.TargetType === TargetType.GroupedFrequency;
  }

  isJumpToTarget(target: PatientTargetModel) {
    return target.TargetType === TargetType.JumpTo;
  }

  isQuantityTarget(target: PatientTargetModel) {
    return target.TargetType === TargetType.Quantity;
  }

  isTaskAnalysisTarget(target: PatientTargetModel) {
    return target.TargetType === TargetType.TaskAnalysis;
  }

  isToiletingTarget(target: PatientTargetModel) {
    return target.TargetType === TargetType.Toileting;
  }

  isWholePartialIntervalTarget(target: PatientTargetModel) {
    return target.TargetType === TargetType.WholePartialInterval;
  }

  isAnecdotalTarget(target: PatientTargetModel) {
    return target.TargetType === TargetType.Anecdotal;
  }

  isDiscreteTrialTeachingTarget(target: PatientTargetModel) {
    return target.TargetType === TargetType.DiscreteTrialTeaching;
  }


  getTargetStatus(target: PatientTargetModel): PatientTargetStatus {
    return target.TargetStatus ?? PatientTargetStatus.Unspecified;
  }

}
