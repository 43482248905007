

<div class="dashboard">
  <top-bar></top-bar>
  <div class="modules-container">
    <div class="horizontal-flex-list">
      <a class="main-button" [routerLink]="['/settings', 'prompt-codes']" routerLinkActive="selected">
        <span>Prompt Codes</span>
      </a>
      <a class="main-button" [routerLink]="['/settings',  'prompt-schedules']" routerLinkActive="selected">
        <span>Prompt Schedules</span>
      </a>
      <a class="main-button" [routerLink]="['/settings',  'maintanance-criteria']" routerLinkActive="selected">
        <span>Maintanance Criteria</span>
      </a>
      <a class="main-button" [routerLink]="['/settings',  'mastery-criteria']" routerLinkActive="selected">
        <span>Mastery Criteria</span>
      </a>
      <a class="main-button" [routerLink]="['/settings',  'probe-criteria']" routerLinkActive="selected">
        <span>Probe Criteria</span>
      </a>
    </div>

    <router-outlet (activate)="activated($event)"></router-outlet>
  </div>

</div>

@if (loading) {
<app-loading-indicator />
}