import { Component, Input } from '@angular/core';
import { PromptScheduleModel } from '../../../core/api/models';
import { SupervisorApiService } from '../../../core/api/services';
import { SettingsHelperService } from '../../settings.helper.service';
import { AlertService } from '../../../shared/services/alert.service';

@Component({
  selector: 'app-prompt-schedule',
  templateUrl: './prompt-schedule.component.html',
})
export class PromptScheduleComponent {
  @Input() patientID: number=0;
  @Input() promptSchedule: PromptScheduleModel | undefined;

  constructor(
    private supervisorApiService: SupervisorApiService,
    private settingsHelperService: SettingsHelperService,
    private alertService: AlertService
  ) {}

  isCollapsed = true;

  toggleCollapsed() {
    this.isCollapsed = !this.isCollapsed;
  }

  editPromptSchedule() {
    console.log('editPromptSchedule clicked!');
    this.settingsHelperService.addPromptScheduleButtonClicked(
      this.promptSchedule ?? null
    );
  }

  deletePromptSchedule() {
    console.log('deletePromptSchedule clicked!');

    this.alertService
      .confirm(
        'Delete Prompt Schedule',
        'Are you sure you want to delete this prompt schedule?'
      )
      .subscribe((result) => {
        if (result) {
          this.deletePromptCodeConfirmed();
        }
      });
  }

  deletePromptCodeConfirmed() {
    if (this.promptSchedule?.ID != null) {
      this.supervisorApiService
        .MarkPromptScheduleAsDeleted({ promptScheduleID: this.promptSchedule.ID })
        .subscribe(
          () => {
            this.alertService.success('Prompt schedule marked as deleted');
            this.settingsHelperService.onCloseAddPromptSchedule();
          },
          (error) => {
            this.alertService.error(error);
          }
        );
    }
  }
}
