/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum TargetType {
  Anecdotal = 1,
  DiscreteTrialTeaching = 2,
  ConditionalDiscrimination = 3,
  Duration = 4,
  Echoic = 5,
  FluencyRate = 6,
  Frequency = 7,
  GroupedFrequency = 8,
  JumpTo = 9,
  Quantity = 10,
  TaskAnalysis = 11,
  Toileting = 12,
  WholePartialInterval = 13
}
