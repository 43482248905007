import { AfterViewInit, ChangeDetectorRef, Component, effect, Input, OnInit } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { PatientSessionService } from '../patient-session.service';
import { PatientTargetDataModel, PatientTargetModel } from '../../../core/api/models';
import { BaseBoxComponent } from '../base-box/base-box.component';

@Component({
  selector: 'app-box-quantity',
  templateUrl: './box-quantity.component.html',
})
export class BoxQuantityComponent extends BaseBoxComponent {
  text: string | undefined = '-';
  quantity: number | null = null;
  @Input() isTopBox: boolean = true;
  @Input() isBottomBox: boolean = false;
  @Input() target: PatientTargetModel | undefined = undefined;
  expectedAmount: number = 0;
  unit: string = '';

  targetID: number = 0;
  targetData: PatientTargetDataModel[] | null = null;
  quantities: { Quantity: number, Date: string }[] = [];


  constructor(
    protected override cdRef: ChangeDetectorRef,
    private patientSessionService: PatientSessionService) {
    super(cdRef);
    effect(() => {
      const targetData = this.patientSessionService.getTargetData(this.targetID);
      if (targetData) {
        this.targetData = targetData;
        this.quantities = targetData.map(t => ({ Quantity: t.IntValue ?? 0, Date: this.formatDate(new Date(t.RecordedDate ?? '')) }));

      }
      else {
        this.quantities = [];
        this.targetData = null;
      }
    });
  }

  ngOnInit(): void {
    this.targetID = this.target?.ID ?? 0;
    this.unit = this.target?.QuantityUnit ?? 'units';
    this.expectedAmount = this.target?.DesiredTrials ?? 1;
    this.text = this.target?.TargetName ?? '-';
  }

  override doLongPress(): void {
    this.showExtra();
  }

  hitQuantity(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.quantity) {
      this.patientSessionService.addTargetData({
        PatientTargetID: this.targetID,
        IntValue: this.quantity,
        TextValue: '',
        RecordedDate: new Date().toServerString(),
      });
      this.quantity = null;
    }
  }

}