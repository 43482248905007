import { Component, OnInit } from '@angular/core';
import { SupervisorApiService } from '../core/api/services';
import { AlertService } from '../shared/services/alert.service';
import { Utils } from '../core/utils';
import {
  PatientPlaylistModel,
  PatientProgramModel,
  PatientTargetModel,
  PromptScheduleModel,
  SkillAreaModel,
  TreatmentGoalModel,
} from '../core/api/models';
import { SupervisorHelperService } from './supervisor.helper.service';
import { catchError, forkJoin, Observable, of } from 'rxjs';

export class SupervisorBaseComponent {
  isLoading: boolean = false;

  constructor(
    protected supervisorApiService: SupervisorApiService,
    protected alertService: AlertService,
    protected supervisorHelperService: SupervisorHelperService
  ) {}

  loadBaseData(patientID: number, finishedLoading?: () => void) {
    console.log('loadBaseData called');

    this.isLoading = true;

    const getPlaylistsObservable =
      this.supervisorApiService.GetPatientPlaylists({ patientID: patientID });

    const getSkillAreasObservable = this.supervisorApiService.GetSkillAreas({
      patientID: patientID,
    });

    const getProgramsObservable = this.supervisorApiService.GetPatientPrograms({
      patientID: patientID,
    });

    const getTargetsObservable = this.supervisorApiService.GetPatientTargets({
      patientID: patientID,
    });

    const getPromptSchedulesObservable = this.supervisorApiService.GetPromptSchedules({
      patientID: patientID,
    });

    const getTreatmentGoalsObservable = this.supervisorApiService.GetPatientTreatmentGoals({
      patientID: patientID,
    });

    const getDataObservables: Observable<
      | PatientPlaylistModel[]
      | SkillAreaModel[]
      | PatientProgramModel[]
      | PatientTargetModel[]
      | PromptScheduleModel[]
      | TreatmentGoalModel[]
    >[] = [
      getPlaylistsObservable,
      getSkillAreasObservable,
      getProgramsObservable,
      getTargetsObservable,
      getPromptSchedulesObservable,
      getTreatmentGoalsObservable,
    ];

    // Wrap each API call with catchError to handle failure individually
    const wrappedCalls = getDataObservables.map((call) =>
      call.pipe(catchError((error) => of({ error: true, details: error })))
    );

    forkJoin(wrappedCalls).subscribe(
      ([playlists, skillAreas, programs, targets, promptSchedules, treatmentGoals]) => {
        if ('error' in programs) {
          console.log('Error fetching programs:', programs.details);
        } else {
          this.supervisorHelperService.programs.set(
            programs as PatientProgramModel[]
          );
        }
        if ('error' in playlists) {
          console.log('Error fetching playlists:', playlists.details);
        } else {
          this.supervisorHelperService.playlists.set(
            playlists as PatientProgramModel[]
          );
        }
        if ('error' in skillAreas) {
          console.log('Error fetching skill areas:', skillAreas.details);
        } else {
          this.supervisorHelperService.skillAreas.set(
            skillAreas as SkillAreaModel[]
          );
        }
        if ('error' in targets) {
          console.log('Error fetching targets:', targets.details);
        } else {
          this.supervisorHelperService.targets.set(
            targets as PatientTargetModel[]
          );
        }
        if ('error' in promptSchedules) {
          console.log('Error fetching prompt schedules:', promptSchedules.details);
        } else {
          this.supervisorHelperService.promptSchedules.set(
            promptSchedules as PromptScheduleModel[]
          );
        } 
        if ('error' in treatmentGoals) {
          console.log('Error fetching treatment goals:', treatmentGoals.details);
        } else {
          this.supervisorHelperService.treatmentGoals.set(
            treatmentGoals as TreatmentGoalModel[]
          );
        }

        this.isLoading = false;
        if (finishedLoading) {
          finishedLoading();
        }
      }
    );
  }

  playlistsToDisplay(programID: number): string {
    let programsPlaylists = this.supervisorHelperService
      .playlists()
      .filter((playlist) =>
        playlist.Items?.find((item) => item.PatientProgramID === programID)
      );
    return programsPlaylists.length === 0
      ? 'none'
      : programsPlaylists.map((playlist) => playlist.PlaylistName).join(', ');
  }

  skillAreaToDisplay(skillAreaID: number): string {
    let programSkillArea = this.supervisorHelperService
      .skillAreas()
      .find((skillArea) => skillArea.ID === skillAreaID);
    if (programSkillArea) {
      return programSkillArea.SkillAreaName ?? 'Skill Area Name Not Found';
    } else {
      return 'none';
    }
  }
}
