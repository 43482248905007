<div>
  <div class="control-row">
    <div class="control no-margin">
      <p-dropdown
        styleClass="w-100"
        appendTo="body"
        [options]="selectableBehaviors()"
        [(ngModel)]="selectedBehaviorID"
        name="selectedBehavior"
        [checkmark]="true"
        optionLabel="text"
        optionValue="value"
        [showClear]="true"
        placeholder="Select a Behavior"
        [filter]="true"
        filterBy="text"
        display="chip"
        [showClear]="true"
        [virtualScroll]="true"
        [virtualScrollItemSize]="50"
        [loading]="isLoading"
        [disabled]="isLoading"
        (onChange)="onBehaviorChange($event)"
        ><ng-template pTemplate="selectedBehavior" let-selectedOption>
          <div class="flex align-items-center gap-2">
            <div>{{ selectedOption.text }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
  </div>
  @if (selectedBehaviorID != null && selectedBehaviorID >= 0) {
  <app-behavior-graph
    [patientID]="patientID()"
    [behaviorId]="selectedBehaviorID"
  ></app-behavior-graph>
  }
</div>
