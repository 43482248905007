<div class="container">
    <div class="row">
      <div class="col-12">
        <p>Import data from an excel file.</p>

        <input type="file" (change)="onFileChange($event)" />



      </div>
    </div>

    <div class="row mb-5">

    </div>
</div>
<app-loading-indicator *ngIf="loading"></app-loading-indicator>