<div #mainBox [class.top-box]="isTopBox" [class.bottom-box]="isBottomBox" class="pointer" (click)="clickedBox()"
    (clickOutside)="onClickOutside()" [additionalElementRefs]="extraBoxes">
    <div class="flag  no-select" (mousedown)="startLongPress()" (mouseup)="endLongPress()" (mouseleave)="endLongPress()"
        (touchstart)="startLongPress()" (touchend)="endLongPress()" (touchcancel)="endLongPress()">
        <span *ngIf="!isEditing" class="flag-text">{{ text }}</span>
        <input type="number" class="flag-input" *ngIf="isEditing" [(ngModel)]="sessionValue" (blur)="addSessionValue()"
            #inputField (focus)="inputField.select()" [autofocus]="true">
        <span *ngIf="!isEditing" class="badge badge-right">{{ sessionValue }}</span>
        <span *ngIf="!isEditing && showDayValue" class="badge badge-left">{{ dayValue + sessionValue }}</span>
        <button *ngIf="isEditing" class="flag-button top-left green" (click)="decreaseSessionValue()">-</button>
        <button *ngIf="isEditing" class="flag-button bottom-right red" (click)="increaseSessionValue()">+</button>
        <button class="badge-bottom-left" (click)="openDetails($event)" *ngIf="isBottomBox">?</button>
    </div>
</div>


@if (detailsOpened) {
<app-target-details [target]="target" (onCloseDetails)="onCloseDetails()"></app-target-details>
}