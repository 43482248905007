/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum TargetGraphType {
  PercentageCorrectResponding = 0,
  PromptPercentageDistribution = 1,
  PromptCounts = 2,
  TrialsByPrompts = 3,
  DailyPromptPercentage = 4,
  TimeOfDay = 5,
  FrequencySimple = 6,
  DurationSum = 7,
  DurationCount = 8,
  QuantityTotal = 9,
  QuantityIndividual = 10,
  FluencyAverage = 11,
  FluencyIndividual = 12
}
