<div class="dashboard">
  <top-bar></top-bar>
  <div class="modules-container">
    <div class="card">
      <div
        class="card-body"
        style="padding-left: 2rem; padding-right: 2rem; padding-bottom: 2rem"
      >
        <p-multiSelect
          appendTo="body"
          autoWidth="false"
          [group]="true"
          [style]="{ width: '100%' }"
          [options]="possibleFilters()"
          name="selectedFilters"
          [(ngModel)]="selectedFilters"
          display="chip"
          [showClear]="true"
          placeholder="Select Filters"
        >
          <ng-template let-group pTemplate="group">
            <div class="flex align-items-center">
              <span>{{ group.label }}</span>
            </div>
          </ng-template>
          <ng-template let-item pTemplate="footer">
            <div class="py-2 px-3">
              <b>
                {{ selectedFilters().length }}
              </b>
              item{{ selectedFilters().length > 1 ? "s" : "" }}
              selected.
            </div>
          </ng-template>
        </p-multiSelect>
        <p-table
          [value]="filteredSessions()"
          #dt
          dataKey="ID"
          (onRowExpand)="onRowExpand($event)"
          (onRowCollapse)="onRowCollapse($event)"
          [tableStyle]="{ 'min-width': '70rem' }"
          editMode="row"
          [loading]="isLoading"
          [rowHover]="true"
          [rows]="25"
          [rowsPerPageOptions]="[10, 25, 50]"
          [paginator]="true"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        >
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 5rem"></th>
              <th pSortableColumn="ServiceID">
                Service ID <p-sortIcon field="ServiceID" />
              </th>
              <th pSortableColumn="StartedDate">
                Started Date <p-sortIcon field="StartedDate" />
              </th>
              <th pSortableColumn="FinishedDate">
                Finished Date <p-sortIcon field="FinishedDate" />
              </th>
              <th>Handoff Type</th>
              <th style="width: 4rem"></th>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="body"
            let-session
            let-editingSession="editing"
            let-expanded="expanded"
            let-sessionIndex="rowIndex"
          >
            <tr [pEditableRow]="session">
              <td>
                <p-button
                  type="button"
                  pRipple
                  [pRowToggler]="session"
                  [text]="true"
                  [rounded]="true"
                  [plain]="true"
                  [icon]="
                    expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
                  "
                />
              </td>
              <td>
                Service #{{ session.ServiceID }}
                @if (!session.FinishedDate) {
                <a
                  [routerLink]="[
                    '/staff/home/' + session.PatientID + '/' + session.ID
                  ]"
                >
                  <i class="pi pi-th-large"></i>
                </a>
                }
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      type="datetime-local"
                      [(ngModel)]="session.StartedDate"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ session.StartedDate | date : "MM/dd/yyyy HH:mm" }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      type="datetime-local"
                      [(ngModel)]="session.FinishedDate"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ session.FinishedDate | date : "MM/dd/yyyy HH:mm" }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-tag
                  [value]="handOffTextForSession(session)"
                  [severity]="severityForHandoffTypeOfSession(session)"
                />
              </td>
              <td>
                <div
                  class="flex align-items-center justify-content-center gap-2"
                >
                  <button
                    *ngIf="!editingSession"
                    pButton
                    pRipple
                    type="button"
                    pInitEditableRow
                    icon="pi pi-pencil"
                    (click)="onSessionEditInit(session)"
                    class="p-button-rounded p-button-text"
                  ></button>
                  <button
                    *ngIf="editingSession"
                    pButton
                    pRipple
                    type="button"
                    pSaveEditableRow
                    icon="pi pi-check"
                    (click)="onSessionEditSave(session)"
                    class="p-button-rounded p-button-text p-button-success mr-2"
                  ></button>
                  <button
                    *ngIf="editingSession"
                    pButton
                    pRipple
                    type="button"
                    pCancelEditableRow
                    icon="pi pi-times"
                    (click)="onSessionEditCancel(session, sessionIndex)"
                    class="p-button-rounded p-button-text p-button-danger"
                  ></button>
                </div>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-session>
            <tr>
              <td colspan="7">
                <div class="p-3">
                  <app-session
                    [session]="session"
                    [targets]="targets()"
                    [behaviors]="behaviors()"
                    [antecedents]="antecedents"
                    [consequences]="consequences"
                    [locations]="locations"
                    [responses]="responses"
                    [severities]="severities"
                  />
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>

@if (isLoading) {
<app-loading-indicator />
}
