import { Component, input, Input, OnInit, signal } from '@angular/core';

import {
  PatientProgramModel,
  PatientTargetModel,
  PatientTargetStatus,
  TargetTemplateModel,
  TargetType,
} from '../../../core/api/models';
import { SupervisorApiService } from '../../../core/api/services';
import { SupervisorHelperService } from '../../supervisor.helper.service';
import { AlertService } from '../../../shared/services/alert.service';
import { Utils } from '../../../core/utils';
import { SupervisorBaseComponent } from '../../supervisor-base.component';
import { getPatientTargetStatusLabel } from '../../../shared/utils/utils';

@Component({
  selector: 'app-target',
  templateUrl: './target.component.html',
  styleUrl: '../../supervisor-main.component.css',
})
export class TargetComponent extends SupervisorBaseComponent {
  patientID = input.required<string>();

  showGraph = signal(false);

  @Input() program: PatientProgramModel | undefined;
  @Input() target: PatientTargetModel | undefined;

  constructor(
    protected override supervisorApiService: SupervisorApiService,
    protected override alertService: AlertService,
    protected override supervisorHelperService: SupervisorHelperService
  ) {
    super(supervisorApiService, alertService, supervisorHelperService);
  }

  isCollapsed = true;

  toggleCollapsed() {
    this.isCollapsed = !this.isCollapsed;
    console.log(this.target);
  }

  targetTypeToDisplay(targetType: TargetType | undefined): string {
    if (!targetType) {
      return 'none';
    }
    return Utils.patientTargetTypeLabel(targetType);
  }

  editTarget() {
    console.log('editTarget clicked!');
    this.supervisorHelperService.additTargetButtonClicked(this.target ?? null);
  }

  get canPlotGraph() {
    return this.target?.TargetType
      ? Utils.canPlotGraph(this.target.TargetType)
      : false;
  }

  get includedPlaylists() {
    return (
      this.supervisorHelperService
        .playlists()
        .filter((playlist) =>
          playlist.Items?.some(
            (item) => item.PatientTargetID === this.target?.ID
          )
        )
        .map((playlist) => playlist.PlaylistName)
        .join(', ') || 'none'
    );
  }

  get includedSkillArea() {
    return (
      this.supervisorHelperService
        .skillAreas()
        .find((skillArea) => skillArea.ID === this.target?.SkillAreaID)
        ?.SkillAreaName ?? 'none'
    );
  }

  onShowGraphClicked() {
    this.showGraph.update((oldValue) => !oldValue);
  }

  deleteTarget() {
    console.log('deleteTarget clicked!');

    this.alertService
      .confirm(
        'Delete Target',
        'Are you sure you want to delete this target?'
      )
      .subscribe((result) => {
        if (result) {
          this.deleteTargetConfirmed();
        }
      });
  }

  deleteTargetConfirmed() {
    console.log('deleteTargetConfirmed!');
    if (this.target?.ID != null) {
      this.supervisorApiService
        .MarkPatientTargetAsDeleted({ patientTargetID: this.target.ID })
        .subscribe(
          () => {
            this.alertService.success('Target marked as deleted');
            this.supervisorHelperService.triggerUpdateSignal();
          },
          (error) => {
            console.error(error);
            this.alertService.error(Utils.getAjaxError(error));
          }
        );
    }
  }

  getGoalName(goalId: number | undefined | null): string {
    if (!goalId) {
      return 'none';
    }
    return (
      this.supervisorHelperService
        .treatmentGoals()
        .find((goal) => goal.ID === goalId)?.Objective ?? 'none'
    );
  }

  getStatusName(status: PatientTargetStatus | undefined): string {
    if (!status) {
      return 'none';
    }
    return getPatientTargetStatusLabel(status);
  }
}
