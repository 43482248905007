import { Component, effect, input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatientReminderModel, PromptCodeModel, PromptScheduleModel } from '../../core/api/models';
import { SupervisorApiService } from '../../core/api/services';
import { AlertService } from '../../shared/services/alert.service';
import { SettingsHelperService } from '../../settings/settings.helper.service';
import { Utils } from '../../core/utils';

@Component({
  selector: 'app-prompt-schedules-patient',
  templateUrl: './prompt-schedules-patient.component.html',
})
export class PromptSchedulesPatientComponent {
  patientID: number=0;

  promptSchedules: PromptScheduleModel[] = [];

  loadingPromptSchedules: boolean = false;

  showAddPromptSchedule = this.settingsHelperService.showAddPromptSchedule;

  constructor(
    private supervisorApiService: SupervisorApiService,
    private alertService: AlertService,
    protected settingsHelperService: SettingsHelperService,
    private activatedRoute: ActivatedRoute
  ) {
    effect(() => {
      console.log(
        'update signal triggered: ',
        this.settingsHelperService.updateSignal()
      );
      this.loadData();
    });
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.patientID = params['patientID'];
      this.loadData();
    });
  }

  loadData() {
    this.loadingPromptSchedules = true;
    this.supervisorApiService
      .GetPromptSchedules({ patientID: this.patientID })
      .subscribe(
        (promptSchedules) => {
          this.loadingPromptSchedules = false;
          this.promptSchedules = promptSchedules;
        },
        (err) => {
          this.loadingPromptSchedules = false;
          this.alertService.error(Utils.getAjaxError(err));
        }
      );
  }

  onCloseAddPromptSchedule() {
    this.settingsHelperService.onCloseAddPromptSchedule();
  }
  
  customizePromptCodes(){
    //call backend to create new prompt code for this patient
    this.loadingPromptSchedules = true;
    this.supervisorApiService.CreateCustomPromptCodes({ patientID: this.patientID }).subscribe((result) => {
      this.loadingPromptSchedules = false;
      this.loadData();
    });
  }

  addPromptScheduleButtonClicked() {
    this.settingsHelperService.addPromptScheduleButtonClicked(null);
  }
}
