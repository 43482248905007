import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PatientModel, PatientReminderModel, PromptCodeModel, PromptScheduleModel } from '../../../core/api/models';
import { SupervisorApiService } from '../../../core/api/services';
import { SettingsHelperService } from '../../../settings/settings.helper.service';
import { AlertService } from '../../../shared/services/alert.service';
import { Utils } from '../../../core/utils';

@Component({
  selector: 'app-add-prompt-schedule',
  templateUrl: './add-prompt-schedule.component.html',
  styleUrl: './add-prompt-schedule.component.css',
})
export class AddPromptScheduleComponent {
  @Input() patientID: number | null = null;
  promptScheduleToEdit: PromptScheduleModel | null = null;

  @Output() close = new EventEmitter<void>();

  loading: boolean = false;

  titleName: string = 'Add Prompt Schedule';
  actionName: string = 'Add';

  promptScheduleId: number | null = null;

  constructor(
    private supervisorApiService: SupervisorApiService,
    private settingsHelperService: SettingsHelperService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    // EDIT LOGIC
    if (this.settingsHelperService.promptScheduleToEdit() != null) {
      this.titleName = 'Edit Prompt Schedule';
      this.actionName = 'Save';
      this.promptScheduleToEdit = this.settingsHelperService.promptScheduleToEdit();
      this.promptScheduleId = this.promptScheduleToEdit?.ID ?? 0;
    } else {
      this.promptScheduleToEdit = {  };
    }
  }

  onCancel() {
    this.close.emit();
  }

  onSubmit() {
    this.loading = true;
    if (this.patientID) {
      this.promptScheduleToEdit!.PatientID = this.patientID;
    }
    this.supervisorApiService
      .SavePromptSchedule({
        body: this.promptScheduleToEdit!,
      })
      .subscribe(
        (promptSchedule) => {
          console.log(promptSchedule);
          this.loading = false;
          this.alertService.success('Prompt schedule saved successfully');
          this.close.emit();
        },
        (error) => {
          console.error(error);
          this.alertService.error(Utils.getAjaxError(error));
          this.loading = false;
          this.close.emit();
        }
      );
  }
}
