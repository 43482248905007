import { Component, effect, input, signal, ViewChild } from '@angular/core';
import {
  PatientTargetModel,
  GraphDataModel,
  GraphFrequency,
  TargetGraphType,
  TargetType,
  PatientBehaviorModel,
  BehaviorGraphType,
} from '../../../core/api/models';
import { DatePipe } from '@angular/common';
import { MultiSelectChangeEvent } from 'primeng/multiselect';
import {
  GraphApiService,
  StaffApiService,
  SupervisorApiService,
} from '../../../core/api/services';
import { AlertService } from '../../../shared/services/alert.service';
import { Utils } from '../../../core/utils';
import { ChartOptions, GraphsBaseComponent } from '../../graphs-base.component';
import { ChartComponent } from 'ng-apexcharts';
@Component({
  selector: 'app-behavior-graph',
  templateUrl: './behavior-graph.component.html',
  styleUrl: './behavior-graph.component.css',
})
export class BehaviorGraphComponent extends GraphsBaseComponent {
  @ViewChild('chart', { static: false }) chart: ChartComponent | undefined;

  patientID = input.required<string>();
  startDate = signal<Date>(Utils.dateInPastBy(90));
  endDate = signal<Date>(new Date());

  isLoading: boolean = false;

  behaviorId = input.required<number>();
  loadedBehaviors: PatientBehaviorModel[] = [];
  selectedBehavior: PatientBehaviorModel | null = null;

  graphData = signal<GraphDataModel | null>(null);

  graphTypes: any[] = [];
  selectedGraphType: BehaviorGraphType | null = null;

  constructor(
    private graphApiService: GraphApiService,
    private supervisorApiService: SupervisorApiService,
    private staffApiService: StaffApiService,
    private datePipe: DatePipe,
    private alertService: AlertService
  ) {
    super();
    this.loadDefaultGraphOptions();
    effect(() => {
      console.log('behaviorId changed to:', this.behaviorId());
      this.selectedGraphType = null;
      this.loadData();
    });
  }

  ngOnInit(): void {}

  loadData() {
    this.isLoading = true;
    this.staffApiService
      .GetPatientBehaviors({ patientID: Number(this.patientID()) })
      .subscribe(
        (behaviors) => {
          console.log(behaviors);
          this.loadedBehaviors = behaviors;
          this.selectedBehavior =
            behaviors.find((behavior) => behavior.ID == this.behaviorId()) ??
            null;
          this.loadDataForGraph(this.behaviorId());
          this.isLoading = false;
        },
        (error) => {
          console.error('Error fetching data', error);
          this.isLoading = false;
        }
      );
  }

  onGraphTypeChange(event: any) {
    this.selectedGraphType = event.value;
    if (this.behaviorId() >= 0) {
      this.loadDataForGraph(this.behaviorId());
    } else {
      this.graphData.set(null);
      this.updateGraph();
    }
  }

  onBehaviorChange(event: MultiSelectChangeEvent) {
    if (!event.value) {
      return;
    }
    this.loadDataForGraph(event.value.ID);
  }

  updateAvailableGraphTypes() {
    // set the available graph types based on the target type
    switch (this.selectedBehavior?.BehaviorType) {
      default:
        this.graphTypes = [
          {
            text: 'Frequency',
            value: BehaviorGraphType.Frequency,
          },
          {
            text: 'Duration Total',
            value: BehaviorGraphType.DurationTotal,
          },
          {
            text: 'Duration Average',
            value: BehaviorGraphType.DurationAverage,
          },
          {
            text: 'Severity',
            value: BehaviorGraphType.Severity,
          },
          { text: 'Time of Day', value: BehaviorGraphType.TimeOfDay },
          {
            text: 'Antecedents for Behavior',
            value: BehaviorGraphType.AntecedentsForBehavior,
          },
          {
            text: 'Consequences for Behavior',
            value: BehaviorGraphType.ConsequencesForBehavior,
          },
          {
            text: 'Locations for Behavior',
            value: BehaviorGraphType.LocationsForBehavior,
          },
          {
            text: 'Frequency for Antecedents',
            value: BehaviorGraphType.FrequencyForAntecedents,
          },
          {
            text: 'Frequency for Consequences',
            value: BehaviorGraphType.FrequencyForConsequences,
          },
          {
            text: 'Frequency for Locations',
            value: BehaviorGraphType.FrequencyForLocations,
          },
        ];
        if (this.selectedGraphType == null) {
          this.selectedGraphType = BehaviorGraphType.Frequency;
        }
        break;
    }
  }

  loadDataForGraph(behaviorID: number) {
    this.updateAvailableGraphTypes();

    this.isLoading = true;
    this.graphApiService
      .GetBehaviorGraph({
        patientID: Number(this.patientID()),
        behaviorID: behaviorID,
        startTime: this.startDate().toServerString(),
        endTime: new Date().toServerString(),
        graphType: this.selectedGraphType!,
        graphFrequency: this.frequencyOption,
      })
      .subscribe(
        (data) => {
          console.log(data);
          this.graphData.set(data);
          this.updateGraph();
          this.isLoading = false;
        },
        (error) => {
          console.error('Error fetching data', error);
          this.alertService.error('Error fetching data: ' + error.Message);
          this.graphData.set(null);
          this.updateGraph();
          this.isLoading = false;
        }
      );
  }

  shouldDisplayGraphTypeOptions(): boolean {
    return true;
  }

  shouldDisplayFrequencyOptions(): boolean {
    return true;
  }

  shouldDisplayGraph(): boolean {
    return (
      this.isLoading === false && this.behaviorId() >= 0 && !this.isGraphEmpty()
    );
  }

  isGraphEmpty(): boolean {
    // check if the graph data is empty
    if (this.graphData() == null) {
      return true;
    }

    // check if series is empty
    if (this.graphData()?.Series?.length == 0) {
      return true;
    }

    // for some graph types, check the lenght of the first series as well.
    switch (this.selectedGraphType) {
      case BehaviorGraphType.Frequency:
      case BehaviorGraphType.DurationTotal:
      case BehaviorGraphType.DurationAverage:
      case BehaviorGraphType.FrequencyForAntecedents:
      case BehaviorGraphType.FrequencyForConsequences:
      case BehaviorGraphType.FrequencyForLocations:
        return this.graphData()!.Series![0].DataPoints!.length == 0;

      default:
        return false;
    }
  }

  onDateOptionChange(event: any) {
    this.startDate.set(event.value);
    this.loadDataForGraph(this.behaviorId());
  }

  onFrequencyOptionChange(event: any) {
    this.frequencyOption = event.value;
    this.loadDataForGraph(this.behaviorId());
  }

  updateGraph() {
    if (!this.graphData()) {
      // hide graph
      this.chartOptions.series = [];
      return;
    }
    switch (this.selectedGraphType) {
      case BehaviorGraphType.Frequency:
      case BehaviorGraphType.DurationTotal:
      case BehaviorGraphType.DurationAverage:
      case BehaviorGraphType.FrequencyForAntecedents:
      case BehaviorGraphType.FrequencyForConsequences:
      case BehaviorGraphType.FrequencyForLocations:
        this.loadDefaultLineGraphOptions(this.graphData()!);
        break;
      case BehaviorGraphType.TimeOfDay:
        this.loadTimeOfDayGraphOptions(this.graphData()!);
        break;
      case BehaviorGraphType.Severity:
      case BehaviorGraphType.AntecedentsForBehavior:
      case BehaviorGraphType.ConsequencesForBehavior:
      case BehaviorGraphType.LocationsForBehavior:
        this.loadBarGraphOptions();
        break;
    }

    // we need to call this so that the options are actually updated on the graph
    this.chart?.updateOptions(this.chartOptions);
  }

  loadBarGraphOptions() {
    this.chartOptions = {
      title: {
        text: this.graphData()?.Title ?? 'Chart',
      },
      series: [
        {
          name: 'count',
          data:
            this.graphData()?.Series!.map((series) => series.Value ?? 0) ?? [],
        },
      ],
      chart: {
        height: 350,
        type: 'bar',
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
      },
      colors:
        this.graphData()?.Series!.map((series) => series.Color ?? '#008FFB') ??
        [],
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      grid: {
        show: false,
      },
      xaxis: {
        categories:
          this.graphData()?.Series!.map((series) => series.Name ?? '') ?? [],
        labels: {
          style: {
            fontSize: '12px',
          },
        },
      },
    };
  }
}
