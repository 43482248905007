<div class="d-flex flex-row gap-3 ms-3 me-3">
  <button (click)="addConsequenceButtonClicked()" class="side-menu-button2 w-100 " id="addConsequenceButton">
    + Consequence
  </button>
  <button (click)="getFromBIPButtonClicked()" class="side-menu-button2 w-100" *ngIf="false">
    Get From BIP
  </button>
</div>
<p-table
  id="consequences"
  [value]="consequences"
  #dt
  dataKey="ID"
  [tableStyle]="{ 'min-width': '70rem' }"
  [loading]="loadingConsequences"
  [rows]="25"
  [rowsPerPageOptions]="[10, 25, 50]"
  [paginator]="true"
>
  <ng-template pTemplate="body" let-consequence>
    <app-consequence [consequence]="consequence" [id]="consequence.PatientBehaviorConsequence"></app-consequence>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <p
      style="
        text-align: center;
        margin-top: 20px;
        font-size: 1rem;
        color: #719eb6;
      "
    >
    There are no Consequences yet.
    </p>
  </ng-template>
</p-table>

@if (showAddConsequence()) {
<div class="supervisor-dialog-backdrop" (click)="onCloseAddConsequence()"></div>
<dialog class="supervisor-dialog" open>
  <app-add-consequence [patientID]="patientID()" (close)="onCloseAddConsequence()" />
</dialog>
}