@if (showAddProgram()) {
  <div class="supervisor-dialog-backdrop" (click)="onCloseAddProgram()"></div>
  <dialog class="supervisor-dialog" open>
    <app-add-program
      [selectedPatientID]="patientIDAsNumber"
      (close)="onCloseAddProgram()"
    />
  </dialog>
  }
@if (showAddPlaylist()) {
<div class="supervisor-dialog-backdrop" (click)="onCloseAddPlaylist()"></div>
<dialog class="supervisor-dialog" open>
  <app-add-playlist
    [selectedPatient]="selectedPatient"
    (close)="onCloseAddPlaylist()"
  />
</dialog>
} @if (showAddTarget()) {
<div class="supervisor-dialog-backdrop" (click)="onCloseAddTarget()"></div>
<dialog class="supervisor-dialog" open>
  <app-add-target
    [selectedPatient]="selectedPatient"
    (close)="onCloseAddTarget()"
  />
</dialog>
} @if (showAddStaffAlert()) {
<div class="supervisor-dialog-backdrop" (click)="onCloseAddStaffAlert()"></div>
<dialog class="supervisor-dialog" open>
  <app-add-staff-alert (close)="onCloseAddStaffAlert()" />
</dialog>
} @if (showAddSkillArea()) {
<div class="supervisor-dialog-backdrop" (click)="onCloseAddSkillArea()"></div>
<dialog class="supervisor-dialog" open>
  <app-add-skill-area
    (close)="onCloseAddSkillArea()"
    [selectedPatient]="selectedPatient"
  />
</dialog>
} @if (showAddReminder() && selectedPatient) {
<div class="supervisor-dialog-backdrop" (click)="onCloseAddReminder()"></div>
<dialog class="supervisor-dialog" open>
  <app-add-reminder
    [selectedPatient]="selectedPatient"
    (close)="onCloseAddReminder()"
  />
</dialog>
} @if (showAddBehavior()) {
<div class="supervisor-dialog-backdrop" (click)="onCloseAddBehavior()"></div>
<dialog class="supervisor-dialog" open>
  <app-add-behavior
    [selectedPatient]="selectedPatient"
    (close)="onCloseAddBehavior()"
  />
</dialog>
}

<div class="dashboard">
  <top-bar></top-bar>
  <div class="modules-container">
    <div class="horizontal-flex-list" id="supervisorComponentsList">
      <a class="main-button" [routerLink]="['/supervisor', selectedPatient?.ID, 'skillAreas']" routerLinkActive="selected">
        <span>Skill Areas</span>
      </a>
      <a
        class="main-button"
        [routerLink]="['/supervisor', selectedPatient?.ID, 'playlists']"
        routerLinkActive="selected"
      >
        <span>Playlists</span>
      </a>
      <a class="main-button" [routerLink]="['/supervisor', selectedPatient?.ID, 'programs']" routerLinkActive="selected">
        <span>Programs</span>
      </a>
      <a class="main-button" [routerLink]="['/supervisor', selectedPatient?.ID, 'targets']" routerLinkActive="selected">
        <span>Targets</span>
      </a>
      <a class="main-button" [routerLink]="['/supervisor', selectedPatient?.ID, 'behaviors']" routerLinkActive="selected">
        <span>Behaviors</span>
      </a>
      <a class="main-button" [routerLink]="['/supervisor', selectedPatient?.ID, 'antecedents']" routerLinkActive="selected">
        <span>Antecedents</span>
      </a>
      <a class="main-button" [routerLink]="['/supervisor', selectedPatient?.ID, 'consequences']" routerLinkActive="selected">
        <span>Consequences</span>
      </a>

      <a class="main-button" [routerLink]="['/supervisor', selectedPatient?.ID, 'reminders']" routerLinkActive="selected">
        <span>Reminders</span>
      </a>
      <a class="main-button" [routerLink]="['/supervisor', selectedPatient?.ID, 'prompt-codes']" routerLinkActive="selected">
        <span>Prompt Codes</span>
      </a>
      <a
        class="main-button"
        [routerLink]="['/supervisor', selectedPatient?.ID, 'prompt-schedules']"
        routerLinkActive="selected"
      >
        <span>Prompt Schedules</span>
      </a>
      <a class="main-button" [routerLink]="['/supervisor', selectedPatient?.ID, 'maintanance-criteria']" routerLinkActive="selected">
        <span>Maintanance Criteria</span>
      </a>
      <a class="main-button" [routerLink]="['/supervisor', selectedPatient?.ID, 'mastery-criteria']" routerLinkActive="selected">
        <span>Mastery Criteria</span>
      </a>
      <a class="main-button" [routerLink]="['/supervisor', selectedPatient?.ID, 'probe-criteria']" routerLinkActive="selected">
        <span>Probe Criteria</span>
      </a>
      <a class="main-button" [routerLink]="['/supervisor', selectedPatient?.ID, 'import-data']" routerLinkActive="selected">
        <span>Import Data</span>
      </a>
    </div>

    <router-outlet (activate)="activated($event)"></router-outlet>
  </div>
</div>

@if (loading) {
<app-loading-indicator />
}
