import { AfterViewInit, ChangeDetectorRef, Component, effect, Input, OnInit } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { PatientSessionService } from '../patient-session.service';
import { PatientTargetDataModel, PatientTargetModel } from '../../../core/api/models';
import { BaseBoxComponent } from '../base-box/base-box.component';

@Component({
  selector: 'app-box-anecdotal',
  templateUrl: './box-anecdotal.component.html',
})
export class BoxAnecdotalComponent extends BaseBoxComponent  {
  @Input() isTopBox: boolean = true;
  @Input() isBottomBox: boolean = false;
  expectedAmount: number = 0;
  targetID: number = 0;
  @Input() target: PatientTargetModel | undefined = undefined;
  anecdotals: any[] = [];

  currentAnecdotalIndex: number = 0;
  targetData: PatientTargetDataModel[] = [];

  constructor(
    protected override cdRef: ChangeDetectorRef,
    private patientSessionService: PatientSessionService) {
    super(cdRef);
    effect(() => {
      const targetData = this.patientSessionService.getTargetData(this.targetID);
      if (targetData) {
        this.targetData = targetData;
        this.anecdotals = targetData.map(t => ({ID: t.ID, text: t.TextValue, date: this.formatDate(new Date(t.RecordedDate??'')), RecordedDate: t.RecordedDate }));
      }
      else {
        this.targetData = [];
        this.anecdotals = [];
      }
    });
  }


  ngOnInit() {
    this.expectedAmount = this.target?.DesiredTrials??1;
    this.targetID = this.target?.ID??0;
  }


  addOrOpenAnecdotal() {
    if (this.anecdotals.length > 0) {
      this.showExtra();
    } else {
      this.addAnecdotal();
      this.showExtra();
    }
  }

  addAnecdotal() {
    const currentDate = new Date();
    const formattedDate = this.formatDate(currentDate);
    this.anecdotals.push({ text: '', date: formattedDate, RecordedDate: currentDate.toServerString() });
    this.currentAnecdotalIndex = this.anecdotals.length - 1;
  }
  
  saveAnecdotal(text: any) {
    const currentAnecdotal = this.currentAnecdotal();
    currentAnecdotal.text = text;
    let targetDataToSave: PatientTargetDataModel|undefined = this.targetData.find(t => t.ID === currentAnecdotal.ID && t.RecordedDate === currentAnecdotal.RecordedDate);
    if (targetDataToSave) {
        targetDataToSave.TextValue = text;
        this.patientSessionService.saveTargetData(targetDataToSave);
    } else {
      targetDataToSave = {
        IntValue: null,
        PatientSessionID: 0,
        PatientTargetID: this.targetID,
        RecordedDate: new Date().toServerString(),
        TextValue: text
      };
      this.patientSessionService.addTargetData(targetDataToSave);
    }
    
  }

  currentAnecdotal() {
    if (this.anecdotals.length == 0) {
      return { text: '', date: '' };
    }
    return this.anecdotals[this.currentAnecdotalIndex];
  }

  selectAnecdotal(index: number) {
    this.currentAnecdotalIndex = index;
  }

}