<div class="dashboard">
  <div class="header">
    <div class="side-menu-buttons">
      <div class="client-name">
        {{
          selectedPatient?.FirstName +
            " " +
            selectedPatient?.LastName +
            " - Service #" +
            session?.ServiceID
        }}
      </div>
    </div>

    <button class="side-menu-button2" (click)="openMenu = !openMenu">
      Menu
    </button>
    <div class="side-menu-buttons menu-top" *ngIf="openMenu">
      <button
        (click)="switchToStaff()"
        *ngIf="this.patientSessionService.patientSession()?.ID"
        class="switch-mode-button"
      >
        Back to Session
      </button>

      <button
        [routerLink]="['/supervisor', selectedPatient?.ID]"
        class="side-menu-button2"
      >
        Supervisor
      </button>

      <button
        [routerLink]="['/graphs', selectedPatient?.ID]"
        class="side-menu-button2"
      >
        Graphs
      </button>

      <button
        [routerLink]="['/settings/prompt-codes']"
        class="side-menu-button2"
      >
        General Settings
      </button>

      <button (click)="backToOpto()" class="side-menu-button2">
        Back to Opto
      </button>
    </div>
  </div>
  <div class="modules-container">
    <div class="card">
      <div
        class="card-body"
        style="padding-left: 2rem; padding-right: 2rem; padding-bottom: 2rem"
      >
        @if (isLoading) {
        <app-loading-indicator />
        } @else {
        <app-session
          [session]="session!"
          [targets]="targets()"
          [behaviors]="behaviors()"
          [antecedents]="antecedents"
          [consequences]="consequences"
          [locations]="locations"
          [responses]="responses"
          [severities]="severities"
        />
        }
      </div>
    </div>
  </div>
</div>
