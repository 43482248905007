import { Component, effect, input } from '@angular/core';
import { MaintananceLevelModel, MaintenanceCriteriaFullModel, MaintenanceCriteriaModel, MaintenanceCriteriaSettingModel, PatientReminderModel, PromptCodeModel } from '../../core/api/models';
import { SupervisorApiService } from '../../core/api/services';
import { AlertService } from '../../shared/services/alert.service';
import { SettingsHelperService } from '../settings.helper.service';
import { Utils } from '../../core/utils';
import { forkJoin } from 'rxjs';
import { getPatientTargetStatuses } from '../../shared/utils/utils';

@Component({
  selector: 'app-maintanance-criterias',
  templateUrl: './maintanance-criterias.component.html',
})
export class MaintananceCriteriasComponent {
  patientID = input<string | undefined>();

  maintenanceCriterias: MaintenanceCriteriaModel[] = [];
  maintananceCriteriaSettings: MaintenanceCriteriaSettingModel = {};
  maintenanceLevels: MaintananceLevelModel[] = [];
  targetStatuses: any[] = [];
  loadingMaintananceCriteria: boolean = false;
  hasCustomMaintananceCriteria: boolean = false;
  constructor(
    private supervisorApiService: SupervisorApiService,
    private alertService: AlertService,
    protected settingsHelperService: SettingsHelperService
  ) {
    effect(() => {
      console.log(
        'update signal triggered: ',
        this.settingsHelperService.updateSignal()
      );
      this.loadData();
    });
  }

  ngOnInit(): void {
    console.log('MaintananceCriteriaComponent ngOnInit');
    this.loadData();
  }

  loadData() {
    this.loadingMaintananceCriteria = true;
    const patinetIdToUse = this.patientID() && !isNaN(Number(this.patientID())) ? Number(this.patientID()) : undefined;
    if (!patinetIdToUse) {
      this.hasCustomMaintananceCriteria = true;
    }
    const GetMaintenanceCriteriaFullObservable = this.supervisorApiService
      .GetMaintenanceCriteriaFull ({ patientID: patinetIdToUse });

    const GetMaintenanceLevelsObservable = this.supervisorApiService
      .GetMaintananceLevels();

    this.targetStatuses = getPatientTargetStatuses();

    forkJoin([GetMaintenanceCriteriaFullObservable, GetMaintenanceLevelsObservable])
      .subscribe(([maintenanceCriteriaFull, maintenanceLevels]  ) => {
        if (maintenanceCriteriaFull.MaintenanceCriteriaSetting) {
          this.hasCustomMaintananceCriteria = true;
        }
        this.maintenanceLevels = maintenanceLevels;        
        this.maintenanceCriterias = maintenanceCriteriaFull.MaintenanceCriterias??[];
        for (const maintananceLevel of this.maintenanceLevels) {
          if (maintananceLevel.ID && !this.maintenanceCriterias.find(x => x.MaintenanceLevelID === maintananceLevel.ID)) {
            this.maintenanceCriterias.push({MaintenanceLevelID: maintananceLevel.ID, IsActive: true,PatientID: patinetIdToUse});
          }
        }
        this.maintananceCriteriaSettings = maintenanceCriteriaFull.MaintenanceCriteriaSetting??{PatientID: patinetIdToUse};
        this.loadingMaintananceCriteria = false;
      },(err) => {
        this.loadingMaintananceCriteria = false;
        this.alertService.error(Utils.getAjaxError(err));
      });
      
  }

  getMaintananceLevelName(maintenanceLevelID: number | undefined) {
    if (!maintenanceLevelID) {
      return '';
    }
    return this.maintenanceLevels.find(x => x.ID === maintenanceLevelID)?.MaintenanceLevel;
  }

  save() {
    this.loadingMaintananceCriteria = true;
    const maintananceCriteriaSettingsToUse:MaintenanceCriteriaFullModel = {
      PatientID: this.patientID() && !isNaN(Number(this.patientID())) ? Number(this.patientID()) : undefined,
      MaintenanceCriteriaSetting: this.maintananceCriteriaSettings,
      MaintenanceCriterias: this.maintenanceCriterias
    };
    this.supervisorApiService.SaveMaintenanceCriteriaFull({body: maintananceCriteriaSettingsToUse}).subscribe(() => {
      this.loadingMaintananceCriteria = false;
      this.hasCustomMaintananceCriteria = true;
      this.alertService.success('Maintenance criteria saved successfully');
    }, (err) => {
      this.loadingMaintananceCriteria = false;
      this.alertService.error(Utils.getAjaxError(err));
    });
  }

  enableCustomMaintananceCriteria() {
    this.hasCustomMaintananceCriteria = true;
  }
}
