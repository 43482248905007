<div class="container">
  <div class="row">
    <div class="col-6">
      <p>
        <p-multiSelect
          appendTo="body"
          autoWidth="false"
          [group]="true"
          [style]="{ width: '100%' }"
          [options]="possibleFilters()"
          name="selectedFilters"
          [(ngModel)]="selectedFilters"
          display="chip"
          [showClear]="true"
          placeholder="Select Filters"
        >
          <ng-template let-group pTemplate="group">
            <div class="flex align-items-center">
              <span>{{ group.label }}</span>
            </div>
          </ng-template>
          <ng-template let-item pTemplate="footer">
            <div class="py-2 px-3">
              <b>
                {{ selectedFilters().length }}
              </b>
              item{{ selectedFilters().length > 1 ? "s" : "" }}
              selected.
            </div>
          </ng-template>
        </p-multiSelect>
      </p>
    </div>
    <div class="col-6">
      <div class="horizontal-flex-list">
        <label class="checkmarks-label">Search</label>
        <input type="text" id="searchBarInput" [(ngModel)]="searchKeyword" />
      </div>
    </div>
    
  
<div class="d-flex flex-row gap-3 " id="supervisorProgramsButtons">
  
  <button (click)="addProgramButtonClicked()" id="addProgramButton" class="side-menu-button2 w-100">
    + Program
  </button>
  <button (click)="getFromBSPButtonClicked()" class="side-menu-button2 w-100" >
    Get From BSP
  </button>
  </div>
</div>

<p-table
  [value]="filteredPrograms()"
  #dt
  dataKey="ID"
  [tableStyle]="{ 'min-width': '70rem' }"
  [loading]="isLoading"
  [rows]="25"
  [rowsPerPageOptions]="[10, 25, 50]"
  [paginator]="true"
>
  <ng-template pTemplate="body" let-program>
    <app-program [patientID]="patientID()" [program]="program" [id]="program.ProgramName" />
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <p
      style="
        text-align: center;
        margin-top: 20px;
        font-size: 1rem;
        color: #719eb6;
      "
    >
    There are no programs yet.
    </p>
  </ng-template>
</p-table>
