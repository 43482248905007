/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum BehaviorGraphType {
  Frequency = 0,
  DurationTotal = 1,
  DurationAverage = 2,
  Severity = 3,
  TimeOfDay = 4,
  AntecedentsForBehavior = 5,
  ConsequencesForBehavior = 6,
  LocationsForBehavior = 7,
  FrequencyForAntecedents = 8,
  FrequencyForConsequences = 9,
  FrequencyForLocations = 10
}
