import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, ChangeDetectorRef, effect } from '@angular/core';
import { PatientSessionService } from '../patient-session.service';
import { PatientBehaviorAntecedentModel, PatientBehaviorConsequenceModel, PatientBehaviorDataModel, PatientBehaviorLocationModel, PatientBehaviorModel, PatientBehaviorResponseModel, PatientBehaviorSeverityModel, PatientBehaviorType, PatientTargetModel } from '../../../core/api/models';
import { BaseBoxComponent } from '../base-box/base-box.component';

@Component({
  selector: 'app-target-details',
  templateUrl: './target-details.component.html',
})
export class TargetDetailsComponent  {

  @Input() target?: PatientTargetModel;
  showGraph: boolean = false;
  @Output() onCloseDetails: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    protected cdRef: ChangeDetectorRef,
    private patientSessionService: PatientSessionService) {
   
  }


  onSeeGraphs(): void {
    this.showGraph = true;
  }
  onHideGraph(): void {
    this.showGraph = false;
  }
  closeDetails(): void {
    this.onCloseDetails.emit();
  }
}
