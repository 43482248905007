@if (showGraph()) {
<div class="supervisor-dialog-backdrop" (click)="onShowGraphClicked()"></div>
<dialog class="supervisor-dialog" open>
  <app-target-graph
    [patientID]="patientID()"
    [targetId]="target?.ID!"
  ></app-target-graph>
</dialog>
} @if (isCollapsed) {
<div
  class="container"
  (click)="toggleCollapsed()"
  [id]="'toggleCollapseButton-' + target?.TargetName"
>
  <div class="row h-4rem align-items-center">
    <div class="col-3">
      <button
        type="button"
        class="btn btn-primary position-relative program-name"
      >
        {{ target?.TargetName }}
      </button>
    </div>
    <div class="col-1">
      <i class="pi pi-arrow-down" style="font-size: 1rem; color: #719eb6"></i>
    </div>
    <div class="col-2">
      <span class="fs-8 fw-bolder" style="color: #719eb6">
        Included playlists:
      </span>
      <span class="fs-8" style="color: #719eb6">
        {{ includedPlaylists }}
      </span>
    </div>
    <div class="col-2">
      <span class="fs-8 fw-bolder" style="color: #719eb6"> Skill Areas: </span>
      <span class="fs-8" style="color: #719eb6">
        {{ includedSkillArea }}
      </span>
    </div>
    @if (target?.IsDeleted) {
      <div class="col-2">
        <p-tag id="deleted-tag" severity="danger" value="Deleted" />
      </div>
      }
      @if (target?.IsArchived) {
        <div class="col-2">
          <p-tag id="archived-tag" severity="warning" value="Archived" />
        </div>
      }
  </div>
</div>
} @else {
<div class="container">
  <div class="row h-4rem align-items-center">
    <div class="col-3">
      <button
        type="button"
        class="btn btn-primary position-relative program-name"
        (click)="toggleCollapsed()"
        [id]="'toggleCollapseButton-' + target?.TargetName"
      >
        {{ target?.TargetName }}
      </button>
    </div>
    <div class="col-1">
      <i class="pi pi-arrow-up" style="font-size: 1rem; color: #719eb6"></i>
    </div>
    <div class="col-3">
      <span class="fs-8 fw-bolder" style="color: #719eb6">
        Included playlists:
      </span>
      <span class="fs-8" style="color: #719eb6">
        {{ includedPlaylists }}
      </span>
    </div>
    <div class="col-2">
      <span class="fs-8 fw-bolder" style="color: #719eb6"> Skill Areas: </span>
      <span class="fs-8" style="color: #719eb6">
        {{ includedSkillArea }}
      </span>
    </div>
    <div class="col-1">
      @if (canPlotGraph) {
      <button (click)="onShowGraphClicked()" class="side-menu-button2">
        Graph
      </button>
      }
    </div>
    @if (target?.IsDeleted) {
      <div class="col-2">
        <p-tag id="deleted-tag" severity="danger" value="Deleted" />
      </div>
      } @else {
        <div class="col-1">
          <button (click)="editTarget()" class="side-menu-button2" [id]="'editTargetButton-' + target?.TargetName">Edit</button>
        </div>
        <div class="col-1">
          <button (click)="deleteTarget()" class="switch-mode-button" [id]="'deleteTargetButton-' + target?.TargetName">
            Delete
          </button>
        </div>
      }
    
  </div>
  <div class="row p-3">
    <div class="col-12" *ngIf="target">
      <div class="mb-2">
        <strong>Target Name:</strong> {{ target.TargetName }}
      </div>
      <div class="mb-2">
        <strong>Status:</strong> {{ getStatusName(target.TargetStatus) }}
      </div>
      <div class="mb-2">
        <strong>Goal:</strong> {{ getGoalName(target.BSP_TreatmentGoalID) }}
      </div>
      <div class="mb-2">
        <strong>Discriminative Stimulus:</strong>
        {{ target.DiscriminativeStimulus }}
      </div>
      <div class="mb-2">
        <strong>Desired Trials:</strong> {{ target.DesiredTrials }}
      </div>
      <div class="mb-2">
        <strong>Target Type:</strong>
        {{ targetTypeToDisplay(target.TargetType) }}
      </div>
      <div class="mb-2">
        <strong>Instructions:</strong> {{ target.Instructions }}
      </div>
      <div class="mb-2">
        <strong>Favorite:</strong>
        {{
          target.IsFavorite
            ? "Yes"
            : target.IsSpecialFavorite
            ? "Special"
            : "No"
        }}
      </div>
    </div>
  </div>
</div>
}
