import { Component, Input } from '@angular/core';
import { PatientReminderModel } from '../../../core/api/models';
import { SupervisorApiService } from '../../../core/api/services';
import { SupervisorHelperService } from '../../supervisor.helper.service';
import { AlertService } from '../../../shared/services/alert.service';

@Component({
  selector: 'app-reminder',
  templateUrl: './reminder.component.html',
  styleUrl: '../../supervisor-main.component.css',
})
export class ReminderComponent {
  @Input() reminder: PatientReminderModel | undefined;

  constructor(
    private supervisorApiService: SupervisorApiService,
    private supervisorHelperService: SupervisorHelperService,
    private alertService: AlertService
  ) {}

  isCollapsed = true;

  toggleCollapsed() {
    this.isCollapsed = !this.isCollapsed;
  }

  editReminder() {
    console.log('editReminder clicked!');
    this.supervisorHelperService.addReminderButtonClicked(
      this.reminder ?? null
    );
  }

  deleteReminder() {
    console.log('deleteReminder clicked!');

    this.alertService
      .confirm(
        'Delete Reminder',
        'Are you sure you want to delete this reminder?'
      )
      .subscribe((result) => {
        if (result) {
          this.deleteReminderConfirmed();
        }
      });
  }

  deleteReminderConfirmed() {
    if (this.reminder?.ID != null) {
      this.supervisorApiService
        .DeletePatientReminder({ patientReminderID: this.reminder.ID })
        .subscribe(
          () => {
            this.alertService.success('Reminder deleted successfully');
            this.supervisorHelperService.triggerUpdateSignal();
          },
          (error) => {
            this.alertService.error(error);
          }
        );
    }
  }
}
