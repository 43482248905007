<div class="dashboard">
  <top-bar></top-bar>
  @if (selectedPatient) {
  <div class="modules-container">
    <div class="horizontal-flex-list">
      <a
        [routerLink]="['/graphs', selectedPatient.ID, 'targets']"
        routerLinkActive="selected"
      >
        <span>Targets</span>
      </a>
      <!-- <a
        [routerLink]="['/graphs', selectedPatient.ID, 'programs']"
        routerLinkActive="selected"
      >
        <span>Programs</span>
      </a> -->
      <a
        [routerLink]="['/graphs', selectedPatient.ID, 'behaviors']"
        routerLinkActive="selected"
      >
        <span>Behaviors</span>
      </a>
    </div>

    <router-outlet (activate)="activated($event)"></router-outlet>
  </div>
  }
</div>
