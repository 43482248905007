import { AfterViewInit, Component, OnInit } from "@angular/core";
import { StaffApiService } from "./core/api/services";
import { Router } from "@angular/router";

@Component({
  selector: 'app-select-patient',
  templateUrl: './select-patient.component.html',
})
export class SelectPatientComponent implements OnInit {

    selectedPatient: { ID: number | undefined, FullName: string } | null = null;
    patientID: number | null = null;
    loading: boolean = true;
    patientOptions: { ID: number | undefined, FullName: string }[] = [];
  constructor(
    private staffApiService: StaffApiService,
    private router: Router,
  ) {


  }

ngOnInit(): void {
    if (!this.patientOptions || this.patientOptions.length === 0) {
        this.staffApiService.GetMyPatients().subscribe((patients) => {
          this.patientOptions = patients.map(patient => ({
            ID: patient.ID,
            FullName: `${patient.FirstName} ${patient.LastName}`
          }));
          this.loading = false;
        });
      } else{
        
        this.loading = false;
      }
}

changedPatient(event: { originalEvent: Event, value: number }) {
    const newPatientIDSelected = event.value;
    this.selectedPatient = this.patientOptions?.find(patient => patient.ID === newPatientIDSelected) ?? null;
    
      this.router.navigate(['/supervisor/' + newPatientIDSelected + '/programs']);
    
  }

}
