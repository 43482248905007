
<div class="details-dialog-backdrop" (click)="closeDetails()"></div>
<dialog class="details-dialog" open>
    <h5 class="w-100 text-center">{{target?.TargetName}}</h5>

    @if (showGraph) {
    <a (click)="onHideGraph()" class="btn btn-primary" href="javascript:void(0)">See instructions</a><br><br>
    <app-target-graph [patientID]="target?.PatientID?.toString()!" [targetId]="target?.ID!"></app-target-graph>
    } @else {
    <a (click)="onSeeGraphs()" class="btn btn-primary" href="javascript:void(0)">See graphs</a><br><br>
    <p>{{target?.Instructions}}</p>
    }
</dialog>