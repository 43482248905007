@if (target) {
    @if (isDurationTarget(target)) {
    <app-box-duration [isBottomBox]="isBottomBox" [target]="target"></app-box-duration>
    }
    @if (isFrequencyTarget(target)) {
    <app-box-frequency [isBottomBox]="isBottomBox" [target]="target"></app-box-frequency>
    }
    @if (isGroupedFrequencyTarget(target) || isConditionalDiscriminationTarget(target) || isTaskAnalysisTarget(target)) {
    <app-box-grouped [isBottomBox]="isBottomBox" [target]="target" [subTargetsDictionary]="subTargetsDictionary"
        [subTargets]="subTargetsDictionary[target.ID ?? 0]" [isChain]="isTaskAnalysisTarget(target)" 
        [promptSchedules]="promptSchedules??[]" ></app-box-grouped>
    }
    @if (isDttTarget(target)) {
    <app-box-dtt [isBottomBox]="isBottomBox" [target]="target"
        [options]="promptCodes" [enabled]="enabled" [promptSchedule]="promptSchedule"></app-box-dtt>
    }
    @if (isEchoicTarget(target)) {
    <app-box-echoic [isBottomBox]="isBottomBox" [target]="target" 
        [options]="promptCodes" [promptSchedule]="promptSchedule"></app-box-echoic>
    }
    @if (isFluencyRateTarget(target)) {
        <app-box-fluencyrate [isBottomBox]="isBottomBox"  [target]="target" ></app-box-fluencyrate>
    }
    @if (isQuantityTarget(target)) {
        <app-box-quantity [isBottomBox]="isBottomBox" [target]="target"></app-box-quantity>
    }
    @if (isAnecdotalTarget(target)) {
        <app-box-anecdotal [isBottomBox]="isBottomBox" [target]="target"></app-box-anecdotal>
    }
}