import { Component, Input } from '@angular/core';
import { PatientBehaviorConsequenceModel } from '../../../../core/api/models';
import { StaffApiService } from '../../../../core/api/services';
import { SupervisorHelperService } from '../../../supervisor.helper.service';
import { AlertService } from '../../../../shared/services/alert.service';

@Component({
  selector: 'app-consequence',
  templateUrl: './consequence.component.html',
})
export class ConsequenceComponent {
  @Input() consequence: PatientBehaviorConsequenceModel | undefined;

  constructor(
    private staffApiService: StaffApiService,
    private supervisorHelperService: SupervisorHelperService,
    private alertService: AlertService
  ) {}

  isCollapsed = true;

  toggleCollapsed() {
    this.isCollapsed = !this.isCollapsed;
  }

  editConsequence() {
    console.log('editConsequence clicked!');
    this.supervisorHelperService.addConsequenceButtonClicked(
      this.consequence ?? null
    );
  }

  deleteConsequence() {
    console.log('deleteConsequence clicked!');

    this.alertService
      .confirm(
        'Delete Consequence',
        'Are you sure you want to delete this consequence?'
      )
      .subscribe((result) => {
        if (result) {
          this.deleteConsequenceConfirmed();
        }
      });
  }

  deleteConsequenceConfirmed() {
    if (this.consequence?.ID != null) {
      this.staffApiService
        .MarkConsequenceAsDeleted({
          id: this.consequence.ID,
        })
        .subscribe(
          () => {
            this.alertService.success('Consequence marked as deleted');
            this.supervisorHelperService.triggerUpdateSignal();
          },
          (error) => {
            this.alertService.error(error);
          }
        );
    }
  }
}
