@if (isCollapsed) {
<div
  class="container"
  (click)="toggleCollapsed()"
  [id]="'toggleCollapseButton-' + program?.ProgramName"
>
  <div class="row h-4rem align-items-center">
    <div class="col-3">
      <button
        type="button"
        class="btn btn-primary position-relative program-name"
      >
        {{ program?.ProgramName }}
        <!-- @if ((program?.alerts?.length ?? 0) > 0) {
                        <span class="position-absolute top-50 end-0 translate-middle-y p-2 bg-danger border border-light rounded-circle" style="background-color: #9382AD !important; margin-right: 1rem">
                          <span class="visually-hidden">New alerts</span>
                        </span>
                    } -->
      </button>
    </div>
    <div class="col-1">
      <i class="pi pi-arrow-down" style="font-size: 1rem; color: #719eb6"></i>
    </div>
    @if (program?.IsDeleted) {
      <div class="col-2">
        <p-tag id="deleted-tag" severity="danger" value="Deleted" />
      </div>
      }
      @if (program?.IsArchived) {
        <div class="col-2">
          <p-tag id="archived-tag" severity="warning" value="Archived" />
        </div>
      }
  </div>
</div>
} @else {
<div class="container">
  <div class="row h-4rem align-items-center">
    <div class="col-3">
      <button
        type="button"
        class="btn btn-primary position-relative program-name"
        (click)="toggleCollapsed()"
        [id]="'toggleCollapseButton-' + program?.ProgramName"
      >
        {{ program?.ProgramName }}
        <!-- @if ((program?.alerts?.length ?? 0) > 0) {
                        <span class="position-absolute top-50 end-0 translate-middle-y p-2 bg-danger border border-light rounded-circle" style="background-color: #9382AD !important; margin-right: 1rem">
                          <span class="visually-hidden">New alerts</span>
                        </span>
                    } -->
      </button>
    </div>
    <div class="col-1">
      <i class="pi pi-arrow-up" style="font-size: 1rem; color: #719eb6"></i>
    </div>
    <div class="col-3">
      <span class="fs-8 fw-bolder" style="color: #719eb6">
        Included Playlists:
      </span>
      <span class="fs-8" style="color: #719eb6">
        {{ program!.ID != null ? playlistsToDisplay(program!.ID) : "None" }}
      </span>
    </div>
    <div class="col-3">
      <span class="fs-8 fw-bolder" style="color: #719eb6"> Skill Areas: </span>
      <span class="fs-8" style="color: #719eb6">
        {{ skillAreasToDisplay }}
      </span>
    </div>
    @if (program?.IsDeleted) {
      <div class="col-2">
        <p-tag id="deleted-tag" severity="danger" value="Deleted" />
      </div>
      } @else {
    <div class="col-1">
      <button
        (click)="editProgram()"
        [id]="'editProgramButton-' + program?.ProgramName"
        class="side-menu-button2"
      >
        Edit
      </button>
    </div>
    <div class="col-1">
      <button
        (click)="deleteProgram()"
        [id]="'deleteProgramButton-' + program?.ProgramName"
        class="switch-mode-button"
      >
        Delete
      </button>
    </div>
    }
  </div>
  <!-- @for (alert of program?.alerts; track alert.id) {
            <div class="row">
                <div class="program-alert">
                    <span class="fs-8 fw-bold col-5"> {{ alert.staffName }} </span>
                    <span class="fs-8 col-6"> {{ alert.message }} </span>
                    <button (click)="dismissAlertButtonClicked(alert)" class="side-menu-button1">Dismiss</button>
                </div>
            </div>
        } -->

  <div class="row">
    <span class="fs-8 fw-bolder" style="color: #719eb6">Details(s): </span>
    <span class="fs-8" style="color: #719eb6"> {{ program?.Details }} </span>
  </div>
  <div class="row">
    @for (target of program?.Targets; track target.TargetName) {
    <app-target
      [patientID]="patientID()"
      [program]="program"
      [target]="target"
    />
    } @empty {
    <p
      style="
        text-align: center;
        margin-top: 20px;
        font-size: 1rem;
        color: #719eb6;
      "
    >
      There are no targets for this program yet.
    </p>
    }
  </div>
</div>
}
