<div class="d-flex flex-row gap-3 ms-3 me-3">
<button (click)="addBehaviorButtonClicked()" id="addBehaviorButton" class="side-menu-button2 w-100">
  + Behavior
</button>
<button (click)="getFromBIPButtonClicked()" class="side-menu-button2 w-100" >
  Get From BIP
</button>
</div>

<p-table
  id="behaviors"
  [value]="behaviors"
  #dt
  dataKey="ID"
  [tableStyle]="{ 'min-width': '70rem' }"
  [loading]="loadingBehaviors"
  [rows]="25"
  [rowsPerPageOptions]="[10, 25, 50]"
  [paginator]="true"
>
  <ng-template pTemplate="body" let-behavior>
    <app-behavior [behavior]="behavior" [id]="behavior.PatientBehaviorName"></app-behavior>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <p
      style="
        text-align: center;
        margin-top: 20px;
        font-size: 1rem;
        color: #719eb6;
      "
    >
    There are no Behaviors yet.
    </p>
  </ng-template>
</p-table>
