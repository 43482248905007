<div class="container">
  <div class="row">
    <div class="col-6">
      <p>
        <p-multiSelect
          appendTo="body"
          autoWidth="false"
          [group]="true"
          [style]="{ width: '100%' }"
          [options]="possibleFilters()"
          name="selectedFilters"
          [(ngModel)]="selectedFilters"
          display="chip"
          [showClear]="true"
          placeholder="Select Filters"
        >
          <ng-template let-group pTemplate="group">
            <div class="flex align-items-center">
              <span>{{ group.label }}</span>
            </div>
          </ng-template>
          <ng-template let-item pTemplate="footer">
            <div class="py-2 px-3">
              <b>
                {{ selectedFilters().length }}
              </b>
              item{{ selectedFilters().length > 1 ? "s" : "" }}
              selected.
            </div>
          </ng-template>
        </p-multiSelect>
      </p>
    </div>
    <div class="col-6">
      <div class="horizontal-flex-list">
        <label class="checkmarks-label">Search</label>
        <input type="text" [(ngModel)]="searchKeyword" />
      </div>
    </div>
    <button
      (click)="addPlaylistButtonClicked()"
      id="addPlaylistButton"
      class="side-menu-button2"
    >
      + Playlist
    </button>
  </div>
</div>

<p-table
  id="playlists"
  [value]="filteredPlaylists()"
  #dt
  dataKey="ID"
  [tableStyle]="{ 'min-width': '70rem' }"
  [loading]="isLoading"
  [rows]="25"
  [rowsPerPageOptions]="[10, 25, 50]"
  [paginator]="true"
>
  <ng-template pTemplate="body" let-playlist>
    <app-playlist [patientID]="patientID()" [playlist]="playlist" [id]="playlist.PlaylistName" />
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <p
      style="
        text-align: center;
        margin-top: 20px;
        font-size: 1rem;
        color: #719eb6;
      "
    >
    There are no playlists yet.
    </p>
  </ng-template>
</p-table>
