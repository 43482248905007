import { Component, effect, input, signal } from '@angular/core';
import { PatientBehaviorModel } from '../../core/api/models';
import { SelectItem } from 'primeng/api';
import { StaffApiService, SupervisorApiService } from '../../core/api/services';
import { MultiSelectChangeEvent } from 'primeng/multiselect';
import { AlertService } from '../../shared/services/alert.service';

@Component({
  selector: 'app-behaviors',
  templateUrl: './behaviors.component.html',
  styleUrl: './behaviors.component.css',
})
export class BehaviorsComponent {
  patientID = input.required<string>();

  isLoading: boolean = false;

  selectableBehaviors = signal<SelectItem[]>([]);
  selectedBehaviorID: number | null = null;

  constructor(
    private staffApiService: StaffApiService,
    private alertService: AlertService
  ) {
    effect(() => {
      if (this.patientID()) {
        this.loadData();
      }
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.staffApiService
      .GetPatientBehaviors({ patientID: Number(this.patientID()) })
      .subscribe(
        (targets) => {
          console.log(targets);
          const behaviors = targets.map((target) => ({
            text: target.PatientBehaviorName ?? 'No Name For Target',
            value: target.ID,
            disabled: false,
          }));
          behaviors.unshift({
            text: 'All Behaviors',
            value: 0,
            disabled: false,
          });
          this.selectableBehaviors.set(behaviors);
          this.isLoading = false;
        },
        (error) => {
          console.error('Error fetching data', error);
          this.alertService.error(
            'Error fetching data. Error: ' + error.Message
          );
          this.isLoading = false;
        }
      );
  }

  onBehaviorChange(event: MultiSelectChangeEvent) {
    if (!event.value) {
      return;
    }
  }
}
