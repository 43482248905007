import { Component, input, Input, OnInit } from '@angular/core';
import {
  PatientPlaylistModel,
  PatientProgramModel,
  SkillAreaModel,
} from '../../../core/api/models';
import { SupervisorApiService } from '../../../core/api/services';
import { Router } from '@angular/router';
import { SupervisorHelperService } from '../../supervisor.helper.service';
import { AlertService } from '../../../shared/services/alert.service';
import { Utils } from '../../../core/utils';
import { SupervisorBaseComponent } from '../../supervisor-base.component';

@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrl: '../../supervisor-main.component.css',
})
export class ProgramComponent extends SupervisorBaseComponent {
  patientID = input.required<string>();
  @Input({ required: true }) program: PatientProgramModel | undefined;

  constructor(
    protected override supervisorApiService: SupervisorApiService,
    protected override alertService: AlertService,
    protected override supervisorHelperService: SupervisorHelperService
  ) {
    super(supervisorApiService, alertService, supervisorHelperService);
  }

  isCollapsed = true;

  get skillAreasToDisplay() {
    let programSkillArea = this.supervisorHelperService
      .skillAreas()
      .find((skillArea) => skillArea.ID === this.program?.SkillAreaID);
    if (programSkillArea) {
      return programSkillArea.SkillAreaName;
    } else {
      return 'none';
    }
  }

  toggleCollapsed() {
    this.isCollapsed = !this.isCollapsed;
  }

  editProgram() {
    console.log('editProgram clicked!');
    this.supervisorHelperService.additProgramButtonClicked(
      this.program ?? null
    );
  }

  deleteProgram() {
    console.log('deleteProgram clicked!');

    if (this.program?.ID != null) {
      this.alertService
        .confirm(
          'Delete Program',
          'Are you sure you want to delete this program? It will be removed from the playlists it was part of, if any.'
        )
        .subscribe((result) => {
          if (result) {
            if (this.supervisorHelperService.playlists.length === 0) {
              this.deleteProgramServiceCall();
              return;
            } else {
              // remove program from all its playlists
              const removeProgramPromises = this.supervisorHelperService
                .playlists()
                .map((playlist) => this.removeProgramFromPlaylist(playlist));

              Promise.all(removeProgramPromises)
                .then(() => {
                  this.deleteProgramServiceCall();
                })
                .catch((error) => {
                  console.error(error);
                  this.alertService.error(Utils.getAjaxError(error));
                });
            }
          }
        });
    }
  }

  removeProgramFromPlaylist(playlist: any): Promise<void> {
    return new Promise((resolve, reject) => {
      if (playlist.Items == null || playlist.Items.length === 0) {
        resolve();
      } else {
        let programItem = playlist.Items.find(
          (item: { PatientProgramID: number | undefined }) =>
            item.PatientProgramID === this.program?.ID
        );
        if (programItem != null) {
          // create new playlist without program
          let newPlaylist = {
            ID: playlist.ID,
            Items: playlist.Items.filter(
              (item: { PatientProgramID: number | undefined }) =>
                item.PatientProgramID !== this.program?.ID
            ),
            PatientID: playlist.PatientID,
            PlaylistName: playlist.PlaylistName,
          };
          // update playlist
          this.supervisorApiService
            .SavePatientPlaylist({
              body: newPlaylist,
            })
            .subscribe(
              () => {
                resolve();
              },
              (error) => {
                console.error(error);
                this.alertService.error(Utils.getAjaxError(error));
                reject(error);
              }
            );
        } else {
          resolve();
        }
      }
    });
  }

  deleteProgramServiceCall() {
    this.supervisorApiService
      .MarkPatientProgramAsDeleted({ patientProgramID: this.program?.ID })
      .subscribe(
        () => {
          this.alertService.success('Program marked as deleted');
          this.supervisorHelperService.triggerUpdateSignal();
        },
        (error) => {
          console.error(error);
          this.alertService.error(Utils.getAjaxError(error));
        }
      );
  }

  dismissAlertButtonClicked(alert: any) {
    console.log('dismissAlertButtonClicked clicked!');
    // if (this.program) {
    //   this.programsService.dismissAlertForProgram(alert.id, this.program.id)
    // }
  }
}
