@if (isCollapsed) {
<div
  class="container"
  (click)="toggleCollapsed()"
  [id]="'toggleCollapseButton-' + behavior?.PatientBehaviorName"
>
  <div class="row h-4rem align-items-center">
    <div class="col-3">
      <button
        type="button"
        class="btn btn-primary position-relative program-name"
      >
        {{ behavior?.PatientBehaviorName }}
      </button>
    </div>
    <div class="col-1">
      <i class="pi pi-arrow-down" style="font-size: 1rem; color: #719eb6"></i>
    </div>

    @if (behavior?.IsDeleted) {
    <div class="col-2">
      <p-tag id="deleted-tag" severity="danger" value="Deleted" />
    </div>
    }
  </div>
</div>
} @else {
<div class="container">
  <div class="row h-4rem align-items-center">
    <div class="col-3">
      <button
        type="button"
        class="btn btn-primary position-relative program-name"
        (click)="toggleCollapsed()"
        [id]="'toggleCollapseButton-' + behavior?.PatientBehaviorName"
      >
        {{ behavior?.PatientBehaviorName }}
      </button>
    </div>

    <div class="col-1">
      <i class="pi pi-arrow-up" style="font-size: 1rem; color: #719eb6"></i>
    </div>

    @if (behavior?.IsDeleted) {
    <div class="col-2">
      <p-tag id="deleted-tag" severity="danger" value="Deleted" />
    </div>
    } @else {
    <div class="col-3">
      <div class="side-menu-buttons-horizontal">
        <button
          (click)="editBehavior()"
          [id]="'editBehaviorButton-' + behavior?.PatientBehaviorName"
          class="side-menu-button2"
        >
          Edit Behavior
        </button>
      </div>
    </div>

    <div class="col-3">
      <div class="side-menu-buttons-horizontal">
        <button
          (click)="deleteBehavior()"
          [id]="'deleteBehaviorButton-' + behavior?.PatientBehaviorName"
          class="switch-mode-button"
        >
          Delete Behavior
        </button>
      </div>
    </div>
    }
  </div>

  <div class="row">
    <p-table [value]="[behavior]" [tableStyle]="{ 'min-width': '40rem' }">
      <ng-template pTemplate="header">
        <tr>
          <th style="text-align: center">Behavior Name</th>
          <th style="text-align: center">Behavior Type</th>
          <th style="text-align: center">Date Created</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-target>
        <tr>
          <td style="text-align: center">
            {{ behavior?.PatientBehaviorName }}
          </td>
          <td style="text-align: center">
            {{ patientBehaviorTypeLabel(behavior?.BehaviorType) }}
          </td>
          <td style="text-align: center">
            {{ behavior?.DateCreated | date : "MM/dd/yyyy" }}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8">No behaviors found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
}
