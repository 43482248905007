import { ShepherdService } from "angular-shepherd";
import Step from "shepherd.js/src/types/step";

export const STEPS_BUTTONS = {
  back: {
    classes: "back-button",
    secondary: true,
    text: "Back",
    type: "back"
  },
  next: {
    classes: "next-button",
    text: "Next",
    type: "next"
  },
  end: {
    classes: "cancel-button",
    secondary: true,
    text: "End",
    type: "cancel"
  }
};
  
export const defaultStepOptions = {
  classes: "shepherd-theme-arrows custom-default-class",
  scrollTo: false,
  cancelIcon: {
    enabled: true
  },
};

const createStepperHtml = (current: number, total: number) => {
  let pills = '';
  for (let i = 1; i <= total; i++) {
    pills += `<div class="step-pill ${i === current ? 'active' : ''}" style="display:inline-block;width:20px;height:8px;margin:0 4px;border-radius:4px;background:${i === current ? '#007bff' : '#e9ecef'}"></div>`;
  }
  return `<div style="text-align:center;margin:10px 0">${pills}</div>`;
};

const step1: Step.StepOptions = {
  attachTo: {
    element: "#clientName",
    on: "bottom"
  },
  arrow: true,
  buttons: [STEPS_BUTTONS.next],
  classes: "custom-class-name-1 custom-class-name-2",
  id: "step1",
  title: "Client Name",
  text: `This is the client name, the client can be change by clicking on the menu on the top right. <br> <br> <br> ${createStepperHtml(1, 5)}`,
  when: {
    show: () => {
      setTimeout(() => {
        document.getElementById("sideMenuButton")?.click();
      }, 1);    
    }
  }
}

const step2: Step.StepOptions = {
  attachTo: {
    element: "#sideMenuButtons",
    on: "left"
  },
  arrow: true,
  buttons: [STEPS_BUTTONS.back, STEPS_BUTTONS.next],
  classes: "custom-class-name-1 custom-class-name-2",
  id: "step2",
  title: "Menu",
  text: `This is the menu, here you can change the selected client and access other features. <br> <br> <br> ${createStepperHtml(2, 5)}`,
  when: {
    hide: () => {
      setTimeout(() => {
        document.getElementById("sideMenuButton")?.click();
      }, 1);    }
  }
}

const step3: Step.StepOptions = {
  attachTo: {
    element: "#supervisorComponentsList",
    on: "bottom"
  },
  arrow: true,
  buttons: [STEPS_BUTTONS.back, STEPS_BUTTONS.next],
  classes: "custom-class-name-1 custom-class-name-2",
  id: "step3",
  title: "Supervisor Components",
  text: `Here you can see the list of components that are available for the supervisor to use. We are now on the Programs section. This is where you can create new programs, add targets to them and make them available to the client. <br> <br> <br> ${createStepperHtml(3, 5)}`,
}

const step4: Step.StepOptions = {
  attachTo: {
    element: "#supervisorProgramsButtons",
    on: "bottom"
  },
  arrow: true,
  buttons: [STEPS_BUTTONS.back, STEPS_BUTTONS.next],
  classes: "custom-class-name-1 custom-class-name-2",
  id: "step4",
  title: "Adding Programs",
  text: `Programs can be manually added by clicking on the + Program button. Or imported from Optomiser by clicking on the Get From BSP button. <br> <br> <br> ${createStepperHtml(4, 5)}`,
}

const step5: Step.StepOptions = {
  buttons: [STEPS_BUTTONS.back, STEPS_BUTTONS.end],
  classes: "custom-class-name-1 custom-class-name-2",
  id: "step5",
  title: "Programs",
  text: `Each program can be clicked to reveal more information about it as well as the edit and delete options. <br> <br> <br> ${createStepperHtml(5, 5)}`,
}
  
export const steps : Array<Step.StepOptions> = [
  step1,
  step2,
  step3,
  step4,
  step5,
];
  