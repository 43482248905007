import { Component, effect, input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientReminderModel, PromptCodeModel, PromptScheduleModel } from '../../core/api/models';
import { SupervisorApiService } from '../../core/api/services';
import { AlertService } from '../../shared/services/alert.service';
import { SettingsHelperService } from '../../settings/settings.helper.service';
import { Utils } from '../../core/utils';
import { SharedModule } from "../../shared/shared.module";

@Component({
  selector: 'app-import-data',
  templateUrl: './import-data.component.html'
})
export class ImportDataComponent {
  patientID: string='';
  patientIDLoaded:boolean=false;
  loading:boolean=false;
  constructor(
    protected settingsHelperService: SettingsHelperService,
    private activatedRoute: ActivatedRoute,
    private supervisorApiService: SupervisorApiService,
    private alertService: AlertService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.patientID = params['patientID'];
      this.patientIDLoaded = true;
    });
  }

  onFileChange(event: any) {
    console.log(event);
    const file = event.target.files[0];
    console.log(file);
    this.loading = true;
    this.supervisorApiService.ImportPatientTargetsFromFile({
      patientID: parseInt(this.patientID) ,
      body: {
        file: file,
      },
    }).subscribe((response) => {
      this.loading = false;
      this.alertService.success('Data imported successfully');
      this.router.navigate(['/supervisor', this.patientID, 'programs']);
    },
    (error) => {
      this.loading = false;
      this.alertService.error('Error importing data');
      console.log(error);
    });
  }
}