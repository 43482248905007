import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, catchError, of, forkJoin } from 'rxjs';
import { AuthService } from '../../auth';
import {
  PatientModel,
  PatientSessionModel,
  PatientTargetModel,
  PatientBehaviorModel,
  PatientBehaviorAntecedentModel,
  PatientBehaviorConsequenceModel,
  PatientBehaviorLocationModel,
  PatientBehaviorResponseModel,
  PatientBehaviorSeverityModel,
} from '../../core/api/models';
import {
  HealthCheckApiService,
  SupervisorApiService,
  StaffApiService,
} from '../../core/api/services';
import { AlertService } from '../../shared/services/alert.service';
import { SupervisorHelperService } from '../../supervisor/supervisor.helper.service';
import { PatientSessionService } from '../home/patient-session.service';
import { inject, DestroyRef, signal } from '@angular/core';

export class SessionsBaseComponent {
  isLoading: boolean = false;

  selectedPatient: PatientModel | null = null;

  private destroyRef = inject(DestroyRef);

  sessions = signal<PatientSessionModel[]>([]);

  targets = signal<PatientTargetModel[]>([]);
  behaviors = signal<PatientBehaviorModel[]>([]);
  antecedents: PatientBehaviorAntecedentModel[] = [];
  consequences: PatientBehaviorConsequenceModel[] = [];
  locations: PatientBehaviorLocationModel[] = [];
  responses: PatientBehaviorResponseModel[] = [];
  severities: PatientBehaviorSeverityModel[] = [];

  constructor(
    protected supervisorApiService: SupervisorApiService,
    protected supervisorHelperService: SupervisorHelperService,
    protected patientSessionService: PatientSessionService,
    protected staffApiService: StaffApiService
  ) {}

  loadBaseData(patientID: number, finishedLoading?: () => void) {
    console.log('loadBaseData from sessions called');

    this.isLoading = true;

    const getPatientObservable = this.staffApiService.GetPatientInfo({
      id: Number(patientID),
    });

    const getSessionsObservable = this.staffApiService.GetPatientSessions({
      patientID: Number(patientID),
    });

    const getTargetsObservable = this.supervisorApiService.GetPatientTargets({
      patientID: Number(patientID),
    });

    const getBehaviorsObservable = this.staffApiService.GetPatientBehaviors({
      patientID: Number(patientID),
    });

    const getAntecedentsObservable =
      this.staffApiService.GetPatientBehaviorAntecedents({
        patientID: Number(patientID),
      });

    const getConsequencesObservable =
      this.staffApiService.GetPatientBehaviorConsequences({
        patientID: Number(patientID),
      });

    const getLocationsObservable =
      this.staffApiService.GetPatientBehaviorLocations({
        patientID: Number(patientID),
      });

    const getResponsesObservable =
      this.staffApiService.GetPatientBehaviorResponses({
        patientID: Number(patientID),
      });

    const getSeveritiesObservable =
      this.staffApiService.GetPatientBehaviorSeverities({
        patientID: Number(patientID),
      });

    const getDataObservables: Observable<
      PatientModel | PatientSessionModel[] | PatientTargetModel[]
    >[] = [
      getPatientObservable,
      getSessionsObservable,
      getTargetsObservable,
      getBehaviorsObservable,
      getAntecedentsObservable,
      getConsequencesObservable,
      getLocationsObservable,
      getResponsesObservable,
      getSeveritiesObservable,
    ];

    const wrappedCalls = getDataObservables.map((call) =>
      call.pipe(catchError((error) => of({ error: true, details: error })))
    );

    const subscription = forkJoin(wrappedCalls).subscribe(
      ([
        patient,
        sessions,
        targets,
        behaviors,
        antecedents,
        consequences,
        locations,
        responses,
        severities,
      ]) => {
        if ('error' in patient) {
          console.log('Error fetching patient:', patient.details);
        } else {
          this.selectedPatient = patient as PatientModel;
        }
        if ('error' in sessions) {
          console.log('Error fetching sessions:', sessions.details);
        } else {
          this.sessions.set(sessions as PatientSessionModel[]);
        }
        if ('error' in targets) {
          console.log('Error fetching targets:', targets.details);
        } else {
          this.targets.set(targets as PatientTargetModel[]);
        }
        if ('error' in behaviors) {
          console.log('Error fetching behaviors:', behaviors.details);
        } else {
          this.behaviors.set(behaviors as PatientBehaviorModel[]);
        }
        if ('error' in antecedents) {
          console.log('Error fetching antecedents:', antecedents.details);
        } else {
          this.antecedents = antecedents as PatientBehaviorAntecedentModel[];
        }
        if ('error' in consequences) {
          console.log('Error fetching consequences:', consequences.details);
        } else {
          this.consequences = consequences as PatientBehaviorConsequenceModel[];
        }
        if ('error' in locations) {
          console.log('Error fetching locations:', locations.details);
        } else {
          this.locations = locations as PatientBehaviorLocationModel[];
        }
        if ('error' in responses) {
          console.log('Error fetching responses:', responses.details);
        } else {
          this.responses = responses as PatientBehaviorResponseModel[];
        }
        if ('error' in severities) {
          console.log('Error fetching severities:', severities.details);
        } else {
          this.severities = severities as PatientBehaviorSeverityModel[];
        }

        this.isLoading = false;
        if (finishedLoading) {
          finishedLoading();
        }
      }
    );

    this.destroyRef.onDestroy(() => {
      subscription.unsubscribe();
    });
  }
}
