import { Component, effect, input } from '@angular/core';
import { PatientReminderModel } from '../../core/api/models';
import { SupervisorApiService } from '../../core/api/services';
import { AlertService } from '../../shared/services/alert.service';
import { SupervisorHelperService } from '../supervisor.helper.service';
import { Utils } from '../../core/utils';

@Component({
  selector: 'app-reminders',
  templateUrl: './reminders.component.html',
  styleUrl: '../supervisor-main.component.css',
})
export class RemindersComponent {
  patientID = input.required<string>();

  reminders: PatientReminderModel[] = [];

  loadingReminders: boolean = false;

  constructor(
    private supervisorApiService: SupervisorApiService,
    private alertService: AlertService,
    protected supervisorHelperService: SupervisorHelperService
  ) {
    effect(() => {
      console.log(
        'update signal triggered: ',
        this.supervisorHelperService.updateSignal()
      );
      this.loadData();
    });
  }

  ngOnInit(): void {
    console.log('RemindersComponent ngOnInit');
    this.loadData();
  }

  loadData() {
    this.loadingReminders = true;
    this.supervisorApiService
      .GetPatientReminders({ patientID: Number(this.patientID()) })
      .subscribe(
        (reminders) => {
          this.loadingReminders = false;
          this.reminders = reminders;
        },
        (err) => {
          this.loadingReminders = false;
          this.alertService.error(Utils.getAjaxError(err));
        }
      );
  }
  
  addReminderButtonClicked() {
    console.log('addReminderButtonClicked');
    this.supervisorHelperService.addReminderButtonClicked(null);
  }
}
